import {useState , useRef , useEffect} from 'react';
import { inputChange, validationByClick } from '../../../utils/formValidation';
import Input from '../input/Input';
import Button from '../button/Button';

const Form = ({ classesForm , initForm   , submit })=>{

    
    const [form, setForm] = useState(initForm);
    const [timeOut , setTimeOut] = useState(true);


    useEffect(()=>{
        setForm(initForm);
    } , [initForm])


    const inputChangeHandler = (e, element) => {

        if(timeOut){
            clearTimeout(timeOut);
        }

        let updatedForm = [...form.formItems];
        let updetedElement = { ...updatedForm[element] };
    
        updetedElement.value = e.target.value;
        updetedElement.valid = true;
        updetedElement.errorMsg = true;
        updetedElement.used = true;
        updatedForm[element] = updetedElement;
        
        //console.log(updatedForm);
        setForm({ formItems: updatedForm });

        let newTimeOuut = setTimeout(()=>{

            if (newTimeOuut === timeOut) {
                setTimeOut(null);
            }

            submit(e.target.value);

        } , 500);

        setTimeOut(newTimeOuut);
    }


    return(
        <form  className={`${classesForm}`} >
            {
                form.formItems?.map((input, index) => (
                    <div key={index} className='flex items-center justify-between'>
                        <Input
                        inputElement={input.inputType}
                        config={input.config}
                        value={input.value}
                        change={(e) => inputChangeHandler(e, index)}
                        valid={input.valid}
                        errorMsg={input.errorMsg}
                        used={input.used} />
                    </div>
                    )
                )
            }
        </form>

    )
 
}

export default Form ;