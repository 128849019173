import React, { useState , useEffect} from 'react';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { isoToDate } from '../../../utils/isoToDate';
import {axiosApiInstance} from '../../../services/axios';
import Form from '../../common/forms/Form';
import Loading from '../loading/Loading';


const UserPlans = (props) => {


    let activePlan = (props.activePlan.filter(item => item.status == 'active'))[0];

    const[loading , setLoading] = useState(false);
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState({});
    
    useEffect(()=>{
        const getBlogCat = async()=>{
            setLoading(true);
            const res = await axiosApiInstance.get(`/admin/plans`);

            if(res.data.status === 'success'){

                console.log(res.data.data.plans.SCC)

                setForm( {
                    formItems: [
                    {
                        inputType: "select-plans",
                        config: {
                            options :  res.data.data.plans.SCC,
                            label: 'Active Plan',
                            name: "planId",
                            containerClasses : 'w-full',
                        },
                        value: activePlan ? activePlan.planId : '' ,
                        validation: {
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "date-input",
                        config: {
                            label: 'Start Time',
                            name: "startTime",
                            containerClasses : 'w-full',
                        },
                        value: activePlan ? isoToDate(activePlan.updatedAt) : '' ,
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                ]
                });
            

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }
            setLoading(false);
        }
        getBlogCat();
    } , []);


    
    const handleSubmitForm = async(formData)=>{
        setLoadingForm(true);

        //console.log(formData);

        const {data} = await axiosApiInstance.put(`/admin/users/edit/plan/${props.id}` , formData) ;
        if(data.status === 'success'){
            setLoadingForm(false); 
            toast.success('User Plan changed successfull');

        }else if(data.status == 'error'){
            setLoadingForm(false);
            toast.error(data.msg);
        }
    }


    return (
        <>
            <div className="container mx-auto p-3 ">

                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my'>

                    <div className='w-full flex flex-col justify-center items-start'>
                        {
                            loading ? 
                            (
                                <Loading/>
                            )
                            :
                            (
                                
                                <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Apply" file={false}/>

                            )
                        }
                        
                    </div> 
                    
                </div>

            </div>
        </>
    )

}

export default UserPlans ;
