import React from 'react';

const Pagination = ({ classes ,  pages, current, action }) => {

  //const pages= useSelector(state => state.pagination.pages);
  //const current = useSelector(state => state.pagination.page) ;

  const pageNeighbours = 2;
  const currentPage = parseInt(current)

  const LEFT_PAGE = 'LEFT';
  const RIGHT_PAGE = 'RIGHT';

  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }
    return range;
  }

  const fetchPageNumbers = () => {
    const totalPages = pages;
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = (pageNeighbours * 2) + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (totalPages - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }
      //console.log([1, ...pages, totalPages]);
      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  }

  const allPage = fetchPageNumbers();


  const gotoPage = page => {
    action(page);
  }

  const handleClick = (e, page) => {
    e.preventDefault();
    gotoPage(page);
  }

  const handleMoveLeft = e => {
    e.preventDefault();
    gotoPage(currentPage - (pageNeighbours * 2) - 1);
  }

  const handleMoveRight = e => {
    e.preventDefault();
    gotoPage(currentPage + (pageNeighbours * 2) + 1);
  }

  //console.log(allPage)

  if (pages > 1) {
    return (
      <div className={`${classes} w-full flex justify-center items-center my-10`}>
        <ul className='flex p-0'>
          {
            allPage.map((page, index) => {

              if (page === LEFT_PAGE) return (

                <li key={index} className='mx-1'>
                  <a className="rounded-md	bg-gray-200 font-semibold	dark:bg-dark-50 px-5 py-3 hover:bg-slate-700" href="#" aria-label="Previous" onClick={handleMoveLeft}>
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
              );

              if (page === RIGHT_PAGE) return (

                <li key={index} className='mx-1'>
                  <a className="rounded-md bg-gray-200 font-semibold	dark:bg-dark-50 px-5 py-3  hover:bg-slate-700" href="#" aria-label="Next" onClick={handleMoveRight}>
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              );

              if (allPage == 1) {
                return null
              }


              return (
                <li  key={index} className='mx-1 group'>
                  <a className={`rounded-md	px-5 py-3 font-semibold ${currentPage === page ? 'bg-primary-100 group-hover:cursor-default dark:bg-primary-100' : 'bg-gray-200 group-hover:bg-gray-300 dark:bg-dark-50 dark:group-hover:bg-slate-500'}`} href="#" onClick={(e) => handleClick(e, page)}>{page}</a>
                </li>

              );

            })
          }
        </ul>
      </div>

    )
  }else{
    return(null)
  }




}

export default Pagination;
