import React, { useState } from 'react';


import ModalUploadImage from '../modals/modalUploadImage/ModalUploadImage';

const TextareaWithUpload = ({ allProps }) => {
    
    //console.log(allProps);

    const [showModalUploadImage, setShowModalUploadImage] = useState(false);


    const stateModalUploadImage = (state) => {
        setShowModalUploadImage(state);
    }

    const sendTestEmailHandler =()=>{
        if(allProps.config.templateTestMode == 'email'){
            console.log('send test email log')
        }

        if(allProps.config.templateTestMode == 'telegram'){
            console.log('send test telegram log')
        }

    }

  return (
        <>
        
            {
                showModalUploadImage ? 
                (
                    <ModalUploadImage show={true} close={()=>setShowModalUploadImage(false)} bucket={allProps.config.bucket}/>
                )
                :
                (
                    null
                )
            }

            <div className={`${allProps.config.containerClasses} flex flex-col justify-center mb-5`}>
            <div className="flex justify-between items-center mx-[5px] mb-1 cursor-pointer">
                <span className="text-gray-800 font-light text-[0.8rem]">{allProps.config.label}</span>
                <div className='flex items-center gap-x-2'>
                    <div className="flex items-center px-3 py-1 text-[0.7rem] border rounded-md hover:border-primary-100" onClick={()=>sendTestEmailHandler()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                        </svg>
                        Send Test Email
                    </div>
                    <div className="flex items-center px-3 py-1 text-[0.7rem] border rounded-md hover:border-primary-100" onClick={()=>stateModalUploadImage(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
                            <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                            />
                        </svg>
                        Upload New Image
                    </div>
                </div>
            </div>
            <textarea
                rows={allProps.config.rows ?? "4"}
                className={`rounded-lg p-1 border outline-none w-full text-slate-500 font-medium focus:border-primary-100 ${!allProps.valid && allProps.used ? "border-red-500" : "border-gray-200 "}`}
                autoComplete="off"
                {...allProps.config}
                onChange={allProps.change}
                value={allProps.value}
            ></textarea>
            {allProps.errorMsg !== "" ? (
                <div className="flex justify-between h-5 w-full px-[5px]">
                <span className="text-sm text-red-500 font-light">{allProps.errorMsg}</span>
                </div>
            ) : null}
            </div>

        </>

  );
};

export default TextareaWithUpload;