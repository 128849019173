import moment from 'moment';
moment.locale('en');

export const isoToDate  = (isoDate) =>{
    let date = moment(isoDate);
    let dateComponent = date.utc().format('YYYY/MM/DD');
    return dateComponent ;
}


