import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user : undefined ,
    loading : true , 
    error : null ,
}

export const authSlice = createSlice({
    name : 'auth' ,
    initialState ,
    reducers : {
        updateUser : (state , action )=>{ 
            state.user = action.payload.user ;
            state.loading = action.payload.loading; 
            state.error = action.payload.error;
        },
    }
})

export const { updateUser } = authSlice.actions ;

export const selectUser = (state) => state.auth.user ;



export default authSlice.reducer;