import moment from 'moment';
import { useState, useEffect } from "react";

import HeadIndexCard from "../../index/headIndexCard/HeadIndexCard";
import LoadingComponent from '../../common/loading/Loading';
import ChartUsers from "../../index/chartUsers/ChartUsers";
import NewUserItem from "../../index/newUserItem/NewUserItem";
import NewTickets from "../../index/newTickets/NewTickets";
import { getCountUsers , getListUsersByDate} from "../../../services/indexPage/indexPageService";

function Index() {
  const [HeadCardUserCount , setHeadCardUserCount] = useState({
    loading : true ,
    classes : 'w-1/4'
  });

  const [loadingNewUser , setLoadingNewUser] = useState(true);
  const [newUsers, setNewUsers] = useState([]);

  const [newTickets, setNewTickets] = useState([
    {
      email: "ali@gmail.com",
      img: "bucket=avatars&object=default-avatar64.jpg",
      txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      registerDate: "2022-12-25T06:28:37.374+00:00",
    },
    {
      email: "ali@gmail.com",
      img: "bucket=avatars&object=default-avatar64.jpg",
      txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      registerDate: "2022-12-25T06:28:37.374+00:00",
    },
    {
      email: "ali@gmail.com",
      img: "bucket=avatars&object=default-avatar64.jpg",
      txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      registerDate: "2022-12-25T06:28:37.374+00:00",
    },
    {
      email: "ali@gmail.com",
      img: "bucket=avatars&object=default-avatar64.jpg",
      txt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      registerDate: "2022-12-25T06:28:37.374+00:00",
    },
  ]);

  useEffect(() => {
    try {
      const getUsersCount = async () => {
        const { data } = await getCountUsers();
        if (data.status === "success") {
          setHeadCardUserCount(
            {
              title: "Users",
              loading: false ,
              value: data.data.count ,
              classes: "w-1/4",
              iconClasses: "stroke-yellow-500",
              icon: "M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z",
            },
         );
        }
      };
      getUsersCount();
    } catch (err) {
      console.log(err);
    }
  }, []);
  

  useEffect(()=>{
    getNewUserDetail('1W');
  },[]);


  const getNewUserDetail = async(period)=>{

    setLoadingNewUser(true);

    let now = new Date();
    let endTime = moment(now).format('yyyy-MM-DD');
    let startTime;

    if(period == '1W'){
      startTime = moment(endTime).startOf('day').subtract(7, 'd').format('yyyy-MM-DD');
    }else if(period == '1M'){
      startTime = moment(endTime).subtract(1, 'M').format('yyyy-MM-DD');
    }else if(period == '3M'){
      startTime = moment(endTime).subtract(3, 'M').format('yyyy-MM-DD');
    }else if(period == '6M'){
      startTime = moment(endTime).subtract(6, 'M').format('yyyy-MM-DD');
    }

    const { data } = await getListUsersByDate(startTime , endTime);
    if(data.status == 'success'){
      setLoadingNewUser(false);
      setNewUsers(data.data);
    }
  }

  const changeTimeNewUserHandler = (e)=>{
    getNewUserDetail(e.target.value);
  }


  return (
    <div className="container mx-auto p-3 pt-5 flex flex-col">
        
      <div className="w-full flex gap-x-5 mb-5">
          <HeadIndexCard {...HeadCardUserCount}/>
          <HeadIndexCard {...HeadCardUserCount}/>
          <HeadIndexCard {...HeadCardUserCount}/>
          <HeadIndexCard {...HeadCardUserCount}/>
      </div>
      <div className="bg-white-100 p-3 rounded-md mb-10">
        <span className="text-[1.5rem] font-bold text-gray-700 mb-4 block">Registered users</span>
        <div className="w-full h-[400px]">
          <ChartUsers />
        </div>
      </div>

      <div className="w-full h-[500px] flex gap-x-5">
        <div className="w-1/2 h-full flex flex-col bg-white-100 p-3 rounded-md">
          <div className="flex justify-between">
            <span className="text-[1.5rem] font-bold text-gray-700 mb-4 block">New users</span>
            <select defaultValue={true} onChange={changeTimeNewUserHandler} className={`w-fit h-fit border rounded-md px-5 py-2 cursor-pointer outline-none text-slate-500 text-[0.8rem] transition-all duration-300 focus:border-primary-100 border-gray-200`}>
              <option className='cursor-pointer' value="1W">Last Week</option>
              <option className='cursor-pointer' value="1M">Last Month</option>
              <option className='cursor-pointer' value="3M">Last 3Month</option>
              <option className='cursor-pointer' value="6M">Last 6Month</option>
            </select>
          </div>
          <div className={`w-full flex flex-col items-center ${newUsers.length == 0 && loadingNewUser  ? 'justify-center' : 'justify-start'} overflow-y-auto grow`}>
            {
              loadingNewUser ? 
              (
                <LoadingComponent classes={'h-full'}/>
              )
              :
              (
                <>
                  {
                  newUsers.map((item, index) => (
                    <NewUserItem classes={`${index !== 0 ? "border-t" : ""}`} key={index} id={item._id} avatar={item.avatar} email={item.email} registerTime={item.registerTime} />
                  ))
                  }
                  {
                    newUsers.length == 0  ?
                    (
                      <span className='my-auto text-[0.7rem]'>
                        No user has registered in this time period :(
                      </span>
                    )
                    :
                    (
                      null
                    )
                  }
                </>
              )
            }
            
          </div>
        </div>
        <div className="w-1/2 h-full flex flex-col bg-white-100 p-3 rounded-md">
          <span className="text-[1.5rem] font-bold text-gray-700 mb-4 block">New Tickets</span>
          <div className='w-full overflow-y-auto grow'>
            {newTickets.map((item, index) => (
              <NewTickets classes={`${index !== 0 ? "border-t" : ""}`} key={index} img={item.img} email={item.email} txt={item.txt} registerDate={item.registerDate} />
            ))}
          </div>
        </div>
      </div>
    

    </div>
  );
}

export default Index;
