import React, { useState , useEffect} from 'react';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addCommas } from '@persian-tools/persian-tools';
import ModalRemovePayment from '../../../common/modals/modalRemovePayment/ModalRemovePayment';
import { useSelector } from 'react-redux';


import FormSearch from '../../../common/forms/FormSearch';
import {axiosApiInstance} from '../../../../services/axios';
import {getPayments} from '../../../../services/payments/paymentService';
import Pagination from '../../../common/pagination/Pagination';
import Loading from '../../../common/loading/Loading';
import ModalLoading from '../../../common/modals/modalLoading/ModalLoading';
import {checkPermissions} from '../../../../utils/checkPermissions';
import { selectUser } from '../../../../redux/slices/auth';
import { showDateTime } from '../../../../utils/showDate';


const Payments = () => {

    const user = useSelector(selectUser);

    const[loading , setLoading] = useState(true);
    const [payments, setPayments] = useState();
    const [page, setPage] = useState();
    const [pages, setPages] = useState();
    const [q, setQ] = useState('');
    const [loadingForm , setLoadingForm] = useState(false);
    const [currentPayment , setCurrentPayment] = useState({});
    const [showModalRemovePayment , setShowModalRemovePayment] = useState(false);
    const [ form ]= useState({
        formItems: [
            {
                inputType: "input",
                config: {
                    name: "key",
                    containerClasses : 'w-full',
                    placeholder : "Search"
                },
                value: q,
                validation: {
                },
                valid: false,
                errorMsg: "",
                used: false
            },
        ]
    });


    const stateModalRemovePayment = (state , item) => {
        setShowModalRemovePayment(state);
        setCurrentPayment(item);
    }
 

    useEffect(()=>{
        const getPlans = async()=>{
            const res = await getPayments();
            setLoading(false);
            if(res.data.status === 'success'){
                setPayments(res.data.data.payments.docs);
                setPage(res.data.data.payments.page);
                setPages(res.data.data.payments.totalPages);

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }
        }
        getPlans();
    } , []);



    const goPage = async (p) => {
        setLoading(true);
        const res = await getPayments(p , q);
        setLoading(false);
        if (res.data.status === 'success') {
            setPayments(res.data.data.payments.docs);
            setPage(res.data.data.payments.page);
            setPages(res.data.data.payments.totalPages);
        } else {
            toast.error(res.data.msg);
        }
    }

    const handleFormSearch = async(query)=>{
        setQ(query);
        setLoadingForm(true);
        
        const res = await axiosApiInstance.get(`/admin/pricing/payments?page=${page}&q=${query}`);
        setLoadingForm(false);
        if (res.data.status === 'success') {
            setPayments(res.data.data.payments.docs);
            setPage(res.data.data.payments.page);
            setPages(res.data.data.payments.totalPages);
        } else {
            toast.error(res.data.msg);
        }

    }


    return (
        <>

            {
                showModalRemovePayment ?
                (
                    <ModalRemovePayment show={true} close={()=>setShowModalRemovePayment(false)} refresh={() => goPage(page)} item={currentPayment}/>
                )
                :
                (
                    null
                )
            }
            <ModalLoading show={loadingForm}/>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />

            <div className="container mx-auto p-3 ">

                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my'>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>Payments</span>
                    </div>


                            <div className='w-full flex flex-col justify-center items-start'>
                                <div className='w-full flex items-center justify-end text-base font-semibold mb-4 text-slate-600 dark:text-white-100'>
                                    <FormSearch classesForm='w-1/2' initForm={form} submit={handleFormSearch}/>
                                </div>
                                {
                                    loading ? 
                                    (
                                        <Loading/>
                                    )
                                    :
                                    (
                                        <div className='w-full text-[0.9rem] text-center border-collapse '>
                                        {payments?.length > 0 ?
                                            (
                                                <>
                                                    <table className="w-full table">
                                                        <thead className='sticky top-[60px] bg-white-100'>
                                                            <tr>
                                                                <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>order ID</th>
                                                                <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>user</th>
                                                                <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>payment for</th>
                                                                <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Price</th>
                                                                <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Type</th>
                                                                <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Date</th>
                                                                <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Admin</th>
                                                                <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { payments?.map ((payment , index) =>(
                                                                <tr key={index} className="hover:bg-stone-100 rounded-lg duration-200">
                                                                    <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>{payment.orderId !== '0' && payment.orderId !== 'false' ? payment.orderId : '-'}</td>
                                                                    <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>{ payment?.user[0]?.email || payment?.user[0]?.phone }</td>
                                                                    <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>{payment.plans.length > 0 ? payment.plans[0].SCC.title : payment.paymentFor }</td>
                                                                    <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>{addCommas(payment.price)}$</td>
                                                                    <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>
                                                                        {
                                                                            payment.statusPayment == 'deposit' ? 
                                                                            (
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-4 h-4 mx-auto stroke-green-500">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                                                </svg>
                                                                            )
                                                                            :
                                                                            (
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-4 h-4 mx-auto stroke-red-500">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                                                </svg>
                                                                            )
                                                                        }
                                                                    </td>
                                                                    <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>{showDateTime(payment.createdAt)}</td>
                                                                    <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>{payment.adminId.length ?  payment.adminId[0].email : '-'}</td>
                                                                    <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>
                                                                    {
                                                                        checkPermissions(user.type , user.permissions , 'Payment-Delete')?
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-auto cursor-pointer hover:stroke-primary-100" onClick={()=>stateModalRemovePayment(true , payment)}>
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                            </svg>    
                                                                        )
                                                                        :
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                            </svg>
                                                                        )
                                                                    }  
                                                                    </td>
                                                                </tr>
                                                                )
                                                            )
                                                            }
    
                                                        </tbody>
                                                    </table>
                                                    <Pagination pages={pages} current={page} action={goPage} />
                                                </>
    
                                            )
                                            :
                                            (
    
                                                <p className="caption-no-result">
                                                    No payment found
                                                </p>
    
                                            )
                                        }
    
                                        </div>
                                    )
                                }
                               
                            </div> 
                   
                </div>

            </div>
        </>
    )

}

export default Payments ;
