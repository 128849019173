import {useState , useRef , useEffect , Fragment} from 'react';
import { inputChange, validationByClick , emptyField} from '../../../utils/formValidation';
import Input from '../input/Input';
import Button from '../button/Button';

const Form = ({initForm , submitTitle , loading , submit , file , imgEdit = '' , isCRM = false})=>{

    //console.log(initForm)

    const [form, setForm] = useState(initForm);

    useEffect(()=>{
        setForm(initForm);
    } , [initForm ])

    const formRef = useRef();


    const inputChangeHandler = (e, element) => {
        setForm(inputChange(e, element, form.formItems));
    }

    
    const emptyFieldHandler = (element) => {

        setForm(emptyField(element , form.formItems));
    }
        


    const submitHandler = async (e) => {
        e.preventDefault();

        if (!loading) {

            let updatedForm = [...form.formItems];
            setForm(validationByClick(form.formItems));

            let allow = true;
            updatedForm.forEach(item => {
                if (!item.valid) {
                    allow = false;
                    return
                }
            });


            if (allow) {

                    try {

                        if(file){
                            let formData = new FormData(formRef.current);
                            
                            submit(formData);
                        }else{
                            const newForm = {};
                            for (let item in form.formItems) {
                                if(!form.formItems[item].config.justShow){
                                    newForm[form.formItems[item].config.name] = form.formItems[item].value
                                }
                            }
                            submit(newForm);
                        }
          
                    } catch (err) {
                        console.log(err)
                        //showMsg('error', t.something_was_wrong);
                    }   
            }
        }
    }

    return(
        <form onSubmit={(e) => submitHandler(e)} ref={formRef} className='w-full flex items-center flex-wrap' >
            {
                form.formItems?.map((input, index) => {
                    if(input.inputType == 'select-blog-publication-status'){

                        if(input.config?.isdepend){
                            let dependFieldindex = form?.formItems.findIndex(item=>item.config.name == input.config.isdependfield) ; 
                            let dependFieldValue = input.config.isdependvalue; 

                            if(form?.formItems[dependFieldindex].value == dependFieldValue){
                                return(
                                    <Input
                                    key={index}
                                    inputElement={input.inputType}
                                    config={input.config}
                                    value={input.value}
                                    size={input.size}
                                    file={input.file}
                                    filename={input.filename}
                                    emptyField = {()=>emptyFieldHandler(index)} 
                                    change={(e) => inputChangeHandler(e, index)}
                                    valid={input.valid}
                                    errorMsg={input.errorMsg}
                                    used={input.used} />
                                )
                            }

                        }else{
                            return(
                                <Input
                                key={index}
                                inputElement={input.inputType}
                                config={input.config}
                                value={input.value}
                                valueFull={input?.valueFull}
                                size={input.size}
                                file={input.file}
                                filename={input.filename}
                                emptyField = {()=>emptyFieldHandler(index)} 
                                change={(e) => inputChangeHandler(e, index)}
                                valid={input.valid}
                                errorMsg={input.errorMsg}
                                used={input.used} />
                            )
                        }
                        
                    }else{

                        if(input.config?.isdepend){

                            let dependFieldindex = form?.formItems.findIndex(item=>item.config.name == input.config.isdependfield);
                            let dependFieldValue = input.config.isdependvalue; 
 
                            if(form?.formItems[dependFieldindex].value == dependFieldValue){
                                
                                return(
                                    <Fragment key={index}> 
                                        <Input
                                        inputElement={input.inputType}
                                        config={input.config}
                                        value={input.value}
                                        valueFull={input?.valueFull}
                                        size={input.size}
                                        file={input.file}
                                        filename={input.filename}
                                        emptyField = {()=>emptyFieldHandler(index)} 
                                        change={(e) => inputChangeHandler(e, index)}
                                        valid={input.valid}
                                        errorMsg={input.errorMsg}
                                        used={input.used} />

                                        {
                                            input.inputType  == 'input-file' && imgEdit !== '' && 
                                            <img src={`${isCRM ? `${process.env.REACT_APP_BACK_END_URL}/api/v1/storage/download` : `${process.env.REACT_APP_BACK_END_URL}/api/v1/download` }?${imgEdit}`}/>
                                        }
                                    </Fragment>
                          
                                )
                            
                            }

                        }else{


                            return(
                                <Fragment key={index}>
                                    <Input
                                    key={index} 
                                    inputElement={input.inputType}
                                    config={input.config}
                                    value={input.value}
                                    valueFull={input?.valueFull}
                                    size={input.size}
                                    file={input.file}
                                    filename={input.filename}
                                    emptyField = {()=>emptyFieldHandler(index)} 
                                    change={(e) => inputChangeHandler(e, index)}
                                    valid={input.valid}
                                    errorMsg={input.errorMsg}
                                    used={input.used} />
                                    {
                                        input.inputType  == 'input-file' && imgEdit !== '' && 
                                        <img src={`${isCRM ? `${process.env.REACT_APP_BACK_END_URL}/api/v1/storage/download` : `${process.env.REACT_APP_BACK_END_URL}/api/v1/download` }?${imgEdit}`}/>
                                            
                                    }
                                
                                </Fragment>
                            )

                        }
                    
                    }
                  
                    }
                )
            }
        
        
            <div className='w-full flex flex-wrap flex-col items-end mb-[16px]'>
                <Button classes="w-[100px] py-1 bg-primary-100 border border-primary-100 hover:bg-transparent hover:text-primary-100 text-gray-600 font-normal" loading={loading} click={submitHandler}>
                    {submitTitle}
                </Button>
            </div>
        </form>

    )
 
}

export default Form ;