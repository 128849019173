import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import useAuth from "../../../hooks/useAuth";


const IsAuth = ({ children , forLogin=false}) => {
    
    let { user, error, loading , mutate} = useAuth(forLogin);

    useEffect(()=>{
        
        if(forLogin){
            if(!loading && error==undefined ){
                if(!isEmpty(user) || user !== undefined){
                    return <Navigate to="/" replace />;
                }
            }

            mutate();
        }       
        

    } , [user])

  

    return children;
};

export default IsAuth ;