import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Form from "../../forms/Form";
import {uploadImage} from '../../../../services/crm/emailTemplateService';


const ModalUploadImage = (props) => {

    const[DownloadLink , setDownloadLink] = useState('');

    const[loadingForm, setLoadingForm] = useState(false);
    const[form, setForm] = useState({
      formItems: [
        {
            inputType: "inputFileUpload",
            config: {
                label: '',
                name: "image",
                type: "file",
                t_label : 'attached_file',
                containerClasses : 'w-full' ,
            },
            value: "",
            validation: {
                maxSize: 5000000,
                isSelected : true ,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
      ],
    });


    const copyLinkHanlder = async(txt) => {
        try {
            await navigator.clipboard.writeText(txt);
        } catch (e) {
            console.log(e)
        }
    };

    
    const close = async (e) => {
        props.close();
    }

    const handleSubmitForm = async (formData) => {
        try {
          setLoadingForm(true);

          formData.append('bucket' , props.bucket);

          const { data } = await uploadImage(formData);
          if (data.status === "success") {
            setLoadingForm(false);
            setDownloadLink(`${process.env.REACT_APP_BACK_END_URL}/api/v1/storage/download?${data.data}`);
            
          } else if (data.status == "error") {
            setLoadingForm(false);
            toast.error(data.msg);
          }


        } catch (err) {
          setLoadingForm(false);
        }
      };

    return (
        <>
         
            {props.show ? (<div className='w-full h-full z-[4000000] fixed top-0 left-0 right-0 bg-modalBackground-100' onClick={() => close()}></div>) : null}
            <div className={`w-4/5 h-fit  max-w-[650px] fixed z-[5000000] bg-white-100 dark:bg-dark-100 rounded-3xl left-0 right-0 top-0 bottom-0 m-auto  ${props.show ? 'animate-my-fade-down block' : 'hidden'}`}>
                <div className='w-full h-full flex flex-col justify-center items-center p-5 overflow-y-auto'>
                    <div className='w-full flex justify-between mb-10'>
                        <h1 className="font-bold text-gray-700 text-[1.2rem]">
                            Upload New Image
                        </h1>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer hover:opacity-80" onClick={() => close()}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                    <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Upload" file={true}/>
                    
                    {
                        !loadingForm && DownloadLink !== '' ? 
                        (
                            <>

                                {
                                    DownloadLink === '' ?
                                    (
                                        <span>
                                            loading
                                        </span>

                                    )
                                    :
                                    (

                                        <div className='w-full p-3 border border-dotted'>
                                        <span className='text-gray-500'>
                                            Download Link:
                                        </span>
                                        <div className='overflow-x-auto'>
                                            <span className='text-gray-500'>
                                                {DownloadLink}
                                            </span>
                                        </div>
                                    
                                        <div className='flex items-center w-fit px-4 py-2 mt-5 text-[0.8rem] rounded-md border mx-auto cursor-pointer hover:opacity-80 hover:text-gray-500' onClick={()=>copyLinkHanlder(DownloadLink)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                                            </svg>
                                            Copy Link
                                        </div>
                                    
                                    </div>
                                    )

                                }
                            
                            </>

                        )
                        :
                        (
                            null
                        )
                    }                       
                </div>
            </div>
        </>
    )
}


export default ModalUploadImage;