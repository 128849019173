
export const ticketStatusToText = (n) =>{

    let num = String(n);

    let status = '';
    switch (num) {
        case '1': 
            status = 'Awaiting answer';
            break;
        case '2':
            status = 'Under review';
            break;
        case '3':
            status = 'Has been answered';
            break;
        case '4':
            status = 'Closed';
            break;
        default:
            status = 'Awaiting answer';
    }

    let bg = '';
    switch (num) {
        case '1': 
            bg = 'bg-orange-400';
            break;
        case '2':
            bg = 'bg-yellow-400';
            break;
        case '3':
            bg = 'bg-red-400';
            break;
        case '4':
            bg = 'bg-gray-500';
            break;
        default:
            bg = 'bg-gray-500';
    }


    return(
    <div className="w-full flex justify-center items-center">
        <div className={`w-3 h-3 ${bg} rounded-full mr-1`}>
        </div>
        <span>
            {status}
        </span>

    </div>
    );
}