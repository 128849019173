import ScaleLoader from "react-spinners/ScaleLoader";

const Loading = ({classes})=>{
    return(
        <div className={`${classes} w-full h-[400px] flex justify-center items-center`}>
        <ScaleLoader height={20} width={3} color={"#FFC20E"} size={5} />
        </div>
    )
}

export default Loading ;
