import React, { useState } from 'react';

import {axiosApiInstance} from '../../../../services/axios';
import ButtonSimple from '../../button/ButtonSimple';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ModalRemoveExclusiveCode = (props) => {

    const [loading, setLoading] = useState(false);
    const [allowSend, setAllowSend] = useState(true);


    const close = async (e) => {
        props.close();
    }

    const removeHandler = async() => {
        if (allowSend) {
            setAllowSend(false);
            try {

                // show loading
                setLoading(true);
                const { data } = await axiosApiInstance.delete(`/admin/exclusive-invitation-code/${props.code._id}`);

                if (data.status == 'success') {
                    toast.success('Blog remove successfull');

                    toast.error(data.msg);

                    // clear inputs and reset form and close
                    close();
                    props.refresh();

                } else {
                    toast.error('somting wrong');

                }
                // hide loading
                setLoading(false);
                setAllowSend(true);

            } catch (err) {
                //console.log(err);
                toast.error('somting wrong');
                // hide loading
                setLoading(false);
                setAllowSend(true);
            }
        }

    }

    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />

            {props.show ? (<div className='w-full h-full z-[4000000] fixed top-0 left-0 right-0 bg-modalBackground-100' onClick={() => close()}></div>) : null}
            <div className={`w-4/5 h-fit  max-w-[650px] fixed z-[5000000] bg-white-100 dark:bg-dark-100 rounded-3xl left-0 right-0 top-0 bottom-0 m-auto  ${props.show ? 'animate-zoom-in block' : 'hidden'}`}>
                <div className='w-full h-full flex flex-col justify-center items-center p-5 overflow-y-auto'>
                        <form className="w-full flex flex-col">
                            <h1 className="font-bold text-gray-700 text-[1.2rem]">
                                Remove Exclusive Code
                            </h1>


                            <span className="txtConfirm my-3">
                                Are you sure to delete the code {props.show ? props.code.code : ''}?
                            </span>


                            <div className="w-full flex justify-center">
                                <ButtonSimple loading={loading} classes='py-1 px-2 bg-primary-100 rounded-md' click={removeHandler}>
                                    Ok
                                </ButtonSimple>
                                <ButtonSimple classes='py-1 px-2 ml-1 bg-primary-100 rounded-md' click={() => close()}>
                                    Cancel
                                </ButtonSimple>
                            </div>
                        </form>
                </div>
            </div>
        </>
    )
}

export default ModalRemoveExclusiveCode;