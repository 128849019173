import { Field , ErrorMessage } from 'formik';

const Input = ({
    label ,
    name ,
    type='text' ,
    palceholder , 
    inputClassName ,
    errrorClassName
})=>{
    
    return(
        <>

            <div className={`flex flex-col justify-center mb-[16px] w-full`} >
                <div className='flex justify-start items-center h-full text-gray-800 font-semibold text-[0.9rem] mx-[5px]' >
                    <span>{label}</span>
                </div>
                <Field
                name = {name}
                type = {type}
                placeholder = {palceholder}
                className={`${inputClassName?? ''} rounded-lg p-3 bg-white-100 border outline-none w-full text-slate-700 text-[0.9rem] font-medium focus:border-sky-500 border-gray-200 `}
                />
                <ErrorMessage name={name} className={`${errrorClassName?? ''} flex justify-start h-5 w-full px-[5px] text-sm text-red-500 font-light`} component='div'/>
            </div>


        </>
    )
} 



export default Input ;