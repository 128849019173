import {BrowserRouter} from 'react-router-dom';
import Forvest from './Forvest';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <BrowserRouter>
     <ToastContainer 
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      style={{zIndex:2000000}}
      />
      <Forvest/>
    </BrowserRouter>
  );
}

export default App;
