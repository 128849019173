import React,{ useState, useEffect }  from "react";
import { toast } from "react-toastify";
import { useParams} from "react-router-dom";

import { historyEventlogs } from "../../../../../services/crm/eventService";
import Pagination from "../../../../../components/common/pagination/Pagination";
import Loading from "../../../../../components/common/loading/Loading";
import { showDateTime , showDateTimeUtc} from '../../../../../utils/showDate';


const Logs = () => {

  let {id} = useParams();

  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState();
  const [page, setPage] = useState();
  const [pages, setPages] = useState();
  const [state , setState] = useState(null);

  useEffect(() => {
    const getAllEvents = async () => {
      const res = await historyEventlogs( id , 1 ,  'event' , state);
      setLoading(false);
      if (res.data.status === "success") {
        setLogs(res.data.data);
        setPage(res.data.pagination.page);
        setPages(Math.ceil(res.data.pagination.count / res.data.pagination.limit));
      } else if (res.data.status === "error") {
        toast.error(res.data.msg);
      }
    };
    getAllEvents();
  }, []);

  const goPage = async (p) => {
    setLoading(true);
    const res = await historyEventlogs( id , p , 'event' , state);
    setLoading(false);
    if (res.data.status === "success") {
      setLogs(res.data.data);
      setPage(res.data.pagination.page);
      setPages(Math.ceil(res.data.pagination.count / res.data.pagination.limit));
    } else {
      toast.error(res.data.msg);
    }
  };


  const changeStatusHandler = async(e)=>{
    setLoading(true);
    let stateString = e.target.value;
    setState(stateString);
    const res = await historyEventlogs( id , 1 ,  'event' , stateString);
    if (res.data.status === "success") {
      setLogs(res.data.data);
      setPage(res.data.pagination.page);
      setPages(Math.ceil(res.data.pagination.count / res.data.pagination.limit));
    } else if (res.data.status === "error") {
      toast.error(res.data.msg);
    }
    setLoading(false);
  }


  const showSeenStatus = (seen)=>{

    if(seen == undefined){
      return (<span className="mx-auto">-</span>);
    }
    if(seen){
      return(
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-auto">
        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
      </svg>
      )
    }else{
      return(
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mx-auto">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      )
    }
    
  }


  return (
    <>
  
      <div className="container mx-auto p-3 ">
        <div className="bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none ">
          <div className="w-full flex justify-start items-center mb-5">
            <svg className="w-2 h-2 fill-gray-700 mr-2" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" />
            </svg>
            <span className="text-gray-700 text-xl font-extrabold">Events Logs</span>
          </div>

          <div className="w-full flex items-ceneter justify-between">
            <span className="mb-5">
              {`List of eventsHistory of event with id: ${id}`}
            </span>
            <select name='status' onChange={changeStatusHandler} className='w-[150px] border rounded-lg p-1 outline-none text-slate-500 bg-white-100 text-[0.9rem] duration-100 focus:border-primary-100 border-gray-200 hover:cursor-pointer'>
              <option value='null' selected={true}>All</option>
              <option value='success'>Success</option>
              <option value='queued'>Queued</option>
              <option value='failed'>Failed</option>
              <option value='n/a'>n/a</option>
            </select>
          </div>
         
          {loading ? (
            <Loading />
          ) : (
            <div className="w-full flex flex-col justify-center items-start">
              <div className="w-full text-[0.9rem] text-center border-collapse">
                {logs.length > 0 ? (
                  <>
                    <table className="w-full table">
                      <thead className="sticky top-[60px] bg-white-100">
                        <tr>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">UserId</th>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">From</th>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">To</th>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">Status</th>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">Reason</th>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">Platform</th>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">Send At</th>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">Seen</th>
                        </tr>
                      </thead>
                      <tbody className="w-full">
                        {logs.map((log , index) => (
                          <tr key={index} className="hover:bg-stone-100 duration-200 rounded-lg text-center p-10">
                            <td className="font-normal text-[0.8rem] border-none align-middle h-20">
                              <a href={`/users/${log.userId}`} className="hover:text-primary-100 hover:underline">
                                {log.userId}
                              </a>
                            </td>
                            <td className="font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20">{log.from}</td>
                            <td className="font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20">{log.to}</td>
                            <td className="font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20">{log.status}</td>
                            <td className="font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20">{String(log.reason)}</td>
                            <td className="font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20">{log.platform}</td>
                            <td className="font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20">ir:{showDateTime(log.sendAt)} - utc:{showDateTimeUtc(log.sendAt)}</td>
                            <td className="font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20">{showSeenStatus(log.seen)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination classes="" pages={pages} current={page} action={goPage} />
                  </>
                ) : (
                  <p className="caption-no-result p-10">No log found</p>
                )}
              </div>
            </div>
          )}
          

        </div>
      </div>
    </>
  );
};

export default Logs;
