import Loading from '../../loading/Loading';
const ModalLoading = (props) => {


    return (
        <>
            {props.show ? (<div className='w-full h-full z-[4000000] fixed top-0 left-0 right-0 bg-modalBackground-100'></div>) : null}
            <div className={`w-4/5 h-fit max-w-[650px] fixed z-[5000000] left-0 right-0 top-0 bottom-0 m-auto  ${props.show ? 'block' : 'hidden'}`}>
                <Loading /> 
            </div>
        </>
    )
}

export default ModalLoading;