import React, { useState , useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import {axiosApiInstance} from '../../../services/axios';
import Loading from '../../common/loading/Loading';


const SingleAffiliateReq = () => {

    const {id} = useParams();

    const[loading , setLoading] = useState(true);
    const [ affiliateReq , setAffiliateReq ] = useState({});
    

    useEffect(()=>{

        const getData = async()=>{
            const {data} = await axiosApiInstance.get(`/admin/affiliate-reqs/${id}` );

            setLoading(false);
            if(data.status === 'success'){
                setAffiliateReq(data.data.affiliateReq);

            }else if(data.status === 'error'){
                toast.error(data.msg);
            }
        }

        getData();

    } , []);



    return (
        <>
        
            <div className="container mx-auto p-3 ">
                {
                        loading ? 
                        (
                            <Loading/>
                        )
                        :
                        (
                            <>
                                <div className='w-full dark:bg-dark-100 bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                                    <div className='w-full flex justify-start items-center mb-5'>
                                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="50" cy="50" r="50" />
                                        </svg>
                                        <span className='text-gray-600 dark:text-white-100 text-xl font-extrabold'>Affiliate Request Detail</span>
                                    </div>
                    
                                    <div className='w-full flex flex-col justify-between'>
                                        <div className='w-[200px] flex flex-col items-start'>
                                            <img className='rounded-full' src='/images/default-avatar64.jpg' width="64" height="64" alt='user' />
                                            <span>{affiliateReq?.user.name !== "" ? affiliateReq?.user.name : affiliateReq?.user.phone}</span>
                                        </div>
                                        <div className='w-[1px] mx-5 bg-gray-300'></div>
                                        <div dir="ltr" className='grow flex flex-col justify-between'>
                                            <span className="text-left text-gray-700 text-[1.1rem]">
                                            Name: {affiliateReq.name}
                                            </span>
                                        </div>
                                        <div dir="ltr" className='grow flex flex-col justify-between'>
                                            <span className="text-left text-gray-700 text-[1.1rem]">
                                            Email: {affiliateReq.email}
                                            </span>
                                        </div>
                                        <div dir="ltr" className='grow flex flex-col justify-between'>
                                            <span className="text-left text-gray-700 text-[1.1rem]">
                                            Phone: {affiliateReq.phone}
                                            </span>
                                        </div>
                                        <div dir="ltr" className='grow flex flex-col justify-between'>
                                            <span className="text-left text-gray-700 text-[1.1rem]">
                                            Message: {affiliateReq.msg}
                                            </span>
                                        </div>
                                        <span className='mt-2'>{moment(affiliateReq.createdAt).format('MMMM Do YYYY, h:mm')}</span>
  
                                    </div>
                                </div>
                            </>
                        )
                    }
            </div>
        </>
    )

}

export default SingleAffiliateReq ;
