import { axiosApiInstance } from "../axiosCRM";

export const getTemplates = (page, limit = 10) => {
  return axiosApiInstance.get(`/api/v1/templates/telegram?page=${page}&limit=${limit}`);
};

export const newTemplates = (formData) => {
  return axiosApiInstance.post(`/api/v1/templates/telegram`, formData);
};

export const singleTemplate = (id) => {
  return axiosApiInstance.get(`/api/v1/templates/telegram/${id}`);
};

export const editTemplate = (id, formData) => {
  return axiosApiInstance.put(`/api/v1/templates/telegram/${id}`, formData);
};

export const removeTemplate = (id) => {
  return axiosApiInstance.delete(`/api/v1/templates/telegram/${id}`);
};


export const attachTemplate = ( eventId , templateId) =>{
  let body = {
      eventId ,
      templateId 
  }
  return axiosApiInstance.post(`/api/v1/templates/telegram/attach` , body);
}

export const deattachTemplate = ( eventId , templateId) =>{
  return axiosApiInstance.delete(`/api/v1/templates/telegram/detach` , {
      data:{
      eventId ,
      templateId 
      }
  });
}
