import { Link  } from 'react-router-dom';


const NewUserItem = (props)=>{
    let {id , avatar , email , registerTime , classes} = props;
    return(
        <Link to={`/users/${id}`} className={`w-full ${classes} flex justify-between p-5 cursor-pointer hover:bg-slate-100 rounded-md`}>
            <div className='flex items-center'>
                <img className='rounded-full w-[32px] h-[32px] mr-2' width="32" height="32" src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${avatar.thumb}`} /> 
                <span className="text-gray-600 font-normal text-[0.8rem]">{email}</span>
            </div>
           
            <div className="flex ittems-center">
                <span className='text-gray-600 leading-[2rem] text-justify font-medium text-[0.8rem] mr-1'>Register Date:</span>
                <span className='text-gray-500 leading-[2rem] text-justify font-light text-[0.8rem]'>{registerTime}</span>
            </div>
        </Link>
    )
}

export default NewUserItem ;


