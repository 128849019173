import React, { useState, useRef, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';

import {checkPermissions} from '../../utils/checkPermissions';


const Menus = ({  showSide , title  , icon ,  menues ,  user}) => {

    const navigate = useNavigate();

    const [ActiveMain, setActiveMain] = useState(false);
    const [heightMain, setHeightMain] = useState(0);


    const contentMain = useRef(null);

    useEffect(() => {
        //    
    }, []);


    const ShowContentMain = () => {
        setActiveMain(!ActiveMain);
        setHeightMain(ActiveMain ? 0 : contentMain.current.scrollHeight);
    }

    return (
        <>
            <>
                <div className="flex items-center justify-between transition-all duration-150 group" onClick={() => ShowContentMain()}>
                    <div className='h-[40px] flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 mr-1 min-w-[78px] stroke-white-100 group-hover:stroke-primary-100 dark:stroke-white-100 duration-300`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d={icon} />
                        </svg>
                        <span className={`text-[0.8rem] text-start font-light transition-opacity text-white-100 dark:text-white-100 group-hover:text-primary-100 ${showSide ? 'block transition-opacity delay-[300ms] opacity-100' : 'pointer-events-none opacity-0 '}`}>
                            {title}
                        </span>
                    </div>
            
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`mr-2 h-5 w-5 stroke-white-100 group-hover:stroke-primary-100 ${ActiveMain ? "rotate-180	" : "" } ${showSide ? 'block opacity-100 ' : 'pointer-events-none opacity-0 '}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                    <span className={`w-fit text-[0.5rem] bg-gray-500 border border-gray-700 rounded-md text-white-100 dark:bg-dark-50 dark:text-white-100 absolute p-1 top-[-20px] left-[50%] -translate-x-2/4 transition-all duration-500 hidden ${showSide ? '' : 'group-hover:block'}`}>
                        {title}
                    </span>
                </div>
                <div className='w-full max-h-0 overflow-hidden transition-all duration-500' ref={contentMain} style={{ maxHeight: `${heightMain}px` }}>
                    <ul className='w-full pr-0 first:mt-5 last:mb-0'>
                        {
                            menues.map((item , index) => {
                                    if(checkPermissions(user.type , user.permissions , item.permission)){

                                    return(
                                        <li className={`relative ${showSide ? "pl-5" : "pl-0" } mr-1 group h-[40px] flex items-center p-0 rounded-tr-md rounded-br-md ${index == menues.length-1 ? 'mb-0':'mb-5'} ${window.location.pathname == item.path ? 'bg-primary-100' : ''}  `} key={index} onClick={()=>navigate(item.path)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 mr-1 min-w-[78px] stroke-white-100 dark:stroke-white-100 transition-opacity delay-[300ms] ${window.location.pathname == item.path ? '' : 'group-hover:stroke-primary-100'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d={item.icon} />
                                            </svg>
                                            <div className='flex'>
                                                <span className={`no-underline text-[0.8rem] font-light text-white-100 dark:text-white-100 visited:text-white-100 dark:visited:text-white-100 active:text-gray-800 active:text-white-100 transition-opacity ${window.location.pathname == item.path ? '' : 'group-hover:text-primary-100'} ${showSide ? 'block transition-opacity delay-[300ms] opacity-100 ' : 'pointer-events-none opacity-0'} `}>
                                                    {item.title}
                                                </span>
                                            </div>
                                            <span className={`w-fit text-[0.5rem] bg-gray-500 border border-gray-700 rounded-md text-white-100 dark:bg-dark-50 dark:text-white-100 absolute p-1 top-[-20px] left-[50%] -translate-x-2/4 transition delay-[300ms] hidden ${showSide ? '' : 'group-hover:block'}`}>
                                                {item.title}
                                            </span>
                                        </li>

                                    )
                                    }else{
                                        return null
                                    }
                                    
                                }
                            
                            )

                        }

                    </ul>
                </div>
            </>
     
         
        </>
    )
}
export default Menus;