import React, { useState , useEffect} from 'react';
import { useNavigate , useParams } from 'react-router-dom';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { singleAnnouncement , editAnnouncement} from '../../../../services/announcements/announcementsService';
import {getAuthorsApi} from '../../../../services/authors/authorsService';
import FormEditBlog from '../../../common/forms/FormEditBlog';
import Loading from '../../../common/loading/Loading';


const EditAnnouncements = () => {

    const {id} = useParams();

    const navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState();
    const [ post , setPost ]= useState({});
    

    useEffect(()=>{
        const getAnnouncement = async()=>{
            const res = await singleAnnouncement(id);
            const resAuthors = await getAuthorsApi();


            if(res.data.status === 'success'){
                setPost(res.data.data.announcement);
                setForm( {
                    formItems: [
                    {
                        inputType: "input",
                        config: {
                            label: 'Title',
                            name: "title",
                            containerClasses : 'w-full'
                        },
                        value: res.data.data.announcement.title,
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input",
                        config: {
                            label: 'Slug',
                            name: "slug",
                            containerClasses : 'w-full'
                        },
                        value: res.data.data.announcement.slug,
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input",
                        config: {
                            label: 'Seo Description',
                            name: "seoDesc",
                            containerClasses : 'w-full'
                        },
                        value: res.data.data.announcement.seoDesc,
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "textarea",
                        config: {
                            label: 'Keywords',
                            name: "keywords",
                            containerClasses : 'w-full'
                        },
                        value: res.data.data.announcement.keywords,
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "ck-editor",
                        config: {
                            label: 'Body',
                            name: "body",
                            containerClasses : 'w-full'
                        },
                        value: res.data.data.announcement.body,
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input-file",
                        config: {
                            containerClasses : 'w-full' ,
                            label: 'Image',
                            name: "images",
                            type: "file",
                            t_label : 'attached_file'
                        },
                        value: "",
                        validation: {
                            maxSize: 5000000,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "selectAuthor",
                        config: {
                            options : resAuthors.data.data,
                            label: 'Author',
                            name: "authors",
                            containerClasses : 'w-full'
                        },
                        value: res.data.data.announcement.authors[0]._id ,
                        validation: {
                            selectRequired : true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                ]
                });

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }

            setLoading(false);
        }
        getAnnouncement();
    } , []);
        


    const handleSubmitForm = async(formData)=>{
        setLoadingForm(true);

        const {data} = await editAnnouncement(id , formData);
        if(data.status === 'success'){
            setLoadingForm(false); 
            toast.success('This announcement edited successfull');
            navigate('/announcements')

        }else if(data.status == 'error'){
            setLoadingForm(false);
            toast.error(data.msg);
        }

    }



    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />


            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 dark:text-white-100 text-xl font-extrabold'>Edit Announcements</span>
                    </div>
                    {
                        loading ? 
                        (
                            <Loading/>
                        )
                        :
                        (null)
                    }

                    <FormEditBlog initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Apply" file={true} post={post}/>

                </div>
            </div>
        </>
    )

}

export default EditAnnouncements ;
