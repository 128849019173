import {useState , useEffect} from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';

import { getCountUsersByDate } from "../../../services/indexPage/indexPageService";

const ChartUsers = ()=>{

  let [data , setData] = useState([]);
  let [loading , setLoading] = useState(false);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  useEffect(() => {
    try {

      const getUsersCont = async () => {
        let now = new Date();
        let endTime = moment(now).format('yyyy-MM-DD');
        let startTime = moment(endTime).startOf('month').subtract(6, 'M').format('yyyy-MM-DD');
        const { data : dataApi} = await getCountUsersByDate(startTime , endTime);
        if (dataApi.status === "success") {

          let newList = dataApi.data.reverse().map(item=>{
            return{
              name : months[(item.month)-1] ,
              count : item.n 
            }
          })
          setData(newList);
        }
      };
      getUsersCont();
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);


    return(
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="count" stroke="#8884d8" fill="#fbbf24" />
        </AreaChart>
      </ResponsiveContainer>
    )
}

export default ChartUsers ;


