import React, { useState , useEffect} from 'react';
import { ToastContainer , toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import {axiosApiInstance} from '../../../services/axios';
import ModalRemoveContact from '../../common/modals/modalRemoveContact/ModalRemoveContact';
import Button from "../../common/button/Button" ;
import Pagination from '../../common/pagination/Pagination';
import Loading from '../../common/loading/Loading';


const ContactForms = () => {

    const navigate = useNavigate();

    const [showModalRemoveContact , setShowModalRemoveContact] = useState(false);
    const[loading , setLoading] = useState(true);
    const [contacts, setContacts] = useState();
    const [page, setPage] = useState();
    const [pages, setPages] = useState();
    const [currentContact , setCurrentContact] = useState({});


    useEffect(()=>{

        const getUsers = async()=>{
            const res = await axiosApiInstance.get('/admin/feedback' );
            setLoading(false);
            if(res.data.status === 'success'){
                setContacts(res.data.data.feedbacks);
                setPage(res.data.data.contacts.page);
                setPages(res.data.data.contacts.pages);

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }

        }

        getUsers();

    } , []);


    const stateModalRemoveContact = (state , item) => {
        setShowModalRemoveContact(state);
        setCurrentContact(item);
    }


    const goPage = async (p) => {
        setLoading(true);
        const res = await axiosApiInstance.get(`/admin/feedback?page=${p}`);
        setLoading(false);
        if (res.data.status === 'success') {
            setContacts(res.data.data.contacts);
            setPage(res.data.data.contacts.page);
            setPages(res.data.data.contacts.pages);
        } else {
            toast.error(res.data.msg);
        }
    }


    const refresh = () => {
        goPage(page);
    }

    return (
        <>
            <ModalRemoveContact show={showModalRemoveContact} close={()=>setShowModalRemoveContact(false)} refresh={() => refresh()} item={currentContact}/>

            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />

            <div className="container mx-auto p-3 ">

                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 dark:text-white-100 text-xl font-extrabold'>Feedback</span>
                    </div>
                    {
                        loading ? 
                        (
                            <Loading/>
                        )
                        :
                        (
                            <div className='w-full flex flex-col justify-center items-start'>
                                <div className='text-base font-semibold	 mb-4 text-slate-600 dark:text-white-100'>
                                    List of all feedback
                                </div>
                                <div className='w-full text-[0.9rem] font-normal text-center border-collapse overflow-x-auto'>
                                    {contacts.docs.length > 0 ?
                                        (
                                            <>
                                                <table className="w-full table">
                                                    <thead>
                                                        <tr>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none align-middle h-20'>User</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none  align-middle h-20'>Subject</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none align-middle h-20'>Date</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none align-middle h-20'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { contacts.docs.map ((contact , index) =>(
                                                            <tr key={index} className="hover:bg-stone-100">
                                                                <td className='font-normal text-gray-700 text-[1rem] font-semibold border-none align-middle h-20'>
                                                                    <div className="w-full flex flex-col justify-center items-center">
                                                                        <img className='rounded-full' src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${contact.user?.avatar?.thumb}`} width="64" height="64" alt='user' />
                                                                        <span className=''>
                                                                            {
                                                                            contact.user.name !== "" ? contact.user.name : contact.user.phone
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className='font-normal text-gray-700 text-[1rem] font-semibold border-none align-middle h-20'>{contact.subject.length >30 ? `${(contact.subject).substring(0,30)} ...` :  contact.subject }</td>
                                                                <td className='font-normal text-gray-700 text-[1rem] font-semibold border-none align-middle h-20'>{moment(contact.updatedAt).fromNow()}</td>

                                                                <td className='flex items-center justify-center font-normal text-gray-700 text-[1rem] font-semibold border-none align-middle h-20'>
                                                                    <Button classes="px-2 py-1 mr-2 bg-yellow-400" click={()=>navigate(`/feedback/${contact.id}`)}>
                                                                        Show detail
                                                                    </Button>
                                                                    {/* <Button classes="px-2 py-1 bg-red-600" click={()=>stateModalRemoveContact(true , contact)}>
                                                                        Remove
                                                                    </Button> */}
                                                                </td>
                                                            </tr>
                                                            )
                                                        )
                                                        }
                                                    </tbody>
                                                </table>
                                                <Pagination pages={pages} current={page} action={goPage} />
                                            </>
                                        )
                                        :
                                        (
                                            <p className="caption-no-result">
                                                No items found
                                            </p>
                                        )
                                    }
                                </div>
                            </div> 
                        )
                    }
                </div>

            </div>
        </>
    )

}

export default ContactForms ;
