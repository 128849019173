import {axiosApiInstance} from '../axios';

export const getPayments = (p=1 , q='') =>{
    return axiosApiInstance.get(`/admin/pricing/payments?page=${p}&q=${q}`);
}

export const newBlog = (formData) =>{
    return axiosApiInstance.post(`/admin/blog/new` , formData);
}

export const singleBlog = (id) =>{
    return axiosApiInstance.get(`/admin/blog/edit/${id}`);
}

export const editBlog = (id , formData) =>{
    return axiosApiInstance.put(`/admin/blog/${id}` , formData);
}

export const removeBlog = (id) =>{
    return axiosApiInstance.delete(`/admin/blog/${id}`);
}


