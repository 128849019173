import {axiosApiInstance} from '../axios';

export const getCountUsers = () =>{
    return axiosApiInstance.get(`/admin/report/users/count`);
}


export const getCountUsersByDate = (starTime , endTime) =>{
    return axiosApiInstance.get(`/admin/report/users/count-by-date?start=${starTime}&end=${endTime}`);
}


export const getListUsersByDate = (startTime , endTime) =>{
    return axiosApiInstance.get(`/admin/report/users/list-by-date?start=${startTime}&end=${endTime}`);
}