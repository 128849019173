
const NewTickets = (props)=>{
    let {img , email , registerDate , classes , txt} = props;
    return(
        <>
            <div className={`${classes} flex justify-between gap-x-3 p-5`}>
                <div className='flex flex-col items-center'>
                    <img className='rounded-full w-[32px] h-[32px]' width="32" height="32" src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${img}`} /> 
                    <span className="text-gray-600 font-normal text-[0.8rem]">{email}</span>
                </div>

                <div className="flex grow">
                    <span className='text-gray-600 font-medium text-[0.8rem]'>{txt}</span>
                </div>
            </div>
            <div className="w-full flex justify-end">
                <span className='text-gray-600 font-black text-[0.7rem]'>Register Date:</span>
                <span className='text-gray-600 font-bold text-[0.7rem]'>{registerDate}</span>
            </div>
        </>
    )
}

export default NewTickets ;


