import React , {useState} from 'react';
import ScaleLoader  from "react-spinners/ScaleLoader";
//import PropsType from 'props-type';



const Button = (props) => {

    const[ isHover , setIsHover] = useState(false);

    return (
        <button type='submit' onMouseEnter={()=>setIsHover(true)} onMouseLeave={()=>setIsHover(false)} className={`${props.classes} flex items-center transition duration-300 ${props.disabled === 'true' ? 'bg-zinc-300 text-white-100' : 'cursor-pointer '} flex justify-center items-center relative hover:opacity-80 outline-none rounded-lg z-[10]`} 
        onClick={props.click}>
        
            {props.loading ?
                (
                    <ScaleLoader height={20} width={3} color={` ${isHover ? "#FFC20E" : "#374151" } `} size={5} />
                )
                :
                (
                    props.children
                )
            }
        </button>
    )
}


//props types
//Button.propTypes = propTypes.submit.boolian;


export default Button;