import React, { useState , useEffect} from 'react';
import {useNavigate , useParams} from 'react-router-dom';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {axiosApiInstance} from '../../../../../services/axios';
import { removePermission } from '../../../../../services/groups/groupsService';
import { addPermission } from '../../../../../services/groups/groupsService';
import Loading from '../../../../common/loading/Loading';
import CheckGroupPermissions from '../../../../common/input/CheckGroupPermissions';


const EditGroup = () => {

    const {id} = useParams();


    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [permissions , setPermissions] = useState([]); 
    

    useEffect(()=>{
        const getPermissions = async()=>{

            const resPermissions = await axiosApiInstance.get(`/admin/access/permissions?limit=20`);
            const resGroups = await axiosApiInstance.get(`/admin/access/groups/${id}`);


            if(resPermissions.data.status == 'success' && resGroups.data.status == 'success'){

                let permissionsList = resPermissions.data.data.map(subjectItem=>{
                    /* 
                    subjectItem =>    {
                        "_id": "653fb07b07d6243f91f7a4c6",
                        "subject": "Post",
                        "actions": [
                            "Read",
                            "Edit"
                        ]
                    }
                    */

                    let subjectIndexInGroup = resGroups.data.data.findIndex(sub => sub.permission.subject == subjectItem.subject);


                    let newactions = subjectItem.actions.map(actionsSubjext=>{
                        /* 
                        actionsSubjext => Read  
                        */
                        return{
                            title : actionsSubjext , 
                            status :  subjectIndexInGroup == -1 ? false  : resGroups.data.data[subjectIndexInGroup].actions.includes(actionsSubjext) ? true : false
                        }

                    });

                    return{
                        subject : subjectItem.subject ,
                        id : subjectItem._id ,
                        actions : newactions
                        
                    }
                });

                //console.log(permissionsList)

                setPermissions(permissionsList);
                
            }
            setLoading(false);
        }

        getPermissions();
    } , []);



    const inputChangeHandler = async(e)=>{

        let subject = e.subject.subject;
        let subjectId = e.subject.id ;
        let action = e.action.title;
        let operation = e.operation;
 
        // console.log('subject' , subject);
        // console.log('subjectId' , subjectId);
        // console.log('action' , action);
        // console.log('operation' , operation);
        

        try{

            if(operation){

                const {data} = await addPermission( {
                    groupId : id ,
                    permissionId  : subjectId ,
                    action 
                });
                if(data.status){
                    let newPermissions = [...permissions];
                    let subjectIndex = newPermissions.findIndex(item=>item.subject == subject);
                    let actionIndex =  newPermissions[subjectIndex].actions.findIndex(item=>item.title == action);
                    newPermissions[subjectIndex].actions[actionIndex].status = operation;
                    setPermissions(newPermissions);
                }else{
                    toast.error(data.msg);
                }
            }else{
                const {data} = await removePermission({
                    groupId : id ,
                    permissionId  : subjectId ,
                    action});
                if(data.status){
                    let newPermissions = [...permissions];
                    let subjectIndex = newPermissions.findIndex(item=>item.subject == subject);
                    let actionIndex =  newPermissions[subjectIndex].actions.findIndex(item=>item.title == action);
                    newPermissions[subjectIndex].actions[actionIndex].status = operation;
                    setPermissions(newPermissions);
                }else{
                    toast.error(data.msg);
                }
            }

        }catch(err){
            toast.error(err.response.data.msg)
        }


    }
        

    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" 
                style={{ zIndex: 5000000}}

                />


            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>Edit Group</span>
                    </div>

                    {
                        loading ?
                        (
                            <Loading/> 
                        )
                        :
                        (
                            null
                        )

                    }


                    <CheckGroupPermissions
                        config= {{
                            label : 'Title',
                            name: "title",
                            containerClasses : 'w-full' ,
                        }}
                        value={permissions}
                        change={(e) => inputChangeHandler(e)}
                    />
                              
                </div>
            </div>
        </>
    )

}

export default EditGroup ;
