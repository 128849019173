import React, { useState , useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { newGroup } from '../../../../../services/groups/groupsService';
import Form from '../../../../common/forms/Form';

const NewGroup = () => {
  
    const navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState({formItems: [
        {
            inputType: "input",
            config: {
                label: 'Name',
                name: "name",
                containerClasses : 'w-full'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "textarea",
            config: {
                label: 'Description',
                name: "description",
                containerClasses : 'w-full'
            },
            value: '',
            validation: {
            },
            valid: false,
            errorMsg: "",
            used: false
        },
    ]
    });
    

    const handleSubmitForm = async(formData)=>{
        
        try{

            setLoadingForm(true);

            const {data} = await newGroup(formData);
            if(data.status === 'success'){
                setLoadingForm(false); 
                toast.success('New group created successfull');
                navigate('/groups')

            }else if(data.status == 'error'){
                setLoadingForm(false);
                toast.error(data.msg);
            }

        }catch(err){
            setLoadingForm(false);
            toast.error(err.response.data.msg);
        }


    }


    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />


            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>New Group</span>
                    </div>
                              
                    <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Apply" />

 
                </div>
            </div>
        </>
    )

}

export default NewGroup ;
