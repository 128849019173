import { useState } from "react";
import { useNavigate } from 'react-router-dom' ;

import LoginForm from '../../common/forms/auth/LoginForm';

function Login() {

  const navigate = useNavigate();

  const[ btnLoading , setBtnLoading ]=useState(false);

  
  const changeBtnLoading = (state)=>{
    setBtnLoading(state);
  }

  const reDirec = ()=>{
    setTimeout(()=>{
      navigate('/');
    } , 100)
  }

  return (
    <>
      <div className='h-screen bg-background-100 w-full flex justify-center items-center p-5'>
        <div className='shadow-lg w-full bg-white-100 p-8 rounded-3xl max-w-[500px] text-base'>
          <p className="mb-5 text-[1.2rem] text-gray-600 font-bold ml-1">Login</p>
          <LoginForm reDirec={reDirec} btnLoading={btnLoading} changeBtnLoading={changeBtnLoading} />
        </div>
      </div>
    </>
  );
}

export default Login;
