import React, { useState , useEffect} from 'react';
import { ToastContainer , toast } from 'react-toastify';
import {useParams} from 'react-router-dom';

import Button from '../../common/button/Button';
import {showDateTimeUtc} from '../../../utils/showDate'
import {axiosApiInstance} from '../../../services/axios';
import ModalRemoveToken from '../../common/modals/modalRemoveToken/ModalRemoveToken';
import ModalAddToken from '../../common/modals/modalAddToken/ModalAddToken';
import ModalEditUser from '../../common/modals/modalEditUser/ModalEditUser';
import UserPayments from '../../common/userPayments/UserPayments';
import UserPlans from '../../common/userPlans/UserPlans';
import UserGiftCharge from '../../common/userGiftCharge/UserGiftCharge';
import EventsHistoryUser from '../../crm/eventsHistoryUser/EventsHistoryUser';

import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../common/loading/Loading';

const UserDetail = () => {

    const {id} = useParams();

    const [type, setType] = useState('personal_information');
    const[eventType , setEventType] = useState('waiting');

    const[loading , setLoading] = useState(true);
    const [user, setUser] = useState();

    const [showModalEditGiftCharge, setShowModalEditGiftCharge] = useState(false);
    const [showModalRemoveToken, setShowModalRemoveToken] = useState(false);
    const [showModalAddToken, setShowModalAddToken] = useState(false);
    const [currentTokenType , setCurrentTokenType] = useState({});

    const stateModalEditGiftCharge = (state ) => {
        setShowModalEditGiftCharge(state);
    }
    
    const stateModalRemoveToken = (state , item) => {
        setShowModalRemoveToken(state);
        setCurrentTokenType(item);
    }

    const stateModalAddToken = (state) => {
        setShowModalAddToken(state);
    }


    useEffect(()=>{

        const getUserDetail = async()=>{
            const res = await axiosApiInstance.get(`/admin/users/${id}`);
            setLoading(false);
            if(res.data.status === 'success'){
                setUser(res.data.data.user);
                //console.log(res.data.data.user);

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }

        }

        getUserDetail();

    } , []);

    const refreshEditGiftCharge = (newData)=>{
        console.log(newData)
        let newUser = user;
        newUser.name = newData.name ;
        newUser.giftCharge = newData.gift;
        newUser.balance = Number(newData.balance);
        setUser(newUser);
    }

    const refreshNew = (newTokenType)=>{
        let oldUser = user;
        oldUser.tokenTypes.push(newTokenType);
        setUser(oldUser);
    }


    const refreshRemove = (idRemoved)=>{
        let oldUser = user;
        let newTokenTypes = oldUser.tokenTypes.filter(token=>{
            return token !== idRemoved 
        });
        oldUser.tokenTypes = newTokenTypes;
        setUser(oldUser);
    }


    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />

            {
                showModalEditGiftCharge ?
                (
                    <ModalEditUser show={true} close={()=>setShowModalEditGiftCharge(false)} refresh={refreshEditGiftCharge} user={{userId : user.id , giftCharge : user.giftCharge ? user.giftCharge : 0 , name : user.name , balance : user?.balance}}/>
                )
                :
                (
                    null
                )
                
            }

            {
                showModalAddToken ?
                (
                    <ModalAddToken show={true} close={()=>setShowModalAddToken(false)} refresh={refreshNew} userId={user.id}/>
                )
                :
                (
                    null
                )
                
            }
            {
                showModalRemoveToken ?
                (
                    <ModalRemoveToken show={true} close={()=>setShowModalRemoveToken(false)} item={currentTokenType} userId={id}  refresh={() => refreshRemove(currentTokenType)}/>
                )
                :
                (
                    null
                )
                
            }


            <div className="container mx-auto p-3 ">

                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 dark:text-white-100 text-xl font-extrabold'>{`User Detail of ${user?.email} ${user?.email && user?.phone? ',' : ''} ${user?.phone}`}</span>
                    </div>
                    {
                        loading ? 
                        (
                            <Loading />

                        )
                        :
                        (
                            <div className='w-full flex flex-col justify-center items-start'>


                                <div className='w-full flex justify-start mb-[20px]'>
                                    <span onClick={() => setType("personal_information")} className={`px-1 text-[1rem] text-left text-gray-700 font-normal cursor-pointer hover:opacity-70 duration-150 pb-3 ${type == "personal_information" && "border-b-2 border-primary-100"} mr-5`}>
                                        Personal Information
                                    </span>
                                    <span onClick={() => setType("transactions")} className={`px-1 text-[1rem] text-left text-gray-700 font-normal cursor-pointer hover:opacity-70 duration-150 pb-3 ${type == "transactions" && "border-b-2 border-primary-100"} mr-5`}>
                                        Transactions
                                    </span>
                                    <span onClick={() => setType("plans")} className={`px-1 text-[1rem] text-left text-gray-700 font-normal cursor-pointer hover:opacity-70 duration-150 pb-3 ${type == "plans" && "border-b-2 border-primary-100"} mr-5 `}>
                                        Plans
                                    </span>
                                    <span onClick={() => setType("giftCharge")} className={`px-1 text-[1rem] text-left text-gray-700 font-normal cursor-pointer hover:opacity-70 duration-150 pb-3 ${type == "giftCharge" && "border-b-2 border-primary-100"} mr-5 `}>
                                        Gift Charge
                                    </span>
                                    <span onClick={() => setType("eventsLog")} className={`px-1 text-[1rem] text-left text-gray-700 font-normal cursor-pointer hover:opacity-70 duration-150 pb-3 ${type == "eventsLog" && "border-b-2 border-primary-100"} mr-5 `}>
                                        Events Log
                                    </span>
                                </div>


                                <div className={`w-full ${type == 'personal_information' ? 'block' : 'hidden'} `}>
                                    <div className='flex mb-5'>
                                        <img className='rounded-full' src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${user.avatar.thumb}`} width="64" height="64" alt='user' />
                                    </div>
                                    <div className='flex mb-5'>
                                        <span className='text-gray-700 text-[1rem] font-normal'>
                                            Id:
                                        </span>
                                        <span className='ml-2 text-gray-600 text-[1rem] font-medium'>
                                            {user.id}
                                        </span>
                                    </div>
                                    <div className='flex mb-5'>
                                        <span className='text-gray-700 text-[1rem] font-normal'>
                                            Name:
                                        </span>
                                        <span className='ml-2 text-gray-600 text-[1rem] font-medium'>
                                            {user.name}
                                        </span>
                                    </div>
                                    <div className='flex mb-5'>
                                        <span className='text-gray-700 text-[1rem] font-normal'>
                                            Email:
                                        </span>
                                        <span className='ml-2 text-gray-600 text-[1rem] font-medium'>
                                            {user.email}
                                        </span>
                                    </div>
                                    <div className='flex mb-5'>
                                        <span className='text-gray-700 text-[1rem] font-normal'>
                                            Phone:
                                        </span>
                                        <span className='ml-2 text-gray-600 text-[1rem] font-medium'>
                                            {user.phone}
                                        </span>
                                    </div>
                                    <div className='flex mb-5'>
                                        <span className='text-gray-700 text-[1rem] font-normal'>
                                            Gift Referral Charge:
                                        </span>
                                        <span className='ml-2 text-gray-600 text-[1rem] font-medium'>
                                            {user.giftCharge ? user.giftCharge : 0}
                                        </span>
                                    </div>
                                    <div className='flex mb-5'>
                                        <span className='text-gray-700 text-[1rem] font-normal'>
                                            About:
                                        </span>
                                        <span className='ml-2 text-gray-600 text-[1rem] font-medium'>
                                            {user.about}
                                        </span>
                                    </div>
                                    <div className='flex mb-5'>
                                        <span className='text-gray-700 text-[1rem] font-normal'>
                                            Referral Code:
                                        </span>
                                        <span className='ml-2 text-gray-600 text-[1rem] font-medium'>
                                            {user.referralCode}
                                        </span>
                                    </div>
                                    <div className='flex mb-5'>
                                        <span className='text-gray-700 text-[1rem] font-normal'>
                                            Register Date:
                                        </span>
                                        <span className='ml-2 text-gray-600 text-[1rem] font-medium'>
                                            {showDateTimeUtc(user.createdAt)}
                                        </span>
                                    </div>
                                    <div className='flex mb-5'>
                                        <span className='text-gray-700 text-[1rem] font-normal'>
                                            User role:
                                        </span>
                                        <span className='ml-2 text-gray-600 text-[1rem] font-medium'>
                                            {user.admin? "Admin" : "User"}
                                        </span>
                                    </div>
                                    <div className='flex mb-5'>
                                        <span className='text-gray-700 text-[1rem] font-normal'>
                                            Wallet:
                                        </span>
                                        <span className='ml-2 text-gray-600 text-[1rem] font-medium'>
                                            {user?.balance.toFixed(2)}$
                                        </span>
                                    </div>
                                    <Button classes='bg-primary-100 mx-auto border border-primary-100 text-[1rem] text-white-100 font-bold px-10 py-[10px] rounded-md' click={()=>stateModalEditGiftCharge(true)}>
                                        Edit  
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-2 stroke-white-100">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                        </svg>
                                    </Button>
                                </div>

                                <div className={`w-full ${type == 'transactions' ? 'block' : 'hidden'} `}>
                                    <UserPayments id={user.id}/>
                                </div>

                                <div className={`w-full ${type == 'plans' ? 'block' : 'hidden'} `}>
                                    <UserPlans id={user.id} activePlan={user.services.SCC}/>
                                </div>

                                <div className={`w-full ${type == 'giftCharge' ? 'block' : 'hidden'} `}>
                                    <UserGiftCharge id={user.id}/>
                                </div>

                                <div className={`w-full ${type == 'eventsLog' ? 'block' : 'hidden'} `}>
                                    
                                    <EventsHistoryUser id={user.id} type={'user'}/>
                                            
                                </div>

                            </div> 
                        )
                    }

                </div>

            </div>
        </>
    )

}

export default UserDetail
    
