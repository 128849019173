import React, { useState , useEffect} from 'react';
import {  toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';

import {axiosApiInstance} from '../../../services/axios';
import FormSearch from '../../common/forms/FormSearch';
import Pagination from '../../common/pagination/Pagination';
import {ticketStatusToText} from '../../../utils/ticketStatusToText';
import Loading from '../../common/loading/Loading';
import ModalLoading from '../../common/modals/modalLoading/ModalLoading';
import ModalRemoveTicket from '../../common/modals/modalRemoveTicket/ModalRemoveTicket';
import {checkPermissions} from '../../../utils/checkPermissions';
import { selectUser } from '../../../redux/slices/auth';

const Tickets = () => {

    const user = useSelector(selectUser);

    const navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [orders, setOrders] = useState();
    const [page, setPage] = useState();
    const [pages, setPages] = useState();
    const [loadingForm , setLoadingForm] = useState(false);
    const [currentTicket , setCurrentTicket] = useState({});
    const [showModalRemoveTicket , setShowModalRemoveTicket] = useState(false);

    const [ form ]= useState({
        formItems: [
            {
                inputType: "input",
                config: {
                    name: "key",
                    placeholder : "Search",
                    containerClasses : 'w-full'
                },
                value: '',
                validation: {
                },
                valid: false,
                errorMsg: "",
                used: false
            },
        ]
    });

    useEffect(()=>{

        const getUsers = async()=>{
            const res = await axiosApiInstance.get('/admin/tickets' );
            setLoading(false);
            if(res.data.status === 'success'){
                setOrders(res.data.data.tickets);
                setPage(res.data.data.tickets.page);
                setPages(res.data.data.tickets.pages);

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }
        }
        getUsers();
    } , []);

    const stateModalRemoveTicket = (state , item) => {
        setShowModalRemoveTicket(state);
        setCurrentTicket(item);
    }



    const goPage = async (p) => {
        setLoading(true);
        const res = await axiosApiInstance.get(`/admin/tickets?page=${p}`);
        setLoading(false);
        if (res.data.status === 'success') {
            setOrders(res.data.data.tickets);
            setPage(res.data.data.tickets.page);
            setPages(res.data.data.tickets.pages);
        } else {
            toast.error(res.data.msg);
        }
    }

    const handleFormSearch = async(q)=>{

        setLoadingForm(true);
        
        const res = await axiosApiInstance.get(`/admin/tickets?q=${q}`);
        setLoadingForm(false);
        if (res.data.status === 'success') {
            setOrders(res.data.data.tickets);
            setPage(res.data.data.tickets.page);
            setPages(res.data.data.tickets.pages);
        } else {
            toast.error(res.data.msg);
        }

    }



    return (
        <>
            {
                showModalRemoveTicket ?
                (
                    <ModalRemoveTicket show={true} close={()=>setShowModalRemoveTicket(false)} refresh={() => goPage(page)} ticket={currentTicket}/>
                )
                :
                (
                    null
                )
            }

            <ModalLoading show={loadingForm}/>


            <div className="container mx-auto p-3 ">
                

                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700  mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>Tickets</span>
                    </div>

                    {
                        loading ? 
                        (
                            <Loading/>
                        )
                        :
                        (
                            <div className='w-full flex flex-col justify-center items-start'>
                                <div className='w-full flex justify-between items-center text-base font-semibold mb-4 text-slate-600 dark:text-white-100'>
                                    List of all Tickets
                                    <FormSearch classesForm='w-[400px]' initForm={form} submit={handleFormSearch}/>
                                </div>
                                <div className='w-full text-[0.9rem] font-normal text-center border-collapse overflow-x-auto'>
                                    {orders.docs.length  ?
                                        (
                                            <>
                                                <table className="w-full table">
                                                    <thead>
                                                        <tr>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none align-middle h-20'>#</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none  align-middle h-20'>User</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none  align-middle h-20'>Subject</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none align-middle h-20'>Status</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none align-middle h-20'>Last update</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none align-middle h-20'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { orders.docs.map ((order , index) =>{
                                                            
                                                                if(true){
                                                                return(
                                                                    <tr key={index} className="hover:bg-stone-100">
                                                                        <td className='font-normal text-gray-700 text-[0.8rem] border-none align-middle h-20'>{ order.number }</td>
                                                                        <td className='font-normal text-gray-700 text-[0.8rem] border-none align-middle h-20'>
                                                                            <div className="w-full flex flex-col justify-center items-center">
                                                                                <img className='rounded-full w-[64px] h-[64px]' src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${order?.user?.avatar?.thumb}`} width="64" height="64" alt='user' />
                                                                                <span className=''>
                                                                                    {
                                                                                    order?.user?.name !== "" ? order?.user?.name : order?.user?.phone
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td className='font-normal text-gray-700 text-[0.8rem] border-none align-middle h-20'>{ order?.subject }</td>
                                                                        <td className='font-normal text-gray-700 text-[0.8rem] border-none align-middle h-20'>{ticketStatusToText(order?.status)}</td>
                                                                        <td className='font-normal text-gray-700 text-[0.8rem] border-none align-middle h-20'>{moment(order?.updatedAt).fromNow()}</td>
    
                                                                        <td className='flex items-center justify-center font-normal text-white-100 text-[1rem] border-none align-middle h-20'>
                                                                            {
                                                                                checkPermissions(user.type , user.permissions , 'Ticket-Modify')?
                                                                                (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-5 h-5 cursor-pointer stroke-gray-700 hover:stroke-primary-100" onClick={()=>navigate(`/tickets/${order.id}`)}>
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                                                                    </svg>

                                                                                )
                                                                                :
                                                                                (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-54 h-5 stroke-gray-700">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                                    </svg>
                                                                                )
                                                                            }
                                                                            {
                                                                                checkPermissions(user.type , user.permissions , 'Ticket-Delete')?
                                                                                (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}  className="w-5 h-5 cursor-pointer stroke-gray-700 hover:stroke-primary-100" onClick={()=>stateModalRemoveTicket(true , order)}>
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                                    </svg>

                                                                                )
                                                                                :
                                                                                (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-54 h-5 stroke-gray-700">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                                    </svg>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                               
                                                            }
                                                           
                                                        )
                                                        }
                                                    </tbody>
                                                </table>
                                                <Pagination pages={pages} current={page} action={goPage} />
                                            </>
                                        )
                                        :
                                        (
                                            <p className="caption-no-result">
                                                No items found
                                            </p>
                                        )
                                    }
                                </div>
                            </div> 
                        )
                    }
                </div>

            </div>
        </>
    )

}

export default Tickets ;
