import React, { useState , useEffect} from 'react';
import { useNavigate , useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {axiosApiInstance} from '../../../../../services/axios';
import { editAdmin } from '../../../../../services/admins/adminService';
import Form from '../../../../common/forms/Form';
import Loading from '../../../../common/loading/Loading';


const EditAdmin = () => {

    const {id} = useParams();
  
    const navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState({});


    useEffect(()=>{
        const getBlogCat = async()=>{
            const res = await axiosApiInstance.get(`/admin/admins/${id}`);
            const resGroups = await axiosApiInstance.get(`/admin/access/groups`);


            if(res.data.status == 'success' && resGroups.data.status == 'success'){
                setForm( {
                    formItems: [
                        {
                            inputType: "input",
                            config: {
                                label: 'Name',
                                name: "name",
                                containerClasses : 'w-full'
                            },
                            value: res?.data?.data?.name,
                            validation: {
                                required: true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "select-group",
                            config: {
                                options : [...resGroups.data.data] ,
                                label: 'Group',
                                name: "groupId",
                                containerClasses : 'w-full' ,
                                disable : res?.data?.data?.type == "Parent" ? true : false
                            },
                            value: res?.data?.data?.groupId,
                            validation: {
                                selectRequired : true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        }
                ]
                });

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }
            setLoading(false);
        }
        getBlogCat();
    } , []);
    

    const handleSubmitForm = async(formData)=>{

        try{

        setLoadingForm(true);

        const {data} = await editAdmin(id , formData) ;
        if(data.status === 'success'){
            setLoadingForm(false); 
            toast.success('New admin created successfull');
            navigate('/admins')

        }else if(data.status == 'error'){
            setLoadingForm(false);
            toast.error(data.msg);
        }

        }catch(err){
            setLoadingForm(false);
            toast.error(err.response.data.msg);
        }

    }


    return (
        <>
         
            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>Edit Admin</span>
                    </div>
                    {
                        loading ? 
                        (
                            <Loading/>
                        )
                        :
                        (null)
                    }    
                    <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Apply" />
                </div>
            </div>
        </>
    )

}

export default EditAdmin ;
