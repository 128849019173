import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { newTemplates } from "../../../../../services/crm/telegramTemplateService";
import Form from "../../../../common/forms/Form";

const NewTemplate = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [form, setForm] = useState({
    formItems: [
      {
        inputType: "input",
        config: {
          label: "Name",
          name: "name",
          containerClasses: "w-full",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        errorMsg: "",
        used: false,
      },
      {
        inputType: "textareaWithUpload",
        config: {
            label: 'Caption',
            name: "caption",
            rows : '10',
            containerClasses : 'w-full' ,
            bucket: 'telegram-template' ,
            templateTestMode : 'telegram'
        },
        value: '',
        validation: {
            required: true,
        },
        valid: false,
        errorMsg: "",
        used: false
    },
    {
        inputType: "input-file",
        config: {
            label: 'Image',
            name: "image",
            type: "file",
            t_label : 'attached_file',
            containerClasses : 'w-1/2'
        },
        value: "",
        validation: {
            maxSize: 5000000,
            isSelected : false ,
            validTypes : ['image/png' , 'image/jpg' , 'image/jpeg']
        },
        valid: false,
        errorMsg: "",
        used: false
    },
 
  
    ],
  });

  const handleSubmitForm = async (formData) => {
    try {
      setLoadingForm(true);

      const { data } = await newTemplates(formData);
      if (data.status === "success") {
        setLoadingForm(false);
        toast.success("New template created successfull");
        navigate("/crm/templates/telegram");
      } else if (data.status == "error") {
        setLoadingForm(false);
        toast.error(data.msg);
      }
    } catch (err) {
      setLoadingForm(false);
      toast.error(err.response.data.msg);
    }
  };

  return (
    <>
      <div className="container mx-auto p-3 ">
        <div className="bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none ">
          <div className="w-full flex justify-start items-center mb-5">
            <svg className="w-2 h-2 fill-gray-700 mr-2" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" />
            </svg>
            <span className="text-gray-700 text-xl font-extrabold">New Telegram Template</span>
          </div>

          <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Send" file={true}/>
        </div>
      </div>
    </>
  );
};

export default NewTemplate;
