import React, { useState , useEffect} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


import {axiosApiInstance} from '../../../../services/axios';
import ModalRemoveComment from '../../../common/modals/modalRemoveComment/ModalRemoveComment';
import Button from "../../../common/button/Button" ;
import Pagination from '../../../common/pagination/Pagination';
import Loading from '../../../common/loading/Loading';
import {checkPermissions} from '../../../../utils/checkPermissions';
import { selectUser } from '../../../../redux/slices/auth';


const AllComments = () => {

    const user = useSelector(selectUser);

    const navigate = useNavigate();

    const [showModalRemoveComment , setShowModalRemoveComment] = useState(false);
    const[loading , setLoading] = useState(true);
    const [comments, setComments] = useState();
    const [page, setPage] = useState();
    const [pages, setPages] = useState();
    const [currentComment , setCurrentComment] = useState({});

  
    useEffect(()=>{
        getComments();
    } , []);


    const stateModalRemoveComment = (state , item) => {
        setShowModalRemoveComment(state);
        setCurrentComment(item);
    }



    const getComments = async()=>{
        const res = await axiosApiInstance.get('/admin/comments' );
        console.log(res);
        setLoading(false);
        if(res.data.status === 'success'){
            setComments(res.data.data.comments);
            setPage(res.data.data.comments.page);
            setPages(res.data.data.comments.pages);

        }else if(res.data.status === 'error'){
            toast.error(res.data.msg);
        }
    }


    const refresh = () => {
        goPage(page);
    }


    const goPage = async (p) => {
        setLoading(true);
        const res = await axiosApiInstance.get(`/admin/comments?page=${p}`);
        setLoading(false);
        if (res.data.status === 'success') {
            setComments(res.data.data.comments);
            setPage(res.data.data.comments.page);
            setPages(res.data.data.comments.pages);
        } else {
            toast.error(res.data.msg);
        }
    }

    const changeApproved = async(id , state)=>{
        setLoading(true);
        const res = await axiosApiInstance.post(`/admin/comments/approved/${id}` , {state});
        if (res.data.status === 'success') {
            goPage(page);
        } else {
            toast.error(res.data.msg);
        }

    }

    

    return (
        <>
            <ModalRemoveComment show={showModalRemoveComment} close={()=>setShowModalRemoveComment(false)} refresh={() => refresh()} comment={currentComment}/>


            <div className="container mx-auto p-3 ">

                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 dark:text-white-100 text-xl font-extrabold'>Comments</span>
                    </div>
                    {
                        loading ? 
                        (
                            <Loading/>
                        )
                        :
                        (
                            <div className='w-full flex flex-col justify-center items-start'>
                                  <div className='w-full flex justify-between items-center'>
                                    <div className='text-base font-semibold	 mb-4 text-slate-600 dark:text-white-100'>
                                        List of comments
                                    </div>
                                </div>
            
                                <div className='w-full text-[0.9rem] text-center border-collapse overflow-x-auto'>
                                    {comments.docs.length > 0 ?
                                        (
                                            <>
                                                <table className="w-full table">
                                                    <thead>
                                                        <tr>
                                                            <th className='min-w-[120px] font-bold text-[0.9rem] text-gray-800 border-none  align-middle h-20'>User</th>
                                                            <th className='min-w-[120px] font-bold text-[0.9rem] text-gray-800 border-none  align-middle h-20'>Comment</th>
                                                            <th className='min-w-[120px] font-bold text-[0.9rem] text-gray-800 border-none  align-middle h-20'>Article</th>
                                                            <th className='min-w-[120px] font-bold text-[0.9rem] text-gray-800 border-none  align-middle h-20'>Approve</th>
                                                            <th className='min-w-[120px] font-bold text-[0.9rem] text-gray-800 border-none align-middle h-20'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { comments.docs.map ((comment , index) =>(
                                                            <tr key={index} className="hover:bg-stone-100">
                                                                <td className='font-normal text-gray-700 text-[0.7rem]  border-none align-middle h-20'>
                                                                    <div className="w-full flex flex-col justify-center items-center">
                                                                        <img className='rounded-full' src={`${process.env.REACT_APP_BACK_END_URL}${comment.user.avatar.thumb}`} width="64" height="64" alt='user' />
                                                                        <span className=''>
                                                                            {comment.user.name}
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td className='font-normal text-gray-700 text-[0.7rem]  border-none align-middle h-20'>{ comment.comment }</td>
                                                                <td className='font-normal text-gray-700 text-[0.7rem]  border-none align-middle h-20'>{ comment.blog?.title }</td>
                                                                <td className='font-normal text-gray-700 text-[0.7rem]  border-none align-middle h-20'>
                                                                    <div className='flex items-center justify-center '>
                                                                        {
                                                                            comment.approved?
                                                                            (
                                                                                <>
                                                                                {
                                                                                    checkPermissions(user.type , user.permissions , 'Comment-Modify')?    
                                                                                    (
                                                                                    <Button classes="px-2 py-1 mr-2 bg-yellow-400" click={()=>changeApproved(comment.id , false)}>
                                                                                        Reject
                                                                                    </Button>
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                                        </svg>
                                                                                    )
                                                                                }
                                                                                </>
                                                                            )
                                                                            :
                                                                            (
                                                                                <>
                                                                                   {
                                                                                        checkPermissions(user.type , user.permissions , 'Comment-Modify')?    
                                                                                        (
                                                                                            <Button classes="px-2 py-1 mr-2 bg-green-400" click={()=>changeApproved(comment.id , true)}>
                                                                                                Aproved
                                                                                            </Button>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                                            </svg>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                              
                                                                            )
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className='font-normal text-gray-700 text-[0.7rem] border-none align-middle h-20'>
                                                                    <div className='flex items-center justify-center '>
                                                                        {
                                                                            checkPermissions(user.type , user.permissions , 'Comment-Modify')?
                                                                            (
                                                                                <Button classes="px-2 py-1 mr-2 bg-yellow-400" click={()=>navigate(`/comments/${comment.id}`)}>
                                                                                    Edit
                                                                                </Button>
                                                                            )
                                                                            :
                                                                            (
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                                </svg>
                                                                            )

                                                                        }
                                                                       
                                                                        {
                                                                            checkPermissions(user.type , user.permissions , 'Comment-Delete')?
                                                                                (
                                                                                    <Button classes="px-2 py-1 bg-red-600" click={()=>stateModalRemoveComment(true , comment)}>
                                                                                        Remove
                                                                                    </Button>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                                    </svg>
                                                                                )
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            )
                                                        )
                                                        }
                                                    </tbody>
                                                </table>
                                                <Pagination pages={pages} current={page} action={goPage} />
                                            </>
                                        )
                                        :
                                        (
                                            <p className="caption-no-result">
                                                No items found
                                            </p>
                                        )
                                    }
                                </div>
                            </div> 
                        )
                    }
                </div>

            </div>
        </>
    )

}

export default AllComments ;
