import React , {useState , useEffect , useRef} from 'react';

import Loading from "../loading/Loading";
import {axiosApiInstance} from '../../../services/axios';




const SelectUsersMultiSearchApi = ({allProps}) => {

    const elBtn = useRef();
    const el = useRef();

    const [selectedItem , setSelectedItem] = useState([]);

    const [filteredItem , setFilteredItems] = useState([]);
    const [loading , setLoading] = useState(false);
    const [timeOut, setTimeOut] = useState(true);
    const [key, setKey] = useState("");


    const addChangeHandler = (item)=>{
        allProps.change({action : 'add' , item });
    }

    const removeChangeHandler = (id)=>{
        allProps.change({action : 'remove' , id });
    }

    useEffect(() => {
        
        let result = filteredItem.filter(item=>{

            if( (allProps.valueFull).some(item2=>item2._id == item._id) ){
                return false;
            }else{
                return true;
            }
            
        });

        if(result.length !== 0){
            setKey('');
        }

        setFilteredItems(result);

      
        setSelectedItem(allProps.valueFull);

        
    }, [allProps.valueFull.length]);








    const searchHandler = (e)=>{

        if (timeOut) {
            clearTimeout(timeOut);
        }
      
        let newTimeOuut = setTimeout(() => {
        if (newTimeOuut === timeOut) {
            setTimeOut(null);
        }
    
        if (e.target.value !== "") {
            setKey(e.target.value);
            searchApiHandler(e.target.value);
        } else {
            setFilteredItems([]);
            setKey("")
        }
        }, 1000);
    
        setTimeOut(newTimeOuut);
   
    }

    const searchApiHandler = async (key) => {
        setFilteredItems([]);
        setLoading(true);

        const {data} = await axiosApiInstance.get(`/admin/users/crm?q=${key}`);

        setLoading(false);

        if(data.status == "success"){

            let result = data.data.filter(item=>{

            
                if( (allProps.valueFull).some(item2=>item2._id == item._id) ){
                    return false;
                }else{
                    return true;
                }
                
            });

            if(result.length !== 0){
                setKey('');
            }

            setFilteredItems(result);

        }else{
            setFilteredItems([]);
            setKey('');
        }
    };


    return (
        <div className={`${allProps.config.containerClasses} flex flex-col justify-center mb-5`} >    
            <div className='flex justify-start items-center mx-[5px]' >
                <span className='text-gray-700 font-light text-[0.8rem]'>{allProps.config.label}</span>
            </div>
            <div className={`w-full h-[200px] overflow-y-auto p-1 border rounded-lg mb-2 text-slate-500 text-base transition-all duration-500 focus:border-sky-500 ${!allProps.valid && allProps.used ? 'border-red-500' : 'border-gray-200'} ${allProps.classes}`}>
                <div className='flex  items-start justify-start cursor-pointer hover:border-red-500 z-[10]' ref={elBtn}>
                    <div className='flex gap-y-2 flex-wrap justify-start items-center'>
                        <input className='hidden' name={allProps.config.name} value={allProps.value}></input> 
                        {
                            selectedItem.length ? 
                            (
                                selectedItem.map((item , index)=>(
                                    <div  key={index} className={`flex items-center p-1 bg-gray-200 rounded-md ${index !== (selectedItem.length-1) ? 'mr-2' : ''}`}>
                                        
                                        <div className='flex justify-start items-center'>
                                            <img className='rounded-full w-[64px] h-[64px]' src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${item.avatar.thumb}`} width="64" height="64" alt='user' />
                                            <div className='flex flex-col'>
                                                <span className='text-[0.7rem] font-light text-gray-800'>{item.name}</span>
                                                <span className='text-[0.7rem] font-light text-gray-800'>{item.email}</span>
                                            </div>
                                        </div>


                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-4 h-4 ml-2 stroke-gray-400 hover:stroke-gray-500" onClick={()=>removeChangeHandler(item._id)}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                ))
                            )
                            :
                            (
                                null
                            )
                        } 
                    </div> 
                    

                </div>
            </div>
            <div className='w-full bg-white-100 border border-gray-200 bg-white-100 rounded-lg z-[20000]' ref={el} >
                    <div className='flex items-center rounded-3xl mx-[5px] my-[10px] bg-gray-100 relative'>
                        <input className='w-full h-[40px] rounded-3xl bg-gray-100 pl-[10px] text-[0.9rem] leading-800 font-dana outline-none focus:border-backgroundInput-100 ' placeholder='Search here...' type="text"  onChange={(e)=>searchHandler(e)}/>
                        <div className='border-none w-[50px] rounded-r-[40px] flex justify-center items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-white-100 h-4 w-3 stroke-gray-500" fill="none" viewBox="0 0 24 24" strokeWidth={3}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </div>
                    </div>
                    <div className='max-h-[200px] overflow-y-auto'>
                    {
                        loading ? 
                        (
                            <>
                                <Loading classes='h-fit pt-2 pb-3'/>
                            </>
                        )   
                        :
                        (
                            null
                        ) 
                    }
                    {
                        filteredItem.map((item , index)=>(
                            <div className='flex items-center gap-x-2 justify-start hover:bg-gray-100 cursor-pointer px-3 py-1' key={index} onClick={()=>addChangeHandler(item)}>
                                <img className='rounded-full w-[64px] h-[64px]' src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${item.avatar.thumb}`} width="64" height="64" alt='user' />
                                <div className='flex flex-col'>
                                    <span className='text-[0.7rem] font-light text-gray-800'>{item.name}</span>
                                    <span className='text-[0.7rem] font-light text-gray-800'>{item.email}</span>
                                </div>
                            </div>
                        ))
                    }
                    {
                        key !== '' && filteredItem.length == 0 && !loading?
                        (
                            <span className='text-sm text-center block my-3'>
                                No user found :(
                            </span>
                        )
                        :
                        (
                            null
                        )
                    }
                    </div>
                
            </div>
            {
                allProps.errorMsg !== '' ?
                (
                    <div className='flex justify-between h-5 w-full px-[5px]'>
                        <span className='text-sm text-red-500 font-light'>{allProps.errorMsg}</span>
                    </div>
                )
                :
                (
                    null
                )
            } 

        </div>
    
    )
}

export default SelectUsersMultiSearchApi;