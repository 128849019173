import {axiosApiInstance} from '../axiosCRM';

export const getEvents = (p) =>{
    return axiosApiInstance.get(`/api/v1/events`);
}

export const newEvent = (formData , type) =>{
    

    if(type === 'plan'){
        return axiosApiInstance.post(`/api/v1/events/plan`, formData );
    }else if(type === 'registeration'){
        return axiosApiInstance.post(`/api/v1/events/registeration`, formData );
    }else if(type === 'forgetPassword'){
        return axiosApiInstance.post(`/api/v1/events/forget-password`, formData );
    }else if(type === 'emailVerification'){
        return axiosApiInstance.post(`/api/v1/events/email-verification`, formData );
    }else if(type === 'general'){
        return axiosApiInstance.post(`/api/v1/events/general`, formData );
    }else if(type === 'portfolioSchedule'){
        return axiosApiInstance.post(`/api/v1/events/portfolio-schedule`, formData );
    }
}

export const singleEvent = (id) =>{
    return axiosApiInstance.get(`/api/v1/events/${id}`);
}

export const editEvent = (id , formData) =>{
    return axiosApiInstance.patch(`/api/v1/events/${id}` , formData);
}

export const removeEvent = (id) =>{
    return axiosApiInstance.delete(`/api/v1/events/${id}`);
}

export const sendEventTest = (formData , type) =>{

    if(type === 'GENERAL'){
        return axiosApiInstance.post(`/api/v1/jobs/general`, {
            ids : formData.ids ,
            requestAt : new Date().getTime()
        });
    }else if(type === 'PLAN'){

        let titmeStamp = new Date((formData.expireAt).replaceAll('/' , '-')).getTime();

        return axiosApiInstance.post(`/api/v1/jobs/plan`, {
            userId : formData.userId ,
            purchaseAt : new Date().getTime() ,
            expireAt : titmeStamp
        });

    }else if(type === 'REGISTERATION'){
        return axiosApiInstance.post(`/api/v1/jobs/registeration`, {
            userId : formData.userId ,
            registerAt : new Date().getTime()
        });
    }else if(type === 'VERIFICATION'){
        return axiosApiInstance.post(`/api/v1/jobs/email-verification`, {
            code : formData.code ,
            userId : formData.userId ,
            requestAt : new Date().getTime()
        });
    }else if(type == 'FORGET_PASSWORD'){
        return axiosApiInstance.post(`/api/v1/jobs/forget-password`, {
            link : formData.link ,
            userId : formData.userId ,
            requestAt : new Date().getTime()
        });
    }
}


export const waitingEventlogs = (id , p , type , state) =>{

    let query = `/api/v1/history?page=${p}&limit=5&userId=${id}`;


    if(type == 'user'){
        return axiosApiInstance.get(`/api/v1/history/waiting?page=${p}&limit=5&userId=${id}`);
    }else if(type == 'event'){
        return axiosApiInstance.get(`/api/v1/history/waiting?page=${p}&limit=5&eventId=${id}`);
    }
}


export const historyEventlogs = (id , p , type , state) =>{

    if(type == 'user'){
        let query = `/api/v1/history?page=${p}&limit=5&userId=${id}`;

        if (state !== null) {
            query += `&status=${state}`;
        }

        return axiosApiInstance.get(query);

    }else if(type == 'event'){

        let query = `/api/v1/history?page=${p}&limit=5&eventId=${id}`;

        if (state !== null && state !== 'null') {
            query += `&status=${state}`;
        }
        
        return axiosApiInstance.get(query);
    }
}

