import { axiosApiInstance } from "../axiosCRM";

export const getTemplates = (page, limit = 10) => {
  return axiosApiInstance.get(`/api/v1/templates/email?page=${page}&limit=${limit}`);
};


export const newTemplates = (formData) => {

  let re = /<\/body>(?![\s\S]*<\/body>[\s\S]*$)/i; 
  let html = formData.html;
  let imgTag = '<img src="$$$seen_api$$$" style="display: none;"/></body>'; 
  let resultHtml = html.replace(re, imgTag);

  return axiosApiInstance.post(`/api/v1/templates/email`, {
    html : resultHtml , 
    name : formData.name , 
    subject : formData.subject
  });

};

export const singleTemplate = (id) => {
  return axiosApiInstance.get(`/api/v1/templates/email/${id}`);
};

export const editTemplate = (id, formData) => {
  return axiosApiInstance.put(`/api/v1/templates/email/${id}`, formData);
};

export const removeTemplate = (id) => {
  return axiosApiInstance.delete(`/api/v1/templates/email/${id}`);
};


export const attachTemplate = ( eventId , templateId) =>{
  let body = {
      eventId ,
      templateId 
  }
  return axiosApiInstance.post(`/api/v1/templates/email/attach` , body);
}

export const deattachTemplate = ( eventId , templateId) =>{
  return axiosApiInstance.delete(`/api/v1/templates/email/detach` , {
      data:{
      eventId ,
      templateId 
      }
  });
}

export const uploadImage = (formData) => {
  return axiosApiInstance.post(`/api/v1/storage/upload`, formData );
};
