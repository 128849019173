import React, { useState , useEffect} from 'react';
import {  toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {axiosApiInstance} from '../../../../services/axios';
import {showDateTime} from '../../../../utils/showDate';
import ModalLoading from '../../../common/modals/modalLoading/ModalLoading';
import FormSearch from '../../../../components/common/forms/FormSearch';
import ModalRemoveBlog from '../../../common/modals/modalRemoveBlog/ModalRemoveBlog';
import Button from "../../../common/button/Button" ;
import Pagination from '../../../common/pagination/Pagination';
import Loading from '../../../common/loading/Loading';
import {checkPermissions} from '../../../../utils/checkPermissions';
import { selectUser } from '../../../../redux/slices/auth';


const AllPosts = () => {

    const user = useSelector(selectUser);

    const navigate = useNavigate();

    const [showModalRemoveBlog, setShowModalRemoveBlog] = useState(false);
    const[loading , setLoading] = useState(true);
    const [blogs, setBlogs] = useState();
    const [page, setPage] = useState();
    const [pages, setPages] = useState();
    const [q, setQ] = useState('');
    const [currentBlog , setCurrentBlog] = useState({});
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form ]= useState({
        formItems: [
            {
                inputType: "input",
                config: {
                    name: "key",
                    placeholder : "Search" ,
                    containerClasses :'w-full'
                },
                value: '',
                validation: {
                },
                valid: false,
                errorMsg: "",
                used: false
            },
        ]
    });
    


    useEffect(()=>{
        getBlogCat();
    } , []);


    const stateModalRemoveBlog = (state , item) => {
        setShowModalRemoveBlog(state);
        setCurrentBlog(item);
    }



    const getBlogCat = async()=>{
        const res = await axiosApiInstance.get('/admin/blog' );
        setLoading(false);
        if(res.data.status === 'success'){
            setBlogs(res.data.data.blogs);
            setPage(res.data.data.blogs.page);
            setPages(res.data.data.blogs.pages);

        }else if(res.data.status === 'error'){
            toast.error(res.data.msg);
        }
    }


    const refresh = () => {
        goPage(page);
    }


    const goPage = async (p) => {
        setLoading(true);
        const res = await axiosApiInstance.get(`/admin/blog?page=${p}&q=${q}`);
        setLoading(false);
        if (res.data.status === 'success') {
            setBlogs(res.data.data.blogs);
            setPage(res.data.data.blogs.page);
            setPages(res.data.data.blogs.pages);
        } else {
            toast.error(res.data.msg);
        }
    }

    const handleFormSearch = async(q)=>{
        setQ(q);
        setLoadingForm(true);
        
        const res = await axiosApiInstance.get(`/admin/blog?q=${q}`);
        setLoadingForm(false);
        if (res.data.status === 'success') {
            setBlogs(res.data.data.blogs);
            setPage(res.data.data.blogs.page);
            setPages(res.data.data.blogs.pages);
        } else {
            toast.error(res.data.msg);
        }

    }

    

    return (
        <>
            <ModalLoading show={loadingForm}/>
            <ModalRemoveBlog show={showModalRemoveBlog} close={()=>setShowModalRemoveBlog(false)} refresh={() => refresh()} catBlog={currentBlog}/>

            <div className="container mx-auto p-3 ">

                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>Blog Posts</span>
                    </div>
                    {
                        loading ? 
                        (
                            <Loading/>
                        )
                        :
                        (
                            <div className='w-full flex flex-col justify-center items-start'>
                                    <div className='w-full flex justify-end items-center'>
                                        {
                                            checkPermissions(user.type , user.permissions , 'Blog-Write')?
                                            (
                                                <Button classes="w-[200px] py-2 bg-primary-100 border border-primary-100 hover:bg-transparent hover:text-primary-100 text-gray-600 font-semibold" click={()=>navigate('/blog/new')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-1">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    New Post
                                                </Button>
                                            )
                                            :
                                            (
                                                null
                                            )
                                        }
                                        <div className='w-full flex justify-end items-center'>
                                        <FormSearch classesForm='w-[400px]' initForm={form} submit={handleFormSearch}/>
                                        </div>
                                    </div>
                                   
            
                                <div className='w-full text-[0.9rem] text-center border-collapse'>
                                    {blogs.docs.length > 0 ?
                                        (
                                            <>
                                                <table className="w-full table">
                                                    <thead className='sticky top-[60px] bg-white-100 z-10'>
                                                        <tr>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none  align-middle h-20'>Title</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none  align-middle h-20'>Image</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none  align-middle h-20'>Publication Status</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none  align-middle h-20'>Date of Publish</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none align-middle h-20'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { blogs.docs.map ((blog , index) =>(
                                                            <tr key={index} className="hover:bg-stone-100">
                                                                <td className='font-normal text-[1rem] text-gray-700 border-none align-middle h-20'>{ blog.title }</td>
                                                                <td className='font-normal text-[1rem] text-gray-700 border-none align-middle h-20'>
                                                                    {/* <img className='m-auto' src={`${process.env.REACT_APP_BACK_END_URL}${blog.thumb}`} width={100} height={100} /> */}
                                                                    <img className='m-auto rounded-lg' src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${blog.thumb}`} width={100} height={100} />
                                                                </td>
                                                                <td className='font-normal text-[1rem] text-gray-700 border-none align-middle h-20'>{ blog.publicationStatus == '1' ? 'publication' : 'draft' }</td>
                                                                <td className='font-normal text-[1rem] text-gray-700 border-none align-middle h-20'>{blog.publicationStatus == '1' ? showDateTime(blog.publishTimestamp) : '-'}</td>
                                                                <td className='flex items-center justify-center font-semibold text-[1rem] text-gray-700 border-none align-middle h-20'>
                                                                   
                                                                    {
                                                                        checkPermissions(user.type , user.permissions , 'Blog-Modify')?
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer hover:stroke-primary-100" onClick={()=>navigate(`/blog/${blog.id}`)}>
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                                                            </svg>

                                                                        )
                                                                        :
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                            </svg>
                                                                        )
                                                                    }
                                                                    {
                                                                        checkPermissions(user.type , user.permissions , 'Blog-Delete')?
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer hover:stroke-primary-100" onClick={()=>stateModalRemoveBlog(true , blog)}>
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                            </svg>

                                                                        )
                                                                        :
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                            </svg>
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                            )
                                                        )
                                                        }
                                                    </tbody>
                                                </table>
                                                <Pagination pages={pages} current={page} action={goPage} />
                                            </>
                                        )
                                        :
                                        (
                                            <p className="caption-no-result">
                                                No items found
                                            </p>
                                        )
                                    }
                                </div>
                            </div> 
                        )
                    }
                </div>

            </div>
        </>
    )

}

export default AllPosts ;
