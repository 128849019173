import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {axiosApiInstance} from '../../../services/axios';
import Form from '../forms/Form';


const UserGiftCharge = (props) => {

    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState({});

    const initForm = {
        formItems: [
            {
                inputType: "input",
                config: {
                    label: 'Gift Charge',
                    name: "giftCharge",
                    containerClasses : 'w-full',
                },
                value: '',
                validation: {
                    isDigit: true,
                },
                valid: false,
                errorMsg: "",
                used: false
            },
            {
                inputType: "input",
                config: {
                    label: 'Gift Title',
                    name: "giftTitle",
                    containerClasses : 'w-full',
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                errorMsg: "",
                used: false
            },
        ]
    };

    useEffect(()=>{
        setForm(initForm);
    } , [])
    

    const resetForm = ()=>{
        setForm(initForm);

    }
    
    const handleSubmitForm = async(formData)=>{
        setLoadingForm(true);

        const {data} = await axiosApiInstance.post(`/admin/users/edit/gift-charge/${props.id}` , formData) ;
        if(data.status === 'success'){
            resetForm();
            setLoadingForm(false); 
            toast.success('User Plan changed successfull');

        }else if(data.status == 'error'){
            setLoadingForm(false);
            toast.error(data.msg);
        }
    }


   



    return (
        <>
            <div className="container mx-auto p-3 ">

                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my'>

                    <div className='w-full flex flex-col justify-center items-start'>
                        
                        <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Send" file={false}/>

                    </div> 
                    
                </div>

            </div>
        </>
    )

}

export default UserGiftCharge ;
