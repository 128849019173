import React, { useState } from 'react';
import parse from "html-react-parser";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import transition from "react-element-popper/animations/transition";
import opacity from "react-element-popper/animations/opacity";

import "react-multi-date-picker/styles/colors/teal.css";
import TextEditor from "../../textEditor/TextEditor";
import SelectBlogCat from './SelectBlogCat';
import SelectUsersMultiSearchApi from './SelectUsersMultiSearchApi';
import CheckboxBlogCat from './CheckboxBlogCat';
import CheckGroupPermissions from './CheckGroupPermissions';
import TextareaWithUpload from './TextareaWithUpload';
import InputFileUploadUpload from './InputFileUploadUpload';
import MyCheckbox from './MyCheckbox';

const Input = (props) => {
    let inputElement = null;
    
    const [isChecked, setIsChecked] = useState(props.config.initCheck ? true : false);
    const changeCheck = (e) => {
        setIsChecked((prev) => !prev);
        props.change(e) ;
    }

    // ---------- input file -------------
    const [fileInfo , setFileInfo] = useState('');
    const selectFileHandler = () => {
        let input = document.querySelector("#fileInput");
        input.click();
    }
    // ---------- /input file -------------

    switch (props.inputElement) {
        case 'input':
            inputElement =
                (
                    <div type={props.config.type ?? 'text'} className={`${props.config.containerClasses} flex flex-col justify-center mb-5 relative`} >
                        {
                            props.config.disable ? 
                            (
                                <div className="w-full h-full bg-gray-100 top-0 left-0 absolute opacity-40"></div>
                            )
                            :
                            (
                                null
                            )
                        }
                        <div className='flex justify-start items-center mx-[5px]' >
                            <span className='text-gray-800 font-light text-[0.8rem]'>{props.config.label}</span>
                        </div>
                        {
                           
                            <input className={`rounded-md px-1 py-2 bg-white-100 border outline-none w-full text-slate-600 text-[0.8rem] font-normal focus:border-primary-100 ${!props.valid && props.used ? '   border-red-500' : 'border-gray-200 '}`} autoComplete="off" {...props.config} onChange={props.change} value={props.value} ></input>
                            
                        }
                        {
                            props.errorMsg !== '' ? 
                            (
                                <div className='flex justify-start h-5 w-full px-[5px]'>
                                    <span className='text-sm text-red-500 font-light text-start'>{props.errorMsg}</span>
                                </div>

                            )
                            :
                            (
                                null
                            )
                        }
                        
                    </div>
                )
            break;
        case 'input-file':
            inputElement =
                (
                    <div className={`${props.config.containerClasses} flex flex-col justify-center`} >
                        <div className='flex justify-start items-center mx-[5px]' >
                            <span className='text-gray-800 font-light text-[0.8rem]'>{props.config.label}</span>
                        </div>
                            {
                                props.config.disable ?
                                (
                                    <>
                                        <input className={`rounded-lg p-2 hidden bg-white-100 border outline-none w-full text-slate-700 text-[0.9rem] font-medium focus:border-sky-500  dark:focus:-2-cyan-500 dark:bg-dark-50 dark:focus:border-cyan-500 dark:placeholder:text-white-50  dark:text-white-100 ${!props.valid && props.used ? '   border-red-500' : 'border-gray-200 dark:border-dark-50'}`} autoComplete="off" {...props.config} onChange={props.change} value={props.value} disabled></input>
                                        <div className='w-10 h-10 p-2 cursor-pointer flex items-center justify-center rounded-lg border border-dotted border-gray-700'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                                            </svg>

                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <input id='fileInput' className={`mx-auto rounded-lg p-1 hidden bg-white-100 border outline-none w-full text-slate-700 text-[0.9rem] font-medium focus:border-sky-500  dark:focus:-2-cyan-500 dark:bg-dark-100 dark:focus:border-cyan-500 dark:placeholder:text-white-50  dark:text-white-100`} autoComplete="off" {...props.config} onChange={props.change} value={props.value} ></input>
                                        <div className={`w-[100px] h-[100px] p-[2px] cursor-pointer ${props.used && props.value !== ''? '' : 'hover:bg-gray-100' } z-[100] flex items-center justify-center rounded-lg border  ${!props.valid && props.used ? ' border-dotted border-red-500' : 'border-gray-400 dark:border-dark-50 border-dotted'} `} onClick={(e) => props.value == '' &&  selectFileHandler(e)}>
                                            {
                                                props.used && props.value !== ''? 
                                                (
                                                    <div className='w-full h-full flex items-center justify-center relative'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 absolute right-[1px] top-[1px] cursor-pointer bg-gray-200 hover:bg-gray-300 z-[200]" onClick={props.emptyField}>
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                        {
                                                            (props.file.type).split('/')[0] == 'image' ?
                                                            (
                                                                <img className='w-full h-auto' src={URL.createObjectURL(props.file)}/>
                                                            )
                                                            :
                                                            (
                                                                <div className='w-full h-full bg-yellow-100 flex items-center justify-center'>
                                                                    <span className='font-medium'>{(props.file.name).split('.')[(props.file.name).split('.').length-1]}</span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                                :
                                                (
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                                                    </svg>
                                                )
                                            }
                                        </div> 
                                        {
                                            props.used &&  props.size ?
                                            (
                                                <div className={`flex flex-col`}>
                                                    <span className='w-fit text-[0.7rem] text-gray-700 mt-2'>
                                                    {props.size/1000} KB 
                                                    </span>
                                                    <span className='w-fit text-[0.7rem] text-gray-700'>
                                                        {props.filename} 
                                                    </span>
                                                </div>
                                            )
                                            :
                                            (
                                                null
                                            )
                                        }
                                    </>
                                )
                            }
                            <div className='flex justify-start h-5 w-full px-[5px]'>
                                <span className='text-sm text-red-500 font-light text-start'>{props.errorMsg}</span>
                            </div>
                     </div>
                )
            break;
        case 'textarea':
            inputElement =
                (
                    <div className={`${props.config.containerClasses} flex flex-col justify-center mb-5`} >
                        <div className='flex justify-start items-center mx-[5px]' >
                            <span className='text-gray-800 font-light text-[0.8rem]'>{props.config.label}</span>
                        </div>
                        <textarea rows={props.config.rows ?? '4'} className={`rounded-lg p-1 border outline-none w-full text-slate-500 font-medium focus:border-primary-100 ${!props.valid && props.used ? 'border-red-500' : 'border-gray-200 '}`} autoComplete="off" {...props.config} onChange={props.change} value={props.value}></textarea>
                        {
                            props.errorMsg !== '' ?
                            (
                                <div className='flex justify-between h-5 w-full px-[5px]'>
                                    <span className='text-sm text-red-500 font-light'>{props.errorMsg}</span>
                                </div>
                            )
                            :
                            (
                                null
                            )
                        }
                    </div>
                )
            break;   
        case 'ck-editor':
                inputElement =
                    (
                        <div className={`${props.config.containerClasses} mb-5 flex flex-col justify-center rounded-md border ${!props.valid && props.used ? "border-red-500" : "border-gray-200"}`}>
                            <div className='flex justify-start items-center h-full mx-[5px]' >
                                <span className='text-gray-700 font-light text-[0.8rem]'>{props.config.label}</span>
                            </div>

                            <TextEditor
                            name={props.config.name}
                            value={props.value}
                            onChange={props.change}/>
                            <input className='hidden' {...props.config} onChange={props.change} value={props.value}></input>
                            <div className='preview-body'>
                                <p>Preview</p>
                                {
                                parse(props.value)
                                }
                            </div>
                            {
                                props.errorMsg !== '' ?
                                (
                                    <div className='flex justify-between h-5 w-full px-[5px]'>
                                        <span className='text-sm text-red-500 font-light'>{props.errorMsg}</span>
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                            }
                        </div>
                    )
                break;    
        case 'select':
                inputElement =
                    (
                        <div className={`${props.config.containerClasses} flex flex-col justify-center mb-5 relative`}>
                            {
                                props.config.disable ? 
                                (
                                    <div className="w-full h-full bg-gray-100 top-0 left-0 absolute opacity-40"></div>
                                )
                                :
                                (
                                null
                                )
                            }
                             <div className='flex justify-start items-center h-full mx-[5px]' >
                                <span className='text-gray-700 font-light text-[0.8rem]'>{props.config.label}</span>
                            </div>
                            <select name={props.config.name} defaultValue={props.value} onChange={props.change} className={`w-full border bg-white-100 rounded-lg px-1 py-2 text-[0.8rem] outline-none text-slate-500 transition-all duration-500 focus:border-primary-100 ${!props.valid && props.used ? 'border-red-500' : 'border-gray-200'}`} >
                                <option value='0'>Please select...</option>
    
                                {
                                    props.config.options.map((option, index) => (
                                        <option value={option.id} key={index}>{option.title}</option>
                                    ))
                                }
                            </select>
                            {
                                props.errorMsg !== '' ?
                                (
                                    <div className='flex justify-between h-5 w-full px-[5px]'>
                                        <span className='text-sm text-red-500 font-light'>{props.errorMsg}</span>
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                            }
                        </div>
                    )
            break;
        case 'selectAuthor':
                inputElement =
                    (
                        <div className={`${props.config.containerClasses} flex flex-col justify-center mb-5`}>
                             <div className='flex justify-start items-center h-full mx-[5px]' >
                                <span className='text-gray-700 font-light text-[0.8rem]'>{props.config.label}</span>
                            </div>
                            <select name={props.config.name} defaultValue={props.value} onChange={props.change} className={`w-full border bg-white-100 rounded-lg px-1 py-2 text-[0.8rem] outline-none text-slate-500 transition-all duration-500 focus:border-primary-100 ${!props.valid && props.used ? 'border-red-500' : 'border-gray-200'}`} >
                                <option value='0'>Please select...</option>
    
                                {
                                    props.config.options.map((option, index) => (
                                        <option value={option._id} key={index}>{option.name}</option>
                                    ))
                                }
                            </select>
                            {
                                props.errorMsg !== '' ?
                                (
                                    <div className='flex justify-between h-5 w-full px-[5px]'>
                                        <span className='text-sm text-red-500 font-light'>{props.errorMsg}</span>
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                            }
                        </div>
                    )
            break;    
        case 'select-blog-publication-status':
                inputElement =
                    (
                        <div className={`${props.config.containerClasses} flex flex-col justify-center mb-5`}>
                             <div className='flex justify-start items-center h-full mx-[5px]' >
                                <span className='text-gray-700 font-light text-[0.8rem]'>{props.config.label}</span>
                            </div>
                            <select name={props.config.name} defaultValue={props.value} onChange={props.change} className={`w-full border rounded-lg px-1 py-2 bg-white-100 outline-none text-slate-500 text-[0.8rem] font-light transition-all duration-500 focus:border-primary-100 ${!props.valid && props.used ? 'border-red-500' : 'border-gray-200 '}`} >
                                <option className='text-[0.8rem] font-light' value='0' >Please select...</option>
                                {
                                    props.config.options.map((option, index) => (
                                        <option className='text-[0.8rem] font-light' value={option.id}  key={index}>{option.title}</option>
                                    ))
                                }
                            </select>
                            {
                                props.errorMsg !== '' ?
                                (
                                    <div className='flex justify-between h-5 w-full px-[5px]'>
                                        <span className='text-sm text-red-500 font-light'>{props.errorMsg}</span>
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                            }
                        </div>
                    )
            break;
        case 'select-group':
                inputElement =
                    (
                        <div className={`${props.config.containerClasses} flex flex-col justify-center mb-5`}>
                             <div className='flex justify-start items-center h-full mx-[5px]' >
                                <span className='text-gray-700 font-light text-[0.8rem]'>{props.config.label}</span>
                            </div>
                            <select name={props.config.name} defaultValue={props.value} onChange={props.change} className={`w-full border rounded-lg px-1 py-2 bg-white-100 outline-none text-slate-500 text-[0.8rem] font-light transition-all duration-500 focus:border-primary-100 ${!props.valid && props.used ? 'border-red-500' : 'border-gray-200 '}`} >
                                <option className='text-[0.8rem] font-light' value='0'>Please select...</option>
                                {
                                    props.config.options.map((option, index) => (
                                        <option className='text-[0.8rem] font-light' value={option._id} key={index}>{option.name}</option>
                                    ))
                                }
                            </select>
                            {
                                props.errorMsg !== '' ?
                                (
                                    <div className='flex justify-between h-5 w-full px-[5px]'>
                                        <span className='text-sm text-red-500 font-light'>{props.errorMsg}</span>
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                            }
                        </div>
                    )
            break;
        case 'select-plans':
                inputElement =
                    (
                        <div className={`${props.config.containerClasses} flex flex-col justify-center mb-5`}>
                            <div className='flex justify-start items-center h-full mx-[5px]' >
                                <span className='text-gray-700 font-light text-[0.8rem]'>{props.config.label}</span>
                            </div>
                            <select name={props.config.name} onChange={props.change} className={`w-full border rounded-lg p-4 outline-none text-slate-500 text-base transition-all duration-500 focus:border-sky-500 ${!props.valid && props.used ? 'border-red-500' : 'border-gray-200 '}`} >
                                <option value='0' selected={true}>Please select...</option>
                                {
                                    props.config.options.map((option, index) => (
                                        <option value={option._id} selected={option._id == props.value ? true : false} key={index}>{option.title} - duration: {option.duration} - price: {option.price}</option>
                                    ))
                                }
                            </select>
                            {
                                props.errorMsg !== '' ?
                                (
                                    <div className='flex justify-between h-5 w-full px-[5px]'>
                                        <span className='text-sm text-red-500 font-light'>{props.errorMsg}</span>
                                    </div>
                                )
                                :
                                (
                                    null
                                )
                            }
                        </div>
                    )
            break;    
        case 'checkbox':
                inputElement =
                    (
                        <div className={`${props.config.containerClasses} checkbox-wrapper font-roboto mb-5 relative`}>
                            {
                                props.config.disable ? 
                                (
                                    <div className="w-full h-full bg-gray-100 top-0 left-0 absolute opacity-40 z-10"></div>
                                )
                                :
                                (
                                null
                                )
                            }
                            <label className="flex justify-start items-center h-full text-gray-800 font-semibold text-[0.9rem]">
                                <input className={isChecked ? "checked" : ""} type="checkbox" checked={isChecked} onChange={(e) => changeCheck(e)}  value={props.value} />
                                <span className='text-gray-800 font-light text-[0.8rem]'>{props.config.label}</span>
                            </label>
                        </div>
                    )
                break;
        case 'select-blog-cat':
                inputElement =
                    (
                        <SelectBlogCat
                        allProps={props}/>
                    )
                break;
        case 'select-users-multi-search-api':
            inputElement =
                (
                    <SelectUsersMultiSearchApi
                    allProps={props}/>
                )
            break;        
        case 'checkbox-blog-cat':
            inputElement =
                (
                    <CheckboxBlogCat
                    allProps={props}/>
                )
            break;   
        case 'checkGroupPermissions':
                inputElement =
                    (
                        <CheckGroupPermissions
                        allProps={props}/>
                    )
            break;
        case 'textareaWithUpload':
                inputElement =
                    (
                        <TextareaWithUpload
                        allProps={props}/>
                    )
            break;
        case 'inputFileUpload':
                inputElement =
                    (
                        <InputFileUploadUpload
                        allProps={props}/>
                    )
            break;
        case 'myCheckbox':
                inputElement =
                    (
                        <MyCheckbox
                        {...props}/>
                    )
                break;                              
        case 'radio':
                inputElement =
                    (
                        <div className={`${props.config.containerClasses} checkbox-wrapper font-roboto mb-5`}>
                            <div className='flex justify-start items-center mx-[5px]' >
                                <span className='text-gray-700 font-light text-[0.8rem]'>{props.config.label}</span>
                            </div>
                            <div className='mt-1'>
                                {
                                    props.config.options.map((option, index) => (
                                        <div className='flex items-center' key={index}>
                                            <input type="radio" className='cursor-pointer' name={props.config.name} onChange={props.change} value={option.value} checked={props.value == option.value}/>
                                            <label className='ml-1 text-[0.8rem] font-light'>{option.title}</label>
                                        </div>
                                
                                    ))
                                }
                            </div>
                        </div>
                    )
            break;
        case 'date-input':
                inputElement =
                    (
                        <div className={`${props.config.containerClasses} flex flex-col justify-center mb-5`}>
                            <div className='flex justify-start items-center mx-[5px]' >
                                <span className='text-gray-700 font-light text-[0.8rem]'>{props.config.label}</span>
                            </div>
                            <div style={{ width: '100%' }}>
                                <DatePicker
                                    className="teal"
                                    animations={[
                                        opacity(),
                                        transition({ from: 35, duration: 800 })
                                    ]}
                                    editable={false}
                                    containerStyle={{
                                        width: "100%"
                                    }}
                                    render={<DateInput props={props} />}
                                    format="YYYY/MM/DD"
                                    // calendar={persian}
                                     // locale={persian_fa}
                                    calendarPosition="bottom-right"
                                    onChange={props.change}
                                />
                            </div>
                            <div className='flex justify-between h-5 w-full px-[5px]'>
                                <span className='text-sm text-red-500 font-light'>{props.errorMsg}</span>
                                <p></p>
                            </div>
                        </div>
                    )
            break;
        case 'date-time-input':
                inputElement =
                    (
                        <div className={`${props.config.containerClasses} flex flex-col justify-center mb-5`}>
                            <div className='flex justify-start items-center mx-[5px]' >
                                <span className='text-gray-700 font-light text-[0.8rem]'>{props.config.label}</span>
                            </div>
                            <div style={{ width: '100%' }}>
                                <DatePicker
                                    className="teal"
                                    animations={[
                                        opacity(),
                                        transition({ from: 35, duration: 800 })
                                    ]}
                                    editable={false}
                                    containerStyle={{
                                        width: "100%"
                                    }}
                                    render={<DateInput props={props} />}
                                    format="YYYY/MM/DD HH:mm:ss"
                                    plugins={[
                                        <TimePicker position="bottom" />
                                    ]} 
                                    // calendar={persian}
                                     // locale={persian_fa}
                                    calendarPosition="bottom-right"
                                    onChange={props.change}
                                />
                            </div>
                            <div className='flex justify-between h-5 w-full px-[5px]'>
                                <span className='text-sm text-red-500 font-light'>{props.errorMsg}</span>
                                <p></p>
                            </div>
                        </div>
                    )
            break;              
        default:
            inputElement =
                (
                    null
                )
    }
    return (
        <>
            {/* {!props.config.disable ? inputElement : null} */}
            {inputElement}
        </>
    )

}

export default Input;


function DateInput({ openCalendar, value, handleValueChange, props }) {
    return (
        <input style={{
            borderRadius: '.5rem',
            direction: 'rtl',
            padding: '10px',
            outline: 'none',
            /* max-width: 400px; */
            fontZize: '0.9rem',
            width: '100%',
            flexGrow: "1"
        }}
            autoComplete="off"
            onFocus={openCalendar}
            value={props.value}
            disabled={props.disable}
            className={`bg-white-100 border outline-none w-full rounded-lg p-4 text-slate-500 dark:placeholder:text-white-50 text-base focus:border-sky-500 dark:border-dark-50 ${!props.valid && props.used ? ' border-red-500' : 'border-gray-200 '} `} {...props.config}
        />
    )
}

