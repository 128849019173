import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import ModalSendEventTest from '../../../../common/modals/modalSendEventTest/ModalSendEventTest';
import Pagination from "../../../../common/pagination/Pagination";
import Loading from "../../../../common/loading/Loading";
import Button from "../../../../common/button/Button";
import { checkPermissions } from "../../../../../utils/checkPermissions";
import { selectUser } from "../../../../../redux/slices/auth";

const TestSendEvents = () => {
  const navigate = useNavigate();

  const [showModalSendEventTest , setShowModalSendEventTest] = useState(false);
  const [events] = useState([
    {
      group : 'PLAN'
    },
    {
      group : 'REGISTERATION'
    },
    {
      group : 'VERIFICATION'

    },
    {
      group : 'FORGET_PASSWORD'

    },
    {
      group : 'GENERAL'
    }
  ]);
  const [currentEvent, setCurrentEvent] = useState({});
  const [form  , setForm] = useState({});

  const [ PlanForm ]= useState({formItems: [
   
      {
        inputType: "select",
        config: {
            options :  [
              {id : '6574359428ed1afe34a3911d' , title: '6574359428ed1afe34a3911d'},
              {id : '659e5aeb7a0b980dbc4ec864' , title: '659e5aeb7a0b980dbc4ec864'},
              {id : '65990d32850753de08f43df9' , title: '65990d32850753de08f43df9'},
            ] ,
            label: 'userId',
            name: "userId",
            containerClasses : 'w-full'
        },
        value: [] ,
        validation: {
            notEmptyArray : true
        },
        valid: false,
        errorMsg: "",
        used: false
    },
    {
    inputType: "date-time-input",
    config: {
        label: 'Expire At',
        name: "expireAt",
        containerClasses : 'w-full',
    },
    value:  '' ,
    validation: {
        required: true,
    },
    valid: false,
    errorMsg: "",
    used: false
  },
]
});


  const [ verificationForm ]= useState({formItems: [
      {
        inputType: "input",
        config: {
            label: 'Code',
            name: "code",
            containerClasses : 'w-full'
        },
        value: '',
        validation: {
            required: true,
        },
        valid: false,
        errorMsg: "",
        used: false
    },
    {
      inputType: "select",
      config: {
          options :  [
            {id : '6574359428ed1afe34a3911d' , title: '6574359428ed1afe34a3911d'},
            {id : '659e5aeb7a0b980dbc4ec864' , title: '659e5aeb7a0b980dbc4ec864'},
            {id : '65990d32850753de08f43df9' , title: '65990d32850753de08f43df9'},
          ] ,
          label: 'userId',
          name: "userId",
          containerClasses : 'w-full'
      },
      value: [] ,
      validation: {
          notEmptyArray : true
      },
      valid: false,
      errorMsg: "",
      used: false
  },
]
});


const [ registeritionForm ]= useState({formItems: [
  {
    inputType: "select",
    config: {
        options :  [
          {id : '6574359428ed1afe34a3911d' , title: '6574359428ed1afe34a3911d'},
          {id : '659e5aeb7a0b980dbc4ec864' , title: '659e5aeb7a0b980dbc4ec864'},
          {id : '65990d32850753de08f43df9' , title: '65990d32850753de08f43df9'},
        ] ,
        label: 'userId',
        name: "userId",
        containerClasses : 'w-full'
    },
    value: [] ,
    validation: {
        notEmptyArray : true
    },
    valid: false,
    errorMsg: "",
    used: false
 },
]
});


const [ forgetPasswordForm ]= useState({formItems: [
  {
    inputType: "input",
    config: {
        label: 'Link',
        name: "link",
        containerClasses : 'w-full'
    },
    value: '',
    validation: {
        required: true,
    },
    valid: false,
    errorMsg: "",
    used: false
  }, 
  {
    inputType: "select",
    config: {
        options :  [
          {id : '6574359428ed1afe34a3911d' , title: '6574359428ed1afe34a3911d'},
          {id : '659e5aeb7a0b980dbc4ec864' , title: '659e5aeb7a0b980dbc4ec864'},
          {id : '65990d32850753de08f43df9' , title: '65990d32850753de08f43df9'},
        ] ,
        label: 'userId',
        name: "userId",
        containerClasses : 'w-full'
    },
    value: [] ,
    validation: {
        notEmptyArray : true
    },
    valid: false,
    errorMsg: "",
    used: false
 },
]
});


const [ generalForm ]= useState({formItems: [
      {
        inputType: "myCheckbox",
        config: {
            label: 'All users',
            name: "all",
            containerClasses : 'w-full' ,
            justShow : false ,
        },
        value: 'false',
        validation: {
        },
        valid: true,
        errorMsg: "",
        used: false
      },
      {
        inputType: "select-users-multi-search-api",
        config: {
            isdepend : true ,
            isdependfield : 'all' ,
            isdependvalue : 'false' ,
            options : [],
            label: 'Ids',
            name: "ids",
            containerClasses : 'w-full z-[50000000]'
        },
        value: [] ,
        valueFull : [] ,
        validation: {
            notEmptyArray : true
        },
        valid: false,
        errorMsg: "",
        used: false
    }
  ]
  });


  const stateModalSendEventTest = (state, item) => {
    
    if(item.group === 'VERIFICATION'){
      setForm(verificationForm);
    }else if(item.group === 'REGISTERATION'){
      setForm(registeritionForm);
    }else if(item.group === 'GENERAL'){
      setForm(generalForm);
    }else if(item.group == 'PLAN'){
      setForm(PlanForm);
    }else if(item.group == 'FORGET_PASSWORD'){
      setForm(forgetPasswordForm);
    }

    setCurrentEvent(item);
    setShowModalSendEventTest(state);
  };




  return (
    <>
 

      {
        showModalSendEventTest ? 
        (
          <ModalSendEventTest show={true} close={() => setShowModalSendEventTest(false)} form={form} eventItem={currentEvent}/>
        )
        :
        (
          null
        )
      }
      <div className="container mx-auto p-3 ">
        <div className="bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none ">
          <div className="w-full flex justify-start items-center mb-5">
            <svg className="w-2 h-2 fill-gray-700 mr-2" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" />
            </svg>
            <span className="text-gray-700 text-xl font-extrabold">Send Test Events</span>
          </div>

          <div className="w-full flex flex-col justify-center items-start">
            <div className="w-full text-[0.9rem] text-center border-collapse">
              {events.length > 0 ? (
                <>
                  <table className="w-full table">
                    <thead className="sticky top-[60px] bg-white-100">
                      <tr>
                        <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">Group</th>
                        <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">Send</th>
                      </tr>
                    </thead>
                    <tbody className="w-full">
                      {events.map((event, index) => (
                        <tr key={index} className="hover:bg-stone-100 duration-200 rounded-lg text-center p-10">
                          <td className="font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20">{event.group}</td>
                          

                          <td className="flex items-center gap-x-2 justify-center font-semibold text-[1rem] text-gray-700 border-none align-middle h-20">
                            
                            {true ? (
                              <div className="relative group">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer hover:stroke-primary-100" onClick={() => stateModalSendEventTest(true, event)}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                              </svg>

                                <span className="w-[160px] bg-slate-200 rounded-md p-1 font-light text-[0.8rem] absolute bottom-[25px] translate-x-[-50%] left-[50%] scale-0 group-hover:scale-105 duration-[50ms]">
                                  Send Test Event
                                </span>
                              </div>
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                              </svg>
                            )}

                        
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <p className="caption-no-result">No event found</p>
              )}
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default TestSendEvents;
