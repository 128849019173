import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import ModalRemoveEvent from "../../../../common/modals/modalRemoveEvent/ModalRemoveEvent";
import ModalAttachEmailTemplate from "../../../../common/modals/modalAttachEmailTemplate/ModalAttachEmailTemplate";
import ModalAttachTelegramTemplate from "../../../../common/modals/modalAttachTelegramTemplate/ModalAttachTelegramTemplate";
import { getEvents } from "../../../../../services/crm/eventService";
import Pagination from "../../../../common/pagination/Pagination";
import Loading from "../../../../common/loading/Loading";
import Button from "../../../../common/button/Button";
import { checkPermissions } from "../../../../../utils/checkPermissions";
import { selectUser } from "../../../../../redux/slices/auth";

const Events = () => {
  const navigate = useNavigate();

  const [showModalRemoveEvent, setShowModalRemoveEvent] = useState(false);
  const [showModalAttachEmailTemplate, setShowModalAttachEmailTemplate] = useState(false);
  const [showModalAttachTelegramTemplate, setShowModalAttachTelegramTemplate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState();
  const [page, setPage] = useState();
  const [pages, setPages] = useState();
  const [currentEvent, setCurrentEvent] = useState({});


  const stateModalRemoveEvent = (state, item) => {
    setShowModalRemoveEvent(state);
    setCurrentEvent(item);
  };

  const stateModalAttachEmailTemplate = (state, item) => {
    setShowModalAttachEmailTemplate(state);
    setCurrentEvent(item);
  };


  const stateModalAttachTelegramTemplate = (state, item) => {
    setShowModalAttachTelegramTemplate(state);
    setCurrentEvent(item);
  };


  useEffect(() => {
    const getAllEvents = async () => {
      const res = await getEvents(1);
      setLoading(false);
      if (res.data.status === "success") {
        setEvents(res.data.data);
        setPage(res.data.pagination.page);
        setPages(Math.ceil(res.data.pagination.count / res.data.pagination.limit));
      } else if (res.data.status === "error") {
        toast.error(res.data.msg);
      }
    };
    getAllEvents();
  }, []);

  const goPage = async (p) => {
    setLoading(true);
    const res = await getEvents(p);
    setLoading(false);
    if (res.data.status === "success") {
      setEvents(res.data.data);
      setPage(res.data.pagination.page);
      setPages(Math.ceil(res.data.pagination.count / res.data.pagination.limit));
    } else {
      toast.error(res.data.msg);
    }
  };

  const refresh = () => {
    goPage(page);
  };


  return (
    <>
      {
        showModalRemoveEvent ? 
        (
          <ModalRemoveEvent show={true} close={() => setShowModalRemoveEvent(false)} refresh={() => refresh()} event={currentEvent} />
        )
        :
        (
          null
        )
      }

      {
        showModalAttachEmailTemplate ? 
        (
          <ModalAttachEmailTemplate show={true} close={() => setShowModalAttachEmailTemplate(false)} refresh={() => refresh()} eventItem={currentEvent} />
        )
        :
        (
          null
        )
      }

      {
        showModalAttachTelegramTemplate ? 
        (
          <ModalAttachTelegramTemplate show={true} close={() => setShowModalAttachTelegramTemplate(false)} refresh={() => refresh()} eventItem={currentEvent} />
        )
        :
        (
          null
        )

      }
  
      <div className="container mx-auto p-3 ">
        <div className="bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none ">
          <div className="w-full flex justify-start items-center mb-5">
            <svg className="w-2 h-2 fill-gray-700 mr-2" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" />
            </svg>
            <span className="text-gray-700 text-xl font-extrabold">Events</span>
          </div>

          {loading ? (
            <Loading />
          ) : (
            <div className="w-full flex flex-col justify-center items-start">
              <div className="w-full flex justify-end items-center">
                {true ? (
                  <Button
                    classes="w-[200px] py-2 bg-primary-100 border border-primary-100 hover:bg-transparent hover:text-primary-100 text-gray-600 font-semibold"
                    click={() => navigate("/crm/events/new")}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    New Event
                  </Button>
                ) : null}
              </div>
              <div className="w-full text-[0.9rem] text-center border-collapse">
                {events.length > 0 ? (
                  <>
                    <table className="w-full table">
                      <thead className="sticky top-[60px] bg-white-100">
                        <tr>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">Name</th>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">Group</th>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">Waiting(emails/telegrams)</th>
                          <th className="min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="w-full">
                        {events.map((event, index) => (
                          <tr key={index} className="hover:bg-stone-100 duration-200 rounded-lg text-center p-10">
                            <td className="font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20">{event.name}</td>
                            <td className="font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20">{event.group}</td>
                            <td className="font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20">
                              {`${event?.waiting?.emails}/${event?.waiting?.telegrams}`}
                            </td>

                            <td className="flex items-center gap-x-2 justify-center font-semibold text-[1rem] text-gray-700 border-none align-middle h-20">
                        

                              {true ? (
                                <div className="relative group">
                                  <svg xmlns="http://www.w3.org/2000/svg" onClick={()=>navigate(`/crm/events/history/${event._id}`)} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer hover:stroke-primary-100">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12" />
                                  </svg>
                                  <span className="w-[160px] bg-slate-200 rounded-md p-1 font-light text-[0.8rem] absolute bottom-[25px] translate-x-[-50%] left-[50%] scale-0 group-hover:scale-105 duration-[50ms]">
                                    Show Logs
                                  </span>
                                </div>
                              ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                </svg>
                              )}


                              {true ? (
                                <div className="relative group">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5 cursor-pointer hover:stroke-primary-100"
                                    onClick={() => stateModalAttachTelegramTemplate(true, event)}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
                                    />
                                  </svg>
                                  <span className="w-[160px] bg-slate-200 rounded-md p-1 font-light text-[0.8rem] absolute bottom-[25px] translate-x-[-50%] left-[50%] scale-0 group-hover:scale-105 duration-[50ms]">
                                    Attach Telegram Template
                                  </span>
                                </div>
                              ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                </svg>
                              )}

                              {true ? (
                                <div className="relative group">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5 cursor-pointer hover:stroke-primary-100"
                                    onClick={() => stateModalAttachEmailTemplate(true, event)}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
                                    />
                                  </svg>
                                  <span className="w-[150px] bg-slate-200 rounded-md p-1 font-light text-[0.8rem] absolute bottom-[25px] translate-x-[-50%] left-[50%] scale-0 group-hover:scale-105 duration-[50ms]">
                                    Attach Email Template
                                  </span>
                                </div>
                              ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                </svg>
                              )}

                              {true ? (
                                <div className="relative group">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5 cursor-pointer hover:stroke-primary-100"
                                    onClick={() => navigate(`/crm/events/${event._id}`)}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                    />
                                  </svg>
                                  <span className="bg-slate-200 rounded-md p-1 font-light text-[0.8rem] absolute bottom-[25px] translate-x-[-50%] left-[50%] scale-0 group-hover:scale-105 duration-[50ms]">
                                    Edit
                                  </span>
                                </div>
                              ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                </svg>
                              )}

                              {true ? (
                                <div className="relative group">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5 cursor-pointer hover:stroke-primary-100"
                                    onClick={() => stateModalRemoveEvent(true, event)}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                  </svg>
                                  <span className="bg-slate-200 rounded-md p-1 font-light text-[0.8rem] absolute bottom-[25px] translate-x-[-50%] left-[50%] scale-0 group-hover:scale-105 duration-[50ms]">
                                    Remove
                                  </span>
                                </div>
                              ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                </svg>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination classes="" pages={pages} current={page} action={goPage} />
                  </>
                ) : (
                  <p className="caption-no-result">No event found</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Events;
