import React, { useState , useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';


import { newAdmin } from '../../../../../services/admins/adminService';
import Form from '../../../../common/forms/Form';

const NewAdmin = () => {
  
    const navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState({formItems: [
        {
            inputType: "input",
            config: {
                label: 'Name',
                name: "name",
                containerClasses : 'w-full'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "input",
            config: {
                label: 'Email',
                name: "email",
                containerClasses : 'w-full'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "input",
            config: {
                label: 'Password',
                name: "password",
                containerClasses : 'w-full'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select-blog-publication-status",
            config: {
                options : [
                    {id : 'Parent', title : 'Parent'} ,
                    {id : 'Child', title : 'Child'} ,
                ] ,
                label: 'Type',
                name: "type",
                containerClasses : 'w-full'
            },
            value: "0",
            validation: {
                selectRequired : true,
            },
            valid: false,
            errorMsg: "",
            used: false
        }
    ]
    });
    

    const handleSubmitForm = async(formData)=>{

        try{

            setLoadingForm(true);

            const {data} = await newAdmin(formData) ;
            if(data.status === 'success'){
                setLoadingForm(false); 
                toast.success('New admin created successfull');
                navigate('/admins')
    
            }else if(data.status == 'error'){
                setLoadingForm(false);
                toast.error(data.msg);
            }
    
        }catch(err){
            setLoadingForm(false);
            toast.error(err.response.data.msg);
        }
    }


    return (
        <>
            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>New Admin</span>
                    </div>
                                
                    <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Apply" />

                </div>
            </div>
        </>
    )

}

export default NewAdmin ;
