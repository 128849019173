import React, { useState , useEffect} from 'react';
import {useNavigate , useParams} from 'react-router-dom';
import { toast } from 'react-toastify';

import { singleEvent , editEvent } from '../../../../../services/crm/eventService';
import Form from '../../../../common/forms/Form';

const EditEvent = () => {
    const {id} = useParams();
  
    const navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [type , setType] = useState('');
    const [ form , setForm]= useState({});


    useEffect(()=>{
        const getEvent = async()=>{

            const res = await singleEvent(id);

            if(res.data.status === 'success'){
                setType(res.data.data.group == 'PLAN' ? 'plan' : 'registeration');
                setForm(
                    {formItems: [
                        {
                            inputType: "input",
                            config: {
                                label: 'Name',
                                name: "name",
                                containerClasses : 'w-full' ,
                                disable : true ,
                            },
                            value: res.data.data.name,
                            validation: {
                                required: true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "select",
                            config: {
                                label: 'Prerequisite',
                                name: "prerequisite",
                                containerClasses : 'w-full',
                                disable : true ,
                                options: [
                                    {id : 'NONE' , title: 'NONE'},
                                    {id : 'IF_DONT_BUY_PLAN' , title: 'IF_DONT_BUY_PLAN'},
                                ]
                            },
                            value: res.data.data.prerequisite,
                            validation: {
                                required: true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "checkbox",
                            config: {
                                label: 'Schedule',
                                name: "schedule",
                                containerClasses : 'w-full' ,
                                justShow : true ,
                                disable : true ,
                                initCheck : res.data.data.schedule == null ?  false : true ,
                            },
                            value: res.data.data.schedule == null ?  'off' :'on',
                            validation: {
                            },
                            valid: true,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "select",
                            config: {
                                disable : true ,
                                label: 'When',
                                name: "when",
                                containerClasses : 'w-1/3',
                                isdepend : true ,
                                isdependfield : 'schedule' ,
                                isdependvalue : 'on' ,
                                options: [
                                    {id : 'AFTER_REGISTER' , title: 'AFTER_REGISTER'},
                                    {id : 'AFTER_BUY' , title: 'AFTER_BUY'},
                                    {id : 'BEFORE_EXPIRETION' , title: 'BEFORE_EXPIRETION'},
                                    {id : 'AFTER_EXPIRETION' , title: 'AFTER_EXPIRETION'},
                                ]
                            },
                            value: res?.data?.data?.schedule?.when,
                            validation: {
                                required: true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        }, 
                        {
                            inputType: "input",
                            config: {
                                label: 'amount',
                                name: "n",
                                containerClasses : 'w-1/3 px-3' ,
                                isdepend : true ,
                                isdependfield : 'schedule' ,
                                isdependvalue : 'on' ,
                            },
                            value: res?.data?.data?.schedule?.n,
                            validation: {
                                required: true,
                                isDigit : true,
                            },
                            valid: true,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "select",
                            config: {
                                disable : true ,
                                label: "Unit" ,
                                name: "unit",
                                containerClasses : 'w-1/3',
                                options: [
                                    {id : 'd' , title: 'd'},
                                ],
                                isdepend : true ,
                                isdependfield : 'schedule' ,
                                isdependvalue : 'on' ,
                            },
                            value: res?.data?.data?.schedule?.unit,
                            validation: {
                                required: true,
                            },
                            valid: true,
                            errorMsg: "",
                            used: false
                        },
                    ]
                    }
                );

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }
            setLoading(false);
        }
        getEvent();
    } , []);
    

    

    const handleSubmitForm = async(formData)=>{

        try{

            let newFormData;

            newFormData = {
                n: formData.n !=='' ?  Number(formData.n) : null,
            }

            //console.log(newFormData)

            setLoadingForm(true);

            const {data} = await editEvent(id , newFormData);
            if(data.status === 'success'){
                setLoadingForm(false); 
                toast.success('New event created successfull');
                navigate('/crm/events')
    
            }else if(data.status == 'error'){
                setLoadingForm(false);
                toast.error(data.msg);
            }
    
        }catch(err){
            setLoadingForm(false);
            toast.error(err.response.data.message);
        }
    }


    return (
        <>
            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>Edit Event</span>
                    </div>

                    <div className='w-full text-center mb-10'>
                        <span className={`p-3 rounded-lg opacity-30 font-medium ${type == 'plan' ? 'bg-gray-500 text-white-100': ''}`}>Plan Event</span>
                        <span className={`p-3 rounded-lg opcatiyu-30 font-medium ${type == 'registeration' ? 'bg-gray-500 text-white-100' : ''}`}>Registeration Event</span>
                    </div>
                 
                    <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Edit" />

                </div>
            </div>
        </>
    )

}

export default EditEvent ;
