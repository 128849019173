import {axiosApiInstance} from '../axios';

export const newAnnouncements = (formData) =>{
    return axiosApiInstance.post(`/admin/announcements` , formData);
}

export const singleAnnouncement = (id) =>{
    return axiosApiInstance.get(`/admin/announcements/${id}`);
}

export const editAnnouncement = (id , formData) =>{
    return axiosApiInstance.put(`/admin/announcements/${id}` , formData);
}

export const removeAnnouncements = (id) =>{
    return axiosApiInstance.delete(`/admin/announcements/${id}`);
}


