import LoadingComponent from "../../common/loading/Loading";

const HeadIndexCard = (props) => {
  let { loading, classes, title, value, icon, iconClasses } = props;
  return (
    <div className={`${classes} bg-white-100 shadow-my-gray rounded-md p-5`}>
      {loading ? (
        <LoadingComponent classes='h-fit'/>
      ) : (
        <>
          <div className="flex">
            <div className="w-1/2 flex flex-col">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className={`${iconClasses} w-12 h-12`}>
                <path strokeLinecap="round" strokeLinejoin="round" d={icon} />
              </svg>
            </div>
            <div className="w-1/2 flex items-end flex-col">
              <span className="text-gray-400 font-medium texto-[1.2rem]">{title}</span>
              <span className="text-gray-900 font-black text-[2.5rem]">{value}</span>
            </div>
          </div>
          <div className="flex items-center border-t border-gray-200 pt-3">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
            <span className="text-[0.9rem] font-light ">Update Now</span>
          </div>
        </>
      )}
    </div>
  );
};

export default HeadIndexCard;
