import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import { Provider } from 'react-redux';
import {HelmetProvider } from 'react-helmet-async';

import {store} from './redux/store' ;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <HelmetProvider>
            <App/>
        </HelmetProvider>
    </Provider>
);


