import React  from 'react';

const CheckGroupPermissions = (props) => {


    const changeCheck = (oldState, item , item2)=>{
        props.change({operation : !oldState , subject : item , action : item2});
    }

    return (
        <div className={`${props.config.containerClasses} flex flex-col justify-center mb-5`} >
            <div className={`w-full rounded-l relative text-slate-500 text-base transition-all duration-500 border-gray-200 dark:border-dark-100 ${props.classes}`}>
                {
                    props.value.map((item , index)=>(
                        <div key={index} className='mb-10'>
                            <div className='flex justify-start items-center mx-[5px] mb-2' >
                                <span className='text-gray-700 font-bold text-[1rem]'>{item.subject}</span>
                            </div>
                            <div className='w-full flex '>
                                {
                                    item.actions.map((action , index2)=>(
                                        <div className={`w-1/4 checkbox-wrapper font-roboto`} key={index2}>
                                            <label className="flex justify-start items-center h-full">
                                                {/* <input className={` ${action.status ?  'checked' : ''}`} type="checkbox" checked={action.status} onChange={(e) => changeCheck(e , item , action)}  value={action.status ? 1 : 0} /> */}
                                                <div className={`w-[15px] h-[15px] border border-gray-400 cursor-pointer rounded-full ${action.status ?  'bg-primary-100' : ''}`}   onClick={(e) => changeCheck(action.status , item , action)}  value={action.status ? 1 : 0}>
                                                    {
                                                        action.status ?
                                                        (
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} className="w-3 h-3 stroke-gray-900">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                            </svg>
                                                        )
                                                        :
                                                        (
                                                            null
                                                        )
                                                    }
                                                </div>
                                                <span className='ml-2 text-gray-700 font-normal text-[0.8rem]'>{action.title}</span>
                                            </label>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    ))

                } 
                    
            </div>
        

        </div>
    
    )
}

export default CheckGroupPermissions;