import {axiosApiInstance} from '../axios';

export const getAuthorsApi = () =>{
    return axiosApiInstance.get(`/admin/blog/authors`);
}

export const newAuthors = (formData) =>{
    return axiosApiInstance.post(`/admin/blog/authors` , formData);
}

export const singleAuthor = (id) =>{
    return axiosApiInstance.get(`/admin/blog/authors/${id}`);
}

export const editAuthor = (id , formData) =>{
    return axiosApiInstance.patch(`/admin/blog/authors/${id}` , formData);
}

export const removeAuthor = (id) =>{
    return axiosApiInstance.delete(`/admin/blog/authors/${id}`);
}


