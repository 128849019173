import {axiosApiInstance} from '../axios';

export const newAdmin = (formData) =>{
    return axiosApiInstance.post('/admin/admins' , formData);
}

export const removeAdmin = (id) =>{
    return axiosApiInstance.delete(`/admin/admins/${id}`);
}

export const editAdmin = (id , formData) =>{
    return axiosApiInstance.patch(`/admin/admins/${id}` , formData);
}


