import {axiosApiInstance} from '../axios';

export const newGroup = (formData) =>{
    return axiosApiInstance.post('/admin/access/groups' , formData);
}

export const removeGroup = (id) =>{
    return axiosApiInstance.delete(`/admin/access/groups/${id}`);
}

export const addPermission = (formData) =>{
    return axiosApiInstance.post(`/admin/access/permissions` , formData);
}


export const removePermission = (formData) =>{
    return axiosApiInstance.delete(`/admin/access/permissions` , {data:formData});
}


