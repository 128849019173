import React, { useState , useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {axiosApiInstance} from '../../../../services/axios';
import Form from '../../../common/forms/Form';
import Loading from '../../../common/loading/Loading';

const NewPost = () => {
  
    const navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState( {
        formItems: [
        {
            inputType: "input-file",
            config: {
                label: 'Image',
                name: "images",
                type: "file",
                t_label : 'attached_file'
            },
            value: "",
            validation: {
                maxSize: 5000000,
                isSelected : true ,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "input",
            config: {
                label: 'time frame',
                name: "tf",
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
    ]
    });

 
    

    const handleSubmitForm = async(formData)=>{
        setLoadingForm(true);

        const {data} = await axiosApiInstance.post(`/admin/excel/indicator` , formData) ;
        if(data.status === 'success'){
            setLoadingForm(false); 
            toast.success('Your new post sended successfull');
            //navigate('/blog')

        }else if(data.status == 'error'){
            setLoadingForm(false);
            toast.error(data.msg);
        }

    }


    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />


            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 dark:text-white-100 text-xl font-extrabold'>New Post</span>
                    </div>

                              
                    <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Apply" file={true}/>

 
                </div>
            </div>
        </>
    )

}

export default NewPost ;
