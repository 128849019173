import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { attachTemplate, deattachTemplate } from "../../../../services/crm/telegramTemplateService";
import { getTemplates } from "../../../../services/crm/telegramTemplateService";
import MultiSelect from "../../input/MultiSelect";

const ModalAttachTelegramTemplate = (props) => {

  
  const [values, setValues] = useState([]);
  const [shouldRefresh , setShouldRefresh] = useState(false);

  const close = async (e) => {
    if(shouldRefresh){
      props.refresh();
    }
    props.close();
  };

  const [options, setOptions] = useState([]);


  useEffect(() => {
    const getAllTemplates = async () => {
      const res = await getTemplates(1, 100);

      if (res.data.status === "success") {
        setOptions(
          res.data.data.map((item) => {
            return {
              id: item._id,
              title: item.name,
            };
          })
        );

        setValues(props?.eventItem?.telegramTemplates);

      } else if (res.data.status === "error") {
        toast.error(res.data.msg);
      }
    };
    getAllTemplates();
  }, []);

  const changeHanlder = async (actionObject) => {
    //console.log(actionObject);
    if (actionObject.action == "add") {
      const { data } = await attachTemplate(props.eventItem._id, actionObject.id);
      if (data.status == "success") {
        setShouldRefresh(true);
        setValues([...values, actionObject.id]);
      }
    }
    if (actionObject.action == "remove") {
      const { data } = await deattachTemplate(props.eventItem._id, actionObject.id);
      if (data.status == "success") {
        setShouldRefresh(true);
        let oldValues = [...values];
        let newValues = oldValues.filter((item) => item !== actionObject.id);
        setValues(newValues);
      }
    }
  };

  return (
    <>
      {props.show ? <div className="w-full h-full z-[4000000] fixed top-0 left-0 right-0 bg-modalBackground-100" onClick={() => close()}></div> : null}
      <div
        className={`w-4/5 h-fit  max-w-[650px] fixed z-[5000000]  bg-white-100 dark:bg-dark-100 rounded-3xl left-0 right-0 top-0 bottom-0 m-auto  ${
          props.show ? "animate-my-fade-down block" : "hidden"
        }`}
      >
        <div className="w-full h-full flex flex-col justify-center items-center p-5">
          <form className="w-full flex flex-col">
            <div className="flex justify-between">
              <h1 className="font-bold text-gray-700 text-[1.2rem]">Attach Telegram Template</h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 cursor-pointer hover:opacity-80"
                onClick={() => close()}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>

            <MultiSelect change={changeHanlder} options={options} value={values} name="Template" />

  

          </form>
        </div>
      </div>
    </>
  );
};

export default ModalAttachTelegramTemplate;
