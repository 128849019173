import moment from 'moment';
moment.locale('fa')
moment.locale('utc')
export const showDate  = (time) =>{
    return  moment(time).fromNow();
}

export const showDateTime  = (time) =>{
    
    if(!time) return '-';

    return  moment(time).format('YYYY/M/D HH:mm');
}

export const showDateTimeUtc = (time)=>{

    if(!time) return '-';

    let dateString = time.split('T')[0];
    let timeString = time.split('T')[1];
    let timeStringArr = timeString.split('.');
    let datestringArr = dateString.split('-');

    return `${datestringArr[0]}/${datestringArr[1]}/${datestringArr[2]} ${timeStringArr[0].slice(0,-3)}`;

}