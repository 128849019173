import React, { useState , useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {axiosApiInstance} from '../../../../services/axios';
import Form from '../../../common/forms/Form';
import Loading from '../../../common/loading/Loading';
import {editComment} from '../../../../services/comments/commentsService'


const EditComment = () => {

    const {id} = useParams();

    const Navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [comment , setComment ] = useState({});
    const [ form , setForm ]= useState();



    useEffect(()=>{

        const getComment = async()=>{
            const res = await axiosApiInstance.get(`/admin/comments/edit/${id}` );


            if(res.data.status === 'success'){
                setComment(res.data.data.comment);
                setForm( {
                    formItems: [
                    {
                        inputType: "textarea",
                        config: {
                            label: 'Comment',
                            name: "comment",
                            containerClasses : 'w-full'
                        },
                        value: res.data.data.comment.comment,
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                ]
                });

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }

            setLoading(false);

        }
        getComment();

    } , []);
        

    const handleSubmitForm = async(formData)=>{
        setLoadingForm(true);

        const {data} = await editComment(id , formData) ;
        if(data.status === 'success'){
            setLoadingForm(false); 
            toast.success('comment edit successfull');
            Navigate('/comments');

        }else if(data.status == 'error'){
            setLoadingForm(false);
            toast.error(data.msg);
        }
    }

    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />


            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 dark:text-white-100 text-xl font-extrabold'>New blog category</span>
                    </div>
                    {
                        loading ?
                        (
                            <Loading/>

                        )
                        :
                        (
                            <div className='w-full dark:bg-dark-100 p-3 mb-2 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                                <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle={'Apply'}/>
                            </div>

                        )

                    }
                   
                </div>
                     
            </div>
        </>
    )

}

export default EditComment ;
