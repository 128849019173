import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

import { newEvent } from '../../../../../services/crm/eventService';
import Form from '../../../../common/forms/Form';

const NewEvent = () => {
  
    const navigate = useNavigate();

    const [loadingForm , setLoadingForm] = useState(false);
    const [type , setType] = useState('plan');
    const [ formPlan ]= useState({formItems: [
        {
            inputType: "input",
            config: {
                label: 'Name',
                name: "name",
                containerClasses : 'w-full'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: 'Prerequisite',
                name: "prerequisite",
                containerClasses : 'w-full',
                options: [
                    {id : 'NONE' , title: 'NONE'},
                ]
            },
            value: '',
            validation: {
                selectRequired: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "myCheckbox",
            config: {
                label: 'Schedule',
                name: "schedule",
                containerClasses : 'w-full' ,
                justShow : true ,
            },
            value: '',
            validation: {
            },
            valid: true,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: 'When',
                name: "when",
                containerClasses : 'w-1/3',
                isdepend : true ,
                isdependfield : 'schedule' ,
                isdependvalue : 'true' ,
                options: [
                    {id : 'AFTER_BUY' , title: 'AFTER_BUY'},
                    {id : 'BEFORE_EXPIRETION' , title: 'BEFORE_EXPIRETION'},
                    {id : 'AFTER_EXPIRETION' , title: 'AFTER_EXPIRETION'},
                ]
            },
            value: '',
            validation: {
                selectRequired: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        }, 
        {
            inputType: "input",
            config: {
                label: 'amount',
                name: "n",
                containerClasses : 'w-1/3 px-3' ,
                isdepend : true ,
                isdependfield : 'schedule' ,
                isdependvalue : 'true' ,
            },
            value: '',
            validation: {
                required: true,
                isDigit : true,
            },
            valid: true,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: "Unit" ,
                name: "unit",
                containerClasses : 'w-1/3',
                options: [
                    {id : 'd' , title: 'd'},
                ],
                isdepend : true ,
                isdependfield : 'schedule' ,
                isdependvalue : 'true' ,
            },
            value: '',
            validation: {
                selectRequired: true,
            },
            valid: true,
            errorMsg: "",
            used: false
        },
    ]
    });

    const [ formRegistration ]= useState({formItems: [
        {
            inputType: "input",
            config: {
                label: 'Name',
                name: "name",
                containerClasses : 'w-full'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: 'Prerequisite',
                name: "prerequisite",
                containerClasses : 'w-full',
                options: [
                    {id : 'NONE' , title: 'NONE'},
                    {id : 'IF_DONT_BUY_PLAN' , title: 'IF_DONT_BUY_PLAN'},
                ]
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "myCheckbox",
            config: {
                label: 'Schedule',
                name: "schedule",
                containerClasses : 'w-full' ,
                justShow : true ,
            },
            value: '',
            validation: {
            },
            valid: true,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: 'When',
                name: "when",
                containerClasses : 'w-1/3',
                isdepend : true ,
                isdependfield : 'schedule' ,
                isdependvalue : 'true' ,
                options: [
                    {id : 'AFTER_REGISTER' , title: 'AFTER_REGISTER'},
                ]
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        }, 
        {
            inputType: "input",
            config: {
                label: 'amount',
                name: "n",
                containerClasses : 'w-1/3 px-3' ,
                isdepend : true ,
                isdependfield : 'schedule' ,
                isdependvalue : 'true' ,
            },
            value: '',
            validation: {
                required: true,
                isDigit : true,
            },
            valid: true,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: "Unit" ,
                name: "unit",
                containerClasses : 'w-1/3',
                options: [
                    {id : 'd' , title: 'd'},
                ],
                isdepend : true ,
                isdependfield : 'schedule' ,
                isdependvalue : 'true' ,
            },
            value: '',
            validation: {
                required: true,
            },
            valid: true,
            errorMsg: "",
            used: false
        },
    ]
    });


    const [ formForgetPassword ]= useState({formItems: [
        {
            inputType: "input",
            config: {
                label: 'Name',
                name: "name",
                containerClasses : 'w-full'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: 'Prerequisite',
                name: "prerequisite",
                containerClasses : 'w-full',
                options: [
                    {id : 'NONE' , title: 'NONE'},
                ]
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
    ]
    });

    const [ formEmailVerification ]= useState({formItems: [
        {
            inputType: "input",
            config: {
                label: 'Name',
                name: "name",
                containerClasses : 'w-full'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: 'Prerequisite',
                name: "prerequisite",
                containerClasses : 'w-full',
                options: [
                    {id : 'NONE' , title: 'NONE'},
                ]
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
    ]
    });

    const [ formGeneral ]= useState({formItems: [
        {
            inputType: "input",
            config: {
                label: 'Name',
                name: "name",
                containerClasses : 'w-full'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: 'Prerequisite',
                name: "prerequisite",
                containerClasses : 'w-full',
                options: [
                    {id : 'NONE' , title: 'NONE'},
                    {id : 'IF_DONT_BUY_PLAN' , title: 'IF_DONT_BUY_PLAN'},
                ]
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "myCheckbox",
            config: {
                label: 'Schedule',
                name: "schedule",
                containerClasses : 'w-full' ,
                justShow : true ,
            },
            value: '',
            validation: {
            },
            valid: true,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: 'When',
                name: "when",
                containerClasses : 'w-1/3',
                isdepend : true ,
                isdependfield : 'schedule' ,
                isdependvalue : 'true' ,
                options: [
                    {id : 'AFTER_REGISTER' , title: 'AFTER_REGISTER'},
                ]
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        }, 
        {
            inputType: "input",
            config: {
                label: 'amount',
                name: "n",
                containerClasses : 'w-1/3 px-3' ,
                isdepend : true ,
                isdependfield : 'schedule' ,
                isdependvalue : 'true' ,
            },
            value: '',
            validation: {
                required: true,
                isDigit : true,
            },
            valid: true,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: "Unit" ,
                name: "unit",
                containerClasses : 'w-1/3',
                options: [
                    {id : 'd' , title: 'd'},
                ],
                isdepend : true ,
                isdependfield : 'schedule' ,
                isdependvalue : 'true' ,
            },
            value: '',
            validation: {
                required: true,
            },
            valid: true,
            errorMsg: "",
            used: false
        },
    ]
    });

    const [ formPortfolioSchedule ]= useState({formItems: [
        {
            inputType: "input",
            config: {
                label: 'Name',
                name: "name",
                containerClasses : 'w-full'
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
        {
            inputType: "select",
            config: {
                label: 'Prerequisite',
                name: "prerequisite",
                containerClasses : 'w-full',
                options: [
                    {id : 'NONE' , title: 'NONE'},
                ]
            },
            value: '',
            validation: {
                required: true,
            },
            valid: false,
            errorMsg: "",
            used: false
        },
    ]
    });


    const changeType = (typeTitle)=>{
        if(type !== typeTitle){
            setType(typeTitle);
        }
    }
    
    const handleSubmitForm = async(formData)=>{

        try{

            let newFormData;

            newFormData = {
            name : formData.name ,
            prerequisite : formData.prerequisite ,
            schedule: formData.when == '' && formData.n == '' && formData.unit == ''  ? null : {
                when: formData.when,
                n: Number(formData.n),
                unit: formData.unit 
                }
            }
            
            setLoadingForm(true);

            const {data} = await newEvent(newFormData , type);
            if(data.status === 'success'){
                setLoadingForm(false); 
                toast.success('New event created successfull');
                navigate('/crm/events')
    
            }else if(data.status == 'error'){
                setLoadingForm(false);
                toast.error(data.msg);
            }
    
        }catch(err){
            setLoadingForm(false);
            toast.error(err.response.data.message);
        }
    }


    const getForm = (type)=>{
        if(type === 'plan'){
            return formPlan;
        }else if(type === 'registeration'){
            return formRegistration;
        }else if(type === 'forgetPassword'){
            return formForgetPassword;
        }else if(type === 'emailVerification'){
            return formEmailVerification;
        }else if(type === 'general'){
            return formGeneral;
        }else if(type === 'portfolioSchedule'){
            return formPortfolioSchedule;
        }

    }


    return (
        <>
            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>New Event</span>
                    </div>
                    
                    <div className='w-full text-center mb-10'>
                        <span className={`p-3 rounded-lg cursor-pointer font-medium ${type == 'plan' ? 'bg-gray-500 text-white-100': ''}`} onClick={()=>changeType('plan')}>Plan Event</span>
                        <span className={`p-3 rounded-lg cursor-pointer font-medium ${type == 'registeration' ? 'bg-gray-500 text-white-100' : ''}`} onClick={()=>changeType('registeration')}>Registeration Event</span>
                        <span className={`p-3 rounded-lg cursor-pointer font-medium ${type == 'forgetPassword' ? 'bg-gray-500 text-white-100' : ''}`} onClick={()=>changeType('forgetPassword')}>Forget Password Event</span>
                        <span className={`p-3 rounded-lg cursor-pointer font-medium ${type == 'emailVerification' ? 'bg-gray-500 text-white-100' : ''}`} onClick={()=>changeType('emailVerification')}>Email Verification Event</span>
                        <span className={`p-3 rounded-lg cursor-pointer font-medium ${type == 'general' ? 'bg-gray-500 text-white-100' : ''}`} onClick={()=>changeType('general')}>General Event</span>
                        <span className={`p-3 rounded-lg cursor-pointer font-medium ${type == 'portfolioSchedule' ? 'bg-gray-500 text-white-100' : ''}`} onClick={()=>changeType('portfolioSchedule')}>Portfolio Schedule Event</span>

                    </div>
                 
                    <Form initForm={getForm(type)} submit={handleSubmitForm} loading={loadingForm} submitTitle="Send" />

                </div>
            </div>
        </>
    )

}

export default NewEvent ;
