import React, { useState , useEffect} from 'react';

import Form from '../../forms/Form';
import {axiosApiInstance} from '../../../../services/axios';

import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ModalEditUser = (props) => {

    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState({});
        

    useEffect(()=>{

        setForm( {
            formItems: [
                {
                    inputType: "input",
                    config: {
                        label: 'Name',
                        name: "name",
                        containerClasses : 'w-full',
                    },
                    value: props.user.name,
                    validation: {
                    },
                    valid: false,
                    errorMsg: "",
                    used: false
                },
                {
                    inputType: "input",
                    config: {
                        label: 'Gift Referral Charge',
                        name: "gift",
                        containerClasses : 'w-full',
                    },
                    value: props.user.giftCharge,
                    validation: {
                        isDigit: true,
                    },
                    valid: false,
                    errorMsg: "",
                    used: false
                },
                {
                    inputType: "input",
                    config: {
                        label: 'Wallet',
                        name: "balance",
                        containerClasses : 'w-full',
                    },
                    value: props.user.balance,
                    validation: {
                        isWalletNumber: true,
                    },
                    valid: false,
                    errorMsg: "",
                    used: false
                }
            ]
        });

    } , [props.show]);


    const handleSubmitForm = async(formData)=>{

        if(formData.name == ''){
            delete formData['name'];
        }

        setLoadingForm(true);

        const {data} = await axiosApiInstance.put(`/admin/users/edit/${props.user.userId}` , formData ) ;

        if(data.status === 'success'){
            setLoadingForm(false); 
            toast.success('User edited successfull');

            close();
            props.refresh(formData);

        }else if(data.status == 'error'){
            setLoadingForm(false);
            toast.error(data.msg);
        }

    }


    const close = async (e) => {
        props.close();
    }



    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />

            {props.show ? (<div className='w-full h-full z-[4000000] fixed top-0 left-0 right-0 bg-modalBackground-100' onClick={() => close()}></div>) : null}
            <div className={`w-4/5 h-fit  max-w-[650px] fixed z-[5000000] bg-white-100 dark:bg-dark-100 rounded-3xl left-0 right-0 top-0 bottom-0 m-auto  ${props.show ? 'animate-zoom-in block' : 'hidden'}`}>
                <div className='w-full h-full flex flex-col justify-center items-center p-5 overflow-y-auto'>
                    <h1 className="font-bold text-gray-700 text-[1.2rem]">
                        Edit User
                    </h1>

                    <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle={'Apply'} file={false}/>

                </div>
            </div>
        </>
    )
}

export default ModalEditUser;