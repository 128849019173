import React, { useState , useEffect} from 'react';
import{ useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import ModalRemoveUser from '../../common/modals/modalRemoveUser/ModalRemoveUser';
import FormSearch from '../../common/forms/FormSearch';
import {axiosApiInstance} from '../../../services/axios';
import Pagination from '../../common/pagination/Pagination';
import Loading from '../../common/loading/Loading';
import ModalLoading from '../../common/modals/modalLoading/ModalLoading';
import { showDateTime , showDateTimeUtc} from '../../../utils/showDate';
import {checkPermissions} from '../../../utils/checkPermissions';
import { selectUser } from '../../../redux/slices/auth';

const Users = () => {

    const userInfo = useSelector(selectUser);

    const navigate = useNavigate();

    const [showModalRemoveUser, setShowModalRemoveUser] = useState(false);
    const[loading , setLoading] = useState(true);
    const [users, setUsers] = useState();
    const [page, setPage] = useState();
    const [pages, setPages] = useState();
    const [q, setQ] = useState('');
    const [currentUser , setCurrentUser] = useState({});
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form ]= useState({
        formItems: [
            {
                inputType: "input",
                config: {
                    name: "key",
                    placeholder : "Search" ,
                    containerClasses : 'w-full'
                },
                value: '',
                validation: {
                },
                valid: false,
                errorMsg: "",
                used: false
            },
        ]
    });

    const stateModalRemoveUser = (state , item) => {
        setShowModalRemoveUser(state);
        setCurrentUser(item);
    }


    useEffect(()=>{
        const getUsers = async()=>{
            const res = await axiosApiInstance.get('/admin/users' );
            setLoading(false);
            if(res.data.status === 'success'){
                setUsers(res.data.data.users);
                setPage(res.data.data.users.page);
                setPages(res.data.data.users.pages);

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }
        }
        getUsers();
    } , []);



    const goPage = async (p) => {
        setLoading(true);
        const res = await axiosApiInstance.get(`/admin/users?page=${p}${q !== '' ? `&q=${q}` : ''}`);
        setLoading(false);
        if (res.data.status === 'success') {
            setUsers(res.data.data.users);
            setPage(res.data.data.users.page);
            setPages(res.data.data.users.pages);
        } else {
            toast.error(res.data.msg);
        }
    }

    const refresh = () => {
        goPage(page);
    }


    const handleFormSearch = async(q)=>{
        setQ(q);
        setLoadingForm(true);
        
        const res = await axiosApiInstance.get(`/admin/users?q=${q}`);
        setLoadingForm(false);
        if (res.data.status === 'success') {
            setUsers(res.data.data.users);
            setPage(res.data.data.users.page);
            setPages(res.data.data.users.pages);
        } else {
            toast.error(res.data.msg);
        }

    }


    const changeFilterHandler=()=>{
        console.log('filter change')

    }

    return (
        <>

            <ModalLoading show={loadingForm}/>
            <ModalRemoveUser show={showModalRemoveUser} close={()=>setShowModalRemoveUser(false)} refresh={() => refresh()} user={currentUser}/>

       
            <div className="container mx-auto p-3 ">

                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>Users</span>
                    </div>


                    {
                        loading ? 
                        (
                            <Loading />

                        )
                        :
                        (
                            <div className='w-full flex flex-col justify-center items-start'>
                                <div className='w-full flex items-center justify-end text-base font-semibold mb-4 text-slate-600 dark:text-white-100'>
                                    <FormSearch classesForm='w-[400px]' initForm={form} submit={handleFormSearch}/>                  
                                </div>
                                {/* <div className='w-full'>
                                    <div className="flex justify-start">
                                        <span className="text-[1rem] font-light mr-1 text-gray-700 block">Filter Users</span>
                                        <select defaultValue={true} onChange={changeFilterHandler} className={`w-fit h-fit border rounded-md px-5 py-2 cursor-pointer outline-none text-slate-500 text-[0.8rem] transition-all duration-300 focus:border-primary-100 border-gray-200`}>
                                        <option className='cursor-pointer' value="1W">All Users</option>
                                        <option className='cursor-pointer' value="1M">Just ...</option>
                                        </select>
                                    </div>  
                                </div> */}
                                <div className='w-full text-[0.9rem] text-center border-collapse'>
                                    {users.docs.length > 0 ?
                                        (
                                            <>
                                                <table className="w-full table">
                                                    <thead className='sticky top-[60px] bg-white-100'>
                                                        <tr>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Avatar</th>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Name</th>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none  align-middle h-20'>Phone Number</th>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Email</th>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Gift Charge</th>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Verify Date</th>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Verify</th>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='w-full'>
                                                        { users.docs.map ((user , index) =>(
                                                            <tr key={index} className="hover:bg-stone-100 duration-200 rounded-lg text-center p-10">
                                                                <td className='align-middle	mx-auto'>
                                                                    <img className='rounded-full w-[64px] h-[64px] mx-auto' src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${user.avatar.thumb}`} width="64" height="64" alt='user' />
                                                                </td>
                                                                <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>{user.name === "" ? "-" : user.name }</td>
                                                                <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>{user.phone === "" ? "-" : user.phone }</td>
                                                                <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>{user.email === "" ? "-" : user.email }</td>
                                                                <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>{user.giftCharge  ? user.giftCharge : 0 }</td>
                                                                <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>{ user?.verifiedAt ? 
                                                                `ir:${showDateTime(user.verifiedAt)} - utc:${showDateTimeUtc(user?.verifiedAt)}`
                                                                : 
                                                                '-'
                                                                }
                                                                </td>
                                                                <td className='font-normal text-[0.8rem] text-gray-700 border-none align-middle h-20'>
                                                                    {
                                                                        user.active ? 
                                                                        (
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-6 h-6 mx-auto stroke-green-500">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                                        </svg>
                                                                        )
                                                                        :
                                                                        (
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-6 h-6 mx-auto stroke-red-500">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                                        </svg>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td className='flex items-center gap-x-2 justify-center font-semibold text-[1rem] text-gray-700 border-none align-middle h-20'>
                                                                    {
                                                                        checkPermissions(userInfo.type , userInfo.permissions , 'User-Modify')?
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer hover:stroke-primary-100" onClick={()=>navigate(`/users/${user.id}`)}>
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                                                            </svg>

                                                                        )
                                                                        :
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                            </svg>
                                                                        )
                                                                    }

                                                                    {
                                                                        checkPermissions(userInfo.type , userInfo.permissions , 'User-Delete')?
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer hover:stroke-primary-100" onClick={()=>stateModalRemoveUser(true , user)}>
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                            </svg>

                                                                        )
                                                                        :
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                            </svg>
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                            )
                                                        )
                                                        }
                                                    </tbody>
                                                </table>
                                                <Pagination classes='' pages={pages} current={page} action={goPage} />
                                            </>
                                        )
                                        :
                                        (
                                            <p className="caption-no-result">
                                                No user found
                                            </p>
                                        )
                                    }

                                </div>
                            </div> 
                        )
                    }

                </div>

            </div>
        </>
    )

}

export default Users ;
