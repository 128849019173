import React, { useState , useEffect} from 'react';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ModalRemoveUser from '../../../common/modals/modalRemoveUser/ModalRemoveUser';
import {axiosApiInstance} from '../../../../services/axios';
import Button from "../../../common/button/Button" ;
import Pagination from '../../../common/pagination/Pagination';
import Loading from '../../../common/loading/Loading';
import ModalLoading from '../../../common/modals/modalLoading/ModalLoading';
import { showDateTime } from '../../../../utils/showDate';

const Plans = () => {

    const [showModalRemoveUser, setShowModalRemoveUser] = useState(false);
    const[loading , setLoading] = useState(true);
    const [plans, setPlans] = useState();
    const [page, setPage] = useState();
    const [pages, setPages] = useState();
    const [currentUser , setCurrentUser] = useState({});
    const [loadingForm , setLoadingForm] = useState(false);
 

    const stateModalRemoveUser = (state , item) => {
        setShowModalRemoveUser(state);
        setCurrentUser(item);
    }

    useEffect(()=>{
        const getPlans = async()=>{
            const res = await axiosApiInstance.get('/admin/pricing/plans' );
            setLoading(false);
            if(res.data.status === 'success'){
                setPlans(res.data.data.plans);
                setPage(res.data.data.plans.page);
                setPages(res.data.data.plans.pages);

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }
        }
        getPlans();
    } , []);



    const goPage = async (p) => {
        setLoading(true);
        const res = await axiosApiInstance.get(`/admin/pricing/plans?page=${p}`);
        setLoading(false);
        if (res.data.status === 'success') {
            setPlans(res.data.data.plans);
            setPage(res.data.data.plans.page);
            setPages(res.data.data.plans.pages);
        } else {
            toast.error(res.data.msg);
        }
    }

    const refresh = () => {
        goPage(page);
    }


    return (
        <>

            <ModalLoading show={loadingForm}/>
            <ModalRemoveUser show={showModalRemoveUser} close={()=>setShowModalRemoveUser(false)} refresh={() => refresh()} user={currentUser}/>

            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />


            <div className="container mx-auto p-3 ">

                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my'>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700  text-xl font-extrabold'>Plans</span>
                    </div>


                    {
                        loading ? 
                        (
                            <Loading />

                        )
                        :
                        (
                            <div className='w-full flex flex-col justify-center items-start'>
                                <div className='w-full flex items-center justify-between text-base font-semibold mb-4 text-slate-600'>
                                    List of all Plans
                                </div>
                                <div className='w-full text-[0.9rem] text-center border-collapse overflow-x-auto'>
                                    {plans.docs.length > 0 ?
                                        (
                                            <>
                                                <table className="w-full table">
                                                    <thead>
                                                        <tr>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Title</th>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Price</th>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Time</th>
                                                            <th className='min-w-[120px] text-gray-800 text-[1rem] font-bold border-none align-middle h-20'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { plans.docs.map ((plan , index) =>(
                                                            <tr key={index} className="hover:bg-stone-100">
                                                                <td className='font-semibold text-[1rem] text-gray-700 border-none align-middle h-20'>{plan.title}</td>
                                                                <td className='font-semibold text-[1rem] text-gray-700 border-none align-middle h-20'>{plan.price}</td>
                                                                <td className='font-semibold text-[1rem] text-gray-700 border-none align-middle h-20'>{plan.time} Days</td>
                                                                <td className='flex items-center justify-center font-semibold text-[1rem] text-gray-700 border-none align-middle h-20'>
                                                                    <Button classes="px-2 py-1 mr-2 bg-yellow-400" >
                                                                        Edit
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                            )
                                                        )
                                                        }

                                                    </tbody>
                                                </table>
                                                <Pagination pages={pages} current={page} action={goPage} />
                                            </>

                                        )
                                        :
                                        (

                                            <p className="caption-no-result">
                                                No plan found
                                            </p>

                                        )
                                    }

                                </div>
                            </div> 
                        )
                    }

                </div>

            </div>
        </>
    )

}

export default Plans ;
