import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom' ;
import { useSWRConfig } from "swr";

import {axiosApiInstance} from '../../../services/axios';
import { updateUser } from '../../../redux/slices/auth';


function Logout() {

    const { cache } = useSWRConfig();


  const dispatch = useDispatch();
  const navigate = useNavigate();
  
    useEffect(()=>{

        const asyncFn = async () => {
            const { data } = await axiosApiInstance.post('/admin/auth/logout',{});
            if(data.status == 'success'){
                dispatch(updateUser({
                    user : {} ,
                    loading : false,
                    error : '',
                }));
                cache.clear();
                navigate('/login');
            }
        };
        asyncFn();
    });

}

export default Logout;
