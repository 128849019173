import {axiosApiInstance} from '../axiosCRM';

export const getPopups = (p) =>{
    return axiosApiInstance.get(`/api/v1/popups`);
}

export const newPopup = (formData) =>{
    return axiosApiInstance.post(`/api/v1/popups`, formData );
    
}

export const singlePopup = (id) =>{
    return axiosApiInstance.get(`/api/v1/popups/${id}`);
}

export const editPopup = (id , formData) =>{
    return axiosApiInstance.patch(`/api/v1/popups/${id}` , formData);
}

export const removePopup = (id) =>{
    return axiosApiInstance.delete(`/api/v1/popups/${id}`);
}

