import React, { useState , useEffect} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {axiosApiInstance} from '../../../services/axios';
import ModalRemoveSubscribe from '../../common/modals/modalRemoveSubscribe/ModalRemoveSubscribe';
import Button from "../../common/button/Button" ;
import {showDateTime} from '../../../utils/showDate';
import Pagination from '../../common/pagination/Pagination';
import Loading from '../../common/loading/Loading';
import {checkPermissions} from './../../../utils/checkPermissions';
import { selectUser } from '../../../redux/slices/auth';


const Subscribes = () => {

    const user = useSelector(selectUser);

    const navigate = useNavigate();

    const [showModalRemoveSubscribe , setShowModalRemoveSubscribe] = useState(false);
    const[loading , setLoading] = useState(true);
    const [subscribes, setSubscribes] = useState();
    const [fiterContacts , setFilterContacts] = useState();
    const [page, setPage] = useState();
    const [pages, setPages] = useState();
    const [currentSubscribe , setCurrentSubscribe] = useState({});


    useEffect(()=>{

        const getUsers = async()=>{
            const res = await axiosApiInstance.get('/admin/subscribes' );
            setLoading(false);
            if(res.data.status === 'success'){
                setSubscribes(res.data.data.subscribes);
                setPage(res.data.data.contacts.page);
                setPages(res.data.data.contacts.pages);

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }

        }

        getUsers();

    } , []);


    const stateModalRemove = (state , item) => {
        setShowModalRemoveSubscribe(state);
        setCurrentSubscribe(item);
    }


    const goPage = async (p) => {
        setLoading(true);
        const res = await axiosApiInstance.get(`/admin/subscribes?page=${p}`);
        setLoading(false);
        if (res.data.status === 'success') {
            setSubscribes(res.data.data.subscribes);
            setPage(res.data.data.contacts.page);
            setPages(res.data.data.contacts.pages);
        } else {
            toast.error(res.data.msg);
        }
    }


    const refresh = () => {
        goPage(page);
    }

    return (
        <>
            <ModalRemoveSubscribe show={showModalRemoveSubscribe} close={()=>setShowModalRemoveSubscribe(false)} refresh={() => refresh()} item={currentSubscribe}/>

            <div className="container mx-auto p-3 ">

                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 dark:text-white-100 text-xl font-extrabold'>Subscribes</span>
                    </div>
                    {
                        loading ? 
                        (
                            <Loading/>
                        )
                        :
                        (
                            <div className='w-full flex flex-col justify-center items-start'>
                                <div className='text-base font-semibold	 mb-4 text-slate-600 dark:text-white-100'>
                                    List of all subscribes
                                </div>
                                <div className='w-full text-[0.9rem] font-normal text-center border-collapse overflow-x-auto'>
                                    {subscribes.docs.length > 0 ?
                                        (
                                            <>
                                                <table className="w-full table">
                                                    <thead>
                                                        <tr>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none align-middle h-20'>Email</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none align-middle h-20'>Created At</th>
                                                            <th className='min-w-[120px] font-bold text-gray-800 text-[1rem] border-none align-middle h-20'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { subscribes.docs.map ((item , index) =>(
                                                            <tr key={index} className="hover:bg-stone-100">
                                                                <td className='font-normal text-gray-700 text-[1rem] font-semibold border-none align-middle h-20'>{item.email}</td>
                                                                <td className='font-normal text-gray-700 text-[1rem] font-semibold border-none align-middle h-20'>{showDateTime(item.createdAt)}</td>
                                                                <td className='flex items-center justify-center font-normal text-gray-700 text-[1rem] font-semibold border-none align-middle h-20'>
                                                                    {/* <Button classes="px-2 py-1 mr-2 bg-yellow-400" click={()=>navigate(`/subscribes/${item.id}`)}>
                                                                        Show detail
                                                                    </Button> */}
                                                                    {
                                                                        checkPermissions(user.type , user.permissions , 'Subscribe-Delete')?
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer hover:stroke-primary-100" onClick={()=>stateModalRemove(true , item)}>
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                                            </svg>

                                                                        )
                                                                        :
                                                                        (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-54 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                                                                            </svg>
                                                                        )
                                                                    } 
                                                                </td>
                                                            </tr>
                                                            )
                                                        )
                                                        }
                                                    </tbody>
                                                </table>
                                                <Pagination pages={pages} current={page} action={goPage} />
                                            </>
                                        )
                                        :
                                        (
                                            <p className="caption-no-result">
                                                No items found
                                            </p>
                                        )
                                    }
                                </div>
                            </div> 
                        )
                    }
                </div>

            </div>
        </>
    )

}

export default Subscribes ;
