import React, { useState , useEffect} from 'react';
import {useNavigate , useParams} from 'react-router-dom';
import { toast } from 'react-toastify';

import { singlePopup , editPopup } from '../../../../../services/crm/popupsService';
import Form from '../../../../common/forms/Form';

const EditPopup = () => {
    const {id} = useParams();
  
    const navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [imgEdit , setImgEdit] = useState('');
    const [ form , setForm]= useState({});


    useEffect(()=>{
        const getEvent = async()=>{

            const res = await singlePopup(id);

            if(res.data.status === 'success'){
                setImgEdit(res.data.data.image);
                setForm(
                    {formItems: [
                        {
                            inputType: "myCheckbox",
                            config: {
                                label: 'Active',
                                name: "active",
                                containerClasses : 'w-full' ,
                            },
                            value: res.data.data.active ? 'true' : 'false',
                            validation: {
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "input",
                            config: {
                                label: 'Name',
                                name: "name",
                                containerClasses : 'w-full' ,
                            },
                            value: res.data.data.name,
                            validation: {
                                required: true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "input-file",
                            config: {
                                label: 'Image',
                                name: "image",
                                type: "file",
                                t_label : 'attached_file',
                                containerClasses : 'w-1/2' ,
                            },
                            value: "",
                            validation: {
                                maxSize: 5000000,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                
                
                        {
                            inputType: "input",
                            config: {
                                label: 'Link',
                                name: "link",
                                containerClasses : 'w-full' ,
                            },
                            value: res.data.data.link,
                            validation: {
                                required: true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "select",
                            config: {
                                label: 'Location',
                                name: "location",
                                containerClasses : 'w-full',
                                options: [
                                    {id : 'INDEX' , title: 'Index Page'},
                                    {id : 'DASHBOARD' , title: 'Dashboard Page'},
                                ] ,
                            },
                            value: res.data.data.location,
                            validation: {
                                selectRequired: true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                    ]
                    }
                );

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }
            setLoading(false);
        }
        getEvent();
    } , []);
    


    const handleSubmitForm = async(formData)=>{

        console.log(formData)

        try{

            setLoadingForm(true);

            const {data} = await editPopup(id , formData);
            if(data.status === 'success'){
                setLoadingForm(false); 
                toast.success('Popup Edited Successfull');
                navigate('/crm/popups');
    
            }else if(data.status == 'error'){
                setLoadingForm(false);
                toast.error(data.msg);
            }
    
        }catch(err){
            setLoadingForm(false);
            toast.error(err.response.data.message);
        }
    }


    return (
        <>
            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>Edit Popup</span>
                    </div>

                    <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Edit" imgEdit={imgEdit} file={true} isCRM={true}/>

                </div>
            </div>
        </>
    )

}

export default EditPopup ;
