import React, { useState } from 'react';

import { removeAdmin } from '../../../../services/admins/adminService';
import ButtonSimple from '../../button/ButtonSimple';
import { toast } from 'react-toastify';

const ModalRemoveAdmin = (props) => {

    const [loading, setLoading] = useState(false);
    const [allowSend, setAllowSend] = useState(true);


    const close = async (e) => {
        props.close();
    }

    const removeHandler = async() => {
        if (allowSend) {
            setAllowSend(false);
            try {

                // show loading
                setLoading(true);
                const { data } = await removeAdmin(props.user._id);

                if (data.status == 'success') {
                    toast.success(data.msg);
                    // clear inputs and reset form and close
                    close();
                    props.refresh();

                } else {
                    toast.error('somting wrong');

                }
                // hide loading
                setLoading(false);
                setAllowSend(true);

            } catch (err) {
                console.log(err);
                toast.error('somting wrong');
                // hide loading
                setLoading(false);
                setAllowSend(true);
            }
        }

    }

    return (
        <>
    
            {props.show ? (<div className='w-full h-full z-[4000000] fixed top-0 left-0 right-0 bg-modalBackground-100' onClick={() => close()}></div>) : null}
            <div className={`w-4/5 h-fit  max-w-[650px] fixed z-[5000000] bg-white-100 dark:bg-dark-100 rounded-3xl left-0 right-0 top-0 bottom-0 m-auto  ${props.show ? 'animate-my-fade-down block' : 'hidden'}`}>
                <div className='w-full h-full flex flex-col justify-center items-center p-5 overflow-y-auto'>
                        <form className="w-full flex flex-col">
                            <div className='flex justify-between'>
                                <h1 className="font-bold text-gray-700 text-[1.2rem]">
                                    Remove Admin
                                </h1>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer hover:opacity-80" onClick={() => close()}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <span className="txtConfirm my-5">
                                Are you sure to delete the {props.show ? props.user.name : ''} -  {props.show ? props.user.phone : ''} - {props.show ? props.user.email : ''}?
                            </span>
                            <div className="w-full flex justify-center mt-2">
                                <ButtonSimple loading={loading} classes='py-1 px-2 bg-primary-100 rounded-md' click={removeHandler}>
                                    Ok
                                </ButtonSimple>
                                <ButtonSimple classes='py-1 px-2 ml-1 bg-primary-100 rounded-md' click={() => close()}>
                                    Cancel
                                </ButtonSimple>
                            </div>
                        </form>
                </div>
            </div>
        </>
    )
}

export default ModalRemoveAdmin;