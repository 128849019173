import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { newTemplates } from "../../../../../services/crm/emailTemplateService";
import Form from "../../../../common/forms/Form";

const NewEmailTemplate = () => {
  const navigate = useNavigate();

  const [loadingForm, setLoadingForm] = useState(false);
  const [form, setForm] = useState({
    formItems: [
      {
        inputType: "input",
        config: {
          label: "Name",
          name: "name",
          containerClasses: "w-full",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        errorMsg: "",
        used: false,
      },
      {
        inputType: "input",
        config: {
          label: "Subject",
          name: "subject",
          containerClasses: "w-full",
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        errorMsg: "",
        used: false,
      },
      {
        inputType: "textareaWithUpload",
        config: {
          label: "HTML Template",
          name: "html",
          rows: "50",
          containerClasses: "w-full",
          bucket: 'email-template' ,
          templateTestMode : 'email'
        },
        value: "",
        validation: {
          required: true,
        },
        valid: false,
        errorMsg: "",
        used: false,
      },
    ],
  });

  const handleSubmitForm = async (formData) => {
    try {
      setLoadingForm(true);

      const { data } = await newTemplates(formData);
      if (data.status === "success") {
        setLoadingForm(false);
        toast.success("New template created successfull");
        navigate("/crm/templates/email");
      } else if (data.status == "error") {
        setLoadingForm(false);
        toast.error(data.msg);
      }
    } catch (err) {
      setLoadingForm(false);
      toast.error(err.response.data.msg);
    }
  };

  return (
    <>
      <div className="container mx-auto p-3 ">
        <div className="bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none ">
          <div className="w-full flex justify-start items-center mb-5">
            <svg className="w-2 h-2 fill-gray-700 mr-2" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" />
            </svg>
            <span className="text-gray-700 text-xl font-extrabold">New Email Template</span>
          </div>

          <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Apply" />
        </div>
      </div>
    </>
  );
};

export default NewEmailTemplate;