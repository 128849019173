import {useState , useRef , useEffect} from 'react';
import { inputChange, validationByClick , emptyField} from '../../../utils/formValidation';
import Input from '../input/Input';
import Button from '../button/Button';

const FormEditTelegramTemplate = ({initForm , submitTitle , loading , submit , file , template})=>{

    const [form, setForm] = useState(initForm);

    useEffect(()=>{
        setForm(initForm);
    } , [initForm])

    const formRef = useRef();

    const resetForm = () => {
        setForm(initForm);
    }

    const inputChangeHandler = (e, element) => {
        setForm(inputChange(e, element, form.formItems));
    }

    const emptyFieldHandler = (element) => {
        setForm(emptyField(element , form.formItems));
    }
        

    const submitHandler = async (e) => {
        e.preventDefault();

        if (!loading) {


            let updatedForm = [...form.formItems];
            setForm(validationByClick(form.formItems));

            let allow = true;
            updatedForm.map(item => {
                if (!item.valid) {
                    allow = false;
                    return
                }
            });


            if (allow) {


                    try {

                        if(file){
                            let formData = new FormData(formRef.current);
                            submit(formData);
                        }else{
                            const newForm = {};
                            for (let item in form.formItems) {
                                newForm[form.formItems[item].config.name] = form.formItems[item].value
                            }
                            submit(newForm);
                        }

                              
                        // const { data } = await replyTicket(router.query.slug , formData);

                        // const status = data.status;

                        // if (status == "success") {
                        //     toast.success(t.new_ticket_successfull);
                        //     let newTicketChilds = [data.ticket , ...ticketChilds] ;
                        //     setTicketChilds(newTicketChilds);
                        //     setForm1(initForm1);
                        // } else if (status == "error") {
                        //     toast.error(t.something_was_wrong);
                        // }
                    
                        //setAllowSend(true);

                    } catch (err) {
                        console.log(err)
                        //showMsg('error', t.something_was_wrong);
                    }   
            }
        }
    }

    return(
        <form onSubmit={(e) => submitHandler(e)} ref={formRef} className={`w-full flex flex-wrap`} >
            {
                form?.formItems?.map((input, index) => {
                    
                    if(input.inputType == 'input-file'){
                    return(
                        <>
                            <Input
                                key={index}
                                inputElement={input.inputType}
                                config={input.config}
                                value={input.value}
                                size={input.size}
                                file={input.file}
                                filename={input.filename}
                                emptyField = {()=>emptyFieldHandler(index)} 
                                change={(e) => inputChangeHandler(e, index)}
                                valid={input.valid}
                                errorMsg={input.errorMsg}
                                used={input.used} />
                                {
                                    template.image &&
                                    <img  src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${template.image}`}/>
                                    
                                }
                        </> 
                    )

                    }else{
                        return(
                            <Input
                            key={index}
                            inputElement={input.inputType}
                            config={input.config}
                            value={input.value}
                            emptyField = {()=>emptyFieldHandler(index)} 
                            change={(e) => inputChangeHandler(e, index)}
                            valid={input.valid}
                            errorMsg={input.errorMsg}
                            used={input.used} />
                        )

                    }
                  
                }
                )
            }
        
            <div className='w-full flex flex-wrap flex-col items-end mb-[16px]'>
                <Button classes="px-4 py-2 bg-primary-100 border border-primary-100 hover:bg-transparent hover:text-primary-100 text-gray-600 font-semibold" loading={loading} click={submitHandler}>
                    {submitTitle}
                </Button>
            </div>
        </form>

    )
 
}

export default FormEditTelegramTemplate ;