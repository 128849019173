import React, { useState , useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

import {newAnnouncements} from '../../../../services/announcements/announcementsService';
import {getAuthorsApi} from '../../../../services/authors/authorsService';

import Form from '../../../common/forms/Form';
import Loading from '../../../common/loading/Loading';

const NewAnnouncement = () => {
  
    const navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState({});
    

    useEffect(()=>{

        const getAuthors = async()=>{

            const resAuthors = await getAuthorsApi();

            if(resAuthors.data.status === 'success'){

                setForm( {
                    formItems: [
                    {
                        inputType: "input",
                        config: {
                            label: 'Title',
                            name: "title",
                            containerClasses : "w-full"
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input",
                        config: {
                            label: 'Slug',
                            name: "slug",
                            containerClasses : "w-full"
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input",
                        config: {
                            label: 'Seo Description',
                            name: "seoDesc",
                            containerClasses : "w-full"
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "textarea",
                        config: {
                            label: 'Keywords',
                            name: "keywords",
                            containerClasses : "w-full"
                        },
                        value: '',
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "ck-editor",
                        config: {
                            label: 'Body',
                            name: "body",
                            containerClasses : "w-full"
                        },
                        value: "",
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input-file",
                        config: {
                            label: 'Image',
                            name: "images",
                            type: "file",
                            t_label : 'attached_file' ,
                            containerClasses : "w-full"
                        },
                        value: "",
                        validation: {
                            maxSize: 5000000,
                            isSelected : true ,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "selectAuthor",
                        config: {
                            options : resAuthors.data.data,
                            label: 'Author',
                            name: "authors",
                            containerClasses : 'w-1/2'
                        },
                        value: "",
                        validation: {
                            selectRequired : true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                ]
                });

            }else {
                toast.error('Something wrong');
            }

            setLoading(false);

        }

        getAuthors();
        
    } , []);
        

    const handleSubmitForm = async(formData)=>{
        setLoadingForm(true);

        const {data} = await newAnnouncements(formData);
        if(data.status === 'success'){
            setLoadingForm(false); 
            toast.success('Your new announcement sended successfull');
            navigate('/announcements')

        }else if(data.status == 'error'){
            setLoadingForm(false);
            toast.error(data.msg);
        }

    }


    return (
        <>
            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 dark:text-white-100 text-xl font-extrabold'>New Announcement</span>
                    </div>


                    {
                        loading ?
                        (
                            <Loading/> 
                        )
                        :
                        (
                            <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Apply" file={true}/>

                        )

                    }
                              
                    

 
                </div>
            </div>
        </>
    )

}

export default NewAnnouncement ;
