import React, { useState , useEffect} from 'react';
import { useNavigate , useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { singleTemplate , editTemplate } from '../../../../../services/crm/telegramTemplateService';
import FormEditTelegramTemplate from '../../../../common/forms/FormEditTelegramTemplate';

const EditTemplate = () => {

    const {id} = useParams();

    const navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState({});
    const [ template , setTemplate ]= useState({});


    useEffect(()=>{
        const getTemplate = async()=>{

            const res = await singleTemplate(id);

            if(res.data.status === 'success'){
                setTemplate(res.data.data);
                setForm({
                    formItems: [
                        {
                            inputType: "input",
                            config: {
                                label: 'Name',
                                name: "name",
                                containerClasses : 'w-full'
                            },
                            value: res.data.data.name,
                            validation: {
                                required: true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "textareaWithUpload",
                            config: {
                                label: 'Caption',
                                name: "caption",
                                rows : '10',
                                containerClasses : 'w-full' ,
                                bucket: 'telegram-template' ,
                                templateTestMode : 'telegram'
                            },
                            value:  res.data.data.caption,
                            validation: {
                                required: true,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                        {
                            inputType: "input-file",
                            config: {
                                label: 'Image',
                                name: "image",
                                type: "file",
                                t_label : 'attached_file',
                                containerClasses : 'w-1/2'
                            },
                            value: "",
                            validation: {
                                maxSize: 5000000,
                                isSelected : false ,
                            },
                            valid: false,
                            errorMsg: "",
                            used: false
                        },
                  
                    ]
                    }
                );

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }
            setLoading(false);
        }
        getTemplate();
    } , []);
    

    const handleSubmitForm = async(formData)=>{

        try{

            setLoadingForm(true);

            const {data} = await editTemplate(id , formData) ;
            if(data.status === 'success'){
                setLoadingForm(false); 
                toast.success('telegram template successfull');
                navigate('/crm/templates/telegram');
    
            }else if(data.status == 'error'){
                setLoadingForm(false);
                toast.error(data.msg);
            }
    
        }catch(err){
            setLoadingForm(false);
            toast.error(err.response.data.msg);
        }
    }


    return (
        <>
            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 text-xl font-extrabold'>Edit Telegram Template</span>
                    </div>
                                
                    <FormEditTelegramTemplate initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle="Apply" file={true} template={template}/>

                </div>
            </div>
        </>
    )

}

export default EditTemplate ;
