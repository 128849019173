import React, { useState , useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

import {axiosApiInstance} from '../../../services/axios';
import Loading from '../../common/loading/Loading';


const SingleFeedback = () => {

    const {id} = useParams();

    const[loading , setLoading] = useState(true);
    const [ contactForm , setContactForm ] = useState({});
    

    useEffect(()=>{

        const getData = async()=>{
            const {data} = await axiosApiInstance.get(`/admin/feedback/${id}` );

            setLoading(false);
            if(data.status === 'success'){
                setContactForm(data.data.feedback);

            }else if(data.status === 'error'){
                toast.error(data.msg);
            }
        }

        getData();

    } , []);


    const downloadHandler = async(file)=>{
       
        let filename = file.split('object=')[1];

        try {

            const URL = process.env.REACT_APP_S3_ENDPOINT_URL + '?' +file ; 

            fetch( URL , {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/pdf',
                },
              })
                .then(response => response.blob())
                .then(blob => {
                  const url = window.URL.createObjectURL(new Blob([blob]));
          
                  const link = document.createElement('a');
                  link.href = url;
                  link.download = filename;
          
                  document.body.appendChild(link);
          
                  link.click();
          
                  link.parentNode.removeChild(link);
                });
           
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <>
        
            <div className="container mx-auto p-3 ">
                {
                        loading ? 
                        (
                            <Loading/>
                        )
                        :
                        (
                            <>
                                <div className='w-full dark:bg-dark-100 bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                                    <div className='w-full flex justify-start items-center mb-5'>
                                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="50" cy="50" r="50" />
                                        </svg>
                                        <span className='text-gray-600 dark:text-white-100 text-xl font-extrabold'>Feedback Detail</span>
                                    </div>
                    
                                    <div className='w-full flex justify-between'>
                                        <div className='w-[200px] flex flex-col items-start'>
                                            <img className='rounded-full' src='/images/default-avatar64.jpg' width="64" height="64" alt='user' />
                                            <span>{contactForm?.user.name !== "" ? contactForm?.user.name : contactForm?.user.phone}</span>
                                            <span className='mt-2'>{moment(contactForm.createdAt).format('MMMM Do YYYY, h:mm')}</span>
                                        </div>
                                        <div className='w-[1px] mx-5 bg-gray-300'></div>
                                        <div dir="ltr" className='grow flex flex-col justify-between'>
                                            <span className="text-left text-gray-700 text-[1.1rem]">
                                            {contactForm.description}
                                            </span>
                                            {
                                                contactForm.file &&
                                                (
                                                    <div className='w-fit flex mt-5 p-2 hover:bg-gray-200 cursor-pointer rounded-md' onClick={() => downloadHandler(contactForm.file.original)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                                        </svg>
                                                        attached_file
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                          
                        )
                    }
            </div>
        </>
    )

}

export default SingleFeedback ;
