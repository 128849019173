const axios = require("axios");

export const axiosApiInstance = axios.create({
  //baseURL: '/api',
  baseURL: process.env.REACT_APP_API,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  //credentials : "include"
});

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (err) {

    if(err.response.data.msg == 'refresh token is not valid or depricated'){
      window.location.href = '/login';
    }

    const originalRequest = err.config;
    if (err.response?.status === 403  && err.response.data.msg == 'authorization faild'  && !originalRequest._retry) {
      originalRequest._retry = true;
      return axiosApiInstance.post("/admin/auth/refresh-token").then(async (res) => {
        try{
          if (res.data.status === "success") {
            return axios(originalRequest);
          }
        }catch(err){
         console.log(err)
        }
      });
    }

    return Promise.reject(err);
  }
);
