import React, { useState , useEffect} from 'react';
import {useParams} from 'react-router-dom';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import fileDownload from 'js-file-download';

import {axiosApiInstance} from '../../../services/axios';
import {ticketStatusToText} from '../../../utils/ticketStatusToText';
import{getFileName} from '../../../utils/getFileName';
import Form from '../../common/forms/Form';
import Loading from '../../common/loading/Loading';
import { isEmpty } from 'lodash';


const SingleTicket = () => {

    const {id} = useParams();

    const[loading , setLoading] = useState(true);
    const [ ticketParent , setTicketParent ] = useState({});
    const [ ticketChilds , setTicketChilds ] = useState([]);
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState();
    const [ update , setUpdate ] = useState(true);
    
        
    const initForm = {
        formItems: [
            {
                inputType: "textarea",
                config: {
                    label: 'description',
                    name: "description",
                    containerClasses : 'w-full'
                },
                value: "",
                validation: {
                    required: true,
                },
                valid: false,
                errorMsg: "",
                used: false
            },
            {
                inputType: "input-file",
                config: {
                    label: 'attached_file',
                    name: "image",
                    type: "file",
                    t_label : 'attached_file',
                    containerClasses : 'w-full'
                },
                value: "",
                validation: {
                    maxSize: 5000000,
                },
                valid: false,
                errorMsg: "",
                used: false
            },
 
        ]
    };



    useEffect(()=>{

        setForm(initForm);
        const getData = async()=>{
            const {data} = await axiosApiInstance.get(`/admin/tickets/${id}` );

            setLoading(false);
            if(data.status === 'success'){
                setTicketParent(data.data.ticketParent);
                setTicketChilds(data.data.ticketChilds);

            }else if(data.status === 'error'){
                toast.error(data.msg);
            }
        }

        getData();

    } , [update]);


    const handleSubmitForm = async(formData)=>{
        setLoadingForm(true);

        const {data} = await axiosApiInstance.post(`/admin/tickets/reply/${id}` , formData) ;
        if(data.status === 'success'){
            setLoadingForm(false); 
            toast.success('Your reply sended successfull');
            setUpdate(!update);

        }else if(data.status == 'error'){
            setLoadingForm(false);
            toast.error(data.msg);
        }

    }


    const changeStateHandler = async(num)=>{
        setLoading(true);
        const {data} = await axiosApiInstance.get(`/admin/tickets/change-state/${id}?status=${num}`);
        if(data.status === 'success'){
            setLoadingForm(false); 
            setUpdate(!update);

        }else if(data.status == 'error'){
            setLoadingForm(false);
            setLoading(false);
            toast.error(data.msg);
        }


    }



    
    const downloadHandler = async(file)=>{

        let filename = file.split('object=')[1];

        try {

            const URL = process.env.REACT_APP_S3_ENDPOINT_URL + '?' +file ; 

            fetch( URL , {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/pdf',
                },
              })
                .then(response => response.blob())
                .then(blob => {
                  const url = window.URL.createObjectURL(new Blob([blob]));
          
                  const link = document.createElement('a');
                  link.href = url;
                  link.download = filename;
          
                  document.body.appendChild(link);
          
                  link.click();
          
                  link.parentNode.removeChild(link);
                });
           

        } catch (err) {
            console.log(err);
        }


    }

    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />


            <div className="container mx-auto p-3 ">
                {
                        loading ? 
                        (
                            <Loading/>
                        )
                        :
                        (
                            <>
                                 <div className='bg-white-100dark:bg-dark-100 p-3 mb-2 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                                    <div className='w-full flex flex-col p-3 bg-white-100 justify-start items-center mb-5 p-5n'>
                                        <div className='w-full flex justify-between'>
                                            <div className='text-[1.5rem] text-gray-700 font-black'>
                                                {ticketParent.subject}
                                            </div>
                                            <div className='text-[1rem] text-gray-500 font-bold flex items-center'>
                                                #{ticketParent.number}
                                            </div>
                                        </div>
                                        <div className='w-full flex justify-between my-5'>
                                            <div className='flex items-center'>
                                                <span className='text-[1rem] text-gray-500 font-normal p-2 hover:bg-gray-100 cursor-pointer rounded-md duration-300'>
                                                    Status:
                                                </span>
                                                <div className='text-[1rem] text-gray-500 ml-2'>
                                                    {ticketStatusToText(ticketParent.status)}
                                                </div>
                                            </div>
                                            <div className='text-[1rem] text-gray-400 font-normal'>
                                                Created at
                                                {moment(ticketParent.createdAt).format('MMMM Do YYYY, h:mm')}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-full bg-white-100 dark:bg-dark-100 p-3 mb-5 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                                        <div className='w-full flex justify-start items-center mb-5'>
                                            <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="50" cy="50" r="50" />
                                            </svg>
                                            <span className='text-gray-600 dark:text-white-100 text-xl font-extrabold'>Change status</span>
                                        </div>
                                        <div className='w-full flex justify-center items-center'>
                                            <div className={`flex items-center mr-10 rounded-md hover:bg-gray-100 p-2 cursor-pointer ${ticketParent.status == 2 ? "border border-gray-300" :""}`} onClick={()=>changeStateHandler(2)}>
                                                <div className='bg-yellow-400 w-3 h-3 rounded-full mr-2'>
                                                </div>
                                                Under Review
                                            </div>
                                            <div className={`flex items-center mr-10 rounded-md hover:bg-gray-100 p-2 cursor-pointer ${ticketParent.status == 4 ? "border border-gray-300" :""}`} onClick={()=>changeStateHandler(4)}>
                                                <div className='bg-gray-500 w-3 h-3 rounded-full mr-2'>
                                                </div>
                                                Closed
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-full bg-white-100 dark:bg-dark-100 p-3 mb-2 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
            
                                        <div className='w-full flex justify-start items-center mb-5'>
                                            <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="50" cy="50" r="50" />
                                            </svg>
                                            <span className='text-gray-600 dark:text-white-100 text-xl font-extrabold'>Reply</span>
                                        </div>
                                        <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle={'Send reply'} file={true}/>
                                    </div>

                                    <div className='w-full dark:bg-dark-100 bg-white-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
            
                                        <div className='w-full flex justify-start items-center mb-5'>
                                            <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="50" cy="50" r="50" />
                                            </svg>
                                            <span className='text-gray-600 dark:text-white-100 text-xl font-extrabold'>History of conversations</span>
                                        </div>
                                        {
                                            ticketChilds && ticketChilds.map ((ticket , index) =>(
                                                <>
                                                    <div className='w-full flex justify-between my-10' key={index}>
                                                        <div className='w-[200px] flex flex-col items-start mr-20'>
                                                            <img className='rounded-full' src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${ticket?.user?.avatar?.thumb}`} width="64" height="64" alt='user' />
                                                            <span>{ticket?.user?.name !== "" || ticket?.user?.name !== null ? ticket?.user?.name : ticket?.user?.phone}</span>
                                                            <span className='mt-2'>{moment(ticket.createdAt).format('MMMM Do YYYY, h:mm')}</span>
                                                        </div>
                                                        <div dir="ltr" className='grow flex flex-col justify-between'>
                                                            <span className="text-left text-gray-700 text-[1.1rem]">
                                                            {ticket.description}
                                                            </span>
                                                            {
                                                                !isEmpty(ticket.file) &&
                                                                (
                                                                    <div className='w-fit flex mt-5 p-2 hover:bg-gray-200 cursor-pointer rounded-md' onClick={() => downloadHandler(ticket.file.original)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                                                        </svg>
                                                                        attached_file
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={`w-full h-[2px] bg-slate-200 ${index == ticketChilds.length-1 ? "mb-7" : ""}`}></div>
                                                </>
                                            ))
                                        }
                                        <div className='w-full flex justify-between'>
                                            <div className='w-[200px] flex flex-col items-start mr-20'>
                                                <img className='rounded-full' src={`${process.env.REACT_APP_S3_ENDPOINT_URL}?${ticketParent?.user?.avatar?.thumb}`} width="64" height="64" alt='user' />
                                                <span>{ticketParent?.user?.name !== "" || ticketParent?.user?.name !== null ? ticketParent?.user?.name : ticketParent?.user?.phone }</span>
                                                <span className='mt-2'>{moment(ticketParent.createdAt).format('MMMM Do YYYY, h:mm')}</span>
                                            </div>
                                            <div dir="ltr" className='grow flex flex-col justify-between'>
                                                <span className="text-left text-gray-700 text-[1.1rem]">
                                                {ticketParent.description}
                                                </span>
                                                {
                                                    ticketParent.file &&
                                                    (
                                                        <div className='w-fit flex mt-5 p-2 hover:bg-gray-200 cursor-pointer rounded-md' onClick={() => downloadHandler(ticketParent.file.original)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                                            </svg>
                                                            attached_file
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                          
                        )
                    }
            </div>
        </>
    )

}

export default SingleTicket ;
