import React, { useState , useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {axiosApiInstance} from '../../../../services/axios';
import FormEditBlog from '../../../common/forms/FormEditBlog';
import Loading from '../../../common/loading/Loading';


const EditBlogCat = () => {

    const {id} = useParams();

    const Navigate = useNavigate();

    const[loading , setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [blogCat , setBlogCat ] = useState({});
    const [ form , setForm ]= useState();



    useEffect(()=>{

        const getBlogCat = async()=>{
            const res = await axiosApiInstance.get(`/admin/blog/categories/${id}` );
            if(res.data.status === 'success'){
                setBlogCat(res.data.data.blogCat);
                setForm( {
                    formItems: [
                    {
                        inputType: "input",
                        config: {
                            label: 'Title',
                            name: "title",
                            containerClasses:'w-full'
                        },
                        value: res.data.data.blogCat.title,
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input",
                        config: {
                            label: 'Slug',
                            name: "slug",
                            containerClasses:'w-full'
                        },
                        value: res.data.data.blogCat.slug,
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "input-file",
                        config: {
                            label: 'Image',
                            name: "images",
                            type: "file",
                            containerClasses:'w-full',
                            t_label : 'attached_file'
                        },
                        value: "",
                        validation: {
                            maxSize: 5000000,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                    {
                        inputType: "ck-editor",
                        config: {
                            label: 'Description',
                            name: "body",
                            containerClasses:'w-full'
                        },
                        value: res.data.data.blogCat.body,
                        validation: {
                            required: true,
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                ]
                });

            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }

            setLoading(false);

        }
        getBlogCat();

    } , []);
        

    const handleSubmitForm = async(formData)=>{
        setLoadingForm(true);

        const {data} = await axiosApiInstance.put(`/admin/blog/categories/${id}` , formData) ;
        if(data.status === 'success'){
            setLoadingForm(false); 
            toast.success('New Category apply successfull');
            Navigate('/blog/categories');

        }else if(data.status == 'error'){
            setLoadingForm(false);
            toast.error(data.msg);
        }
    }

    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />


            <div className="container mx-auto p-3 ">
                <div className='bg-white-100 dark:bg-dark-100 p-3 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>

                    <div className='w-full flex justify-start items-center mb-5'>
                        <svg className='w-2 h-2 fill-gray-700 dark:fill-white-100 mr-2' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" />
                        </svg>
                        <span className='text-gray-700 dark:text-white-100 text-xl font-extrabold'>Edit blog category</span>
                    </div>
                    {
                        loading ?
                        (
                            <Loading/>

                        )
                        :
                        (
                            <div className='w-full dark:bg-dark-100 p-3 mb-2 rounded-md text-[0.9rem] font-normal flex flex-col justify-center items-start shadow-my dark:shadow-none '>
                                <FormEditBlog initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle={'Apply'} file={true} post={blogCat}/>
                            </div>

                        )

                    }
                   
                </div>
                     
            </div>
        </>
    )

}

export default EditBlogCat ;
