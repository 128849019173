import React, { useState , useEffect} from 'react';

import Form from '../../forms/Form';
import {axiosApiInstance} from '../../../../services/axios';

import ButtonSimple from '../../button/ButtonSimple';
import { ToastContainer , toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ModalAddToken = (props) => {

    const [loading, setLoading] = useState(true);
    const [loadingForm , setLoadingForm] = useState(false);
    const [ form , setForm ]= useState({});
        

    useEffect(()=>{

        const getBlogCat = async()=>{

            const res = await axiosApiInstance.get(`/admin/users/tokens/types/all` );
            console.log(res)

            if(res.data.status === 'success'){

                setForm( {
                    formItems: [
                    {
                        inputType: "select",
                        config: {
                            options :  res.data.data ,
                            label: 'Token type',
                            name: "tokentype",
                        },
                        value: [] ,
                        validation: {
                            notEmptyArray : true
                        },
                        valid: false,
                        errorMsg: "",
                        used: false
                    },
                ]
                });
            
            }else if(res.data.status === 'error'){
                toast.error(res.data.msg);
            }
            setLoading(false);
        }

        getBlogCat();

    } , [props.show]);


    const handleSubmitForm = async(formData)=>{
        setLoadingForm(true);


        const {data} = await axiosApiInstance.post(`/admin/users/tokens/new/${props.userId}` , formData) ;

        if(data.status === 'success'){
            setLoadingForm(false); 
            toast.success('New token created successfull');

            close();
            props.refresh(data.data.tokentype);

        }else if(data.status == 'error'){
            setLoadingForm(false);
            toast.error(data.msg);
        }

    }


    const close = async (e) => {
        props.close();
    }

 
    return (
        <>
            <ToastContainer 
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />

            {props.show ? (<div className='w-full h-full z-[4000000] fixed top-0 left-0 right-0 bg-modalBackground-100' onClick={() => close()}></div>) : null}
            <div className={`w-4/5 h-fit  max-w-[650px] fixed z-[5000000] bg-white-100 dark:bg-dark-100 rounded-3xl left-0 right-0 top-0 bottom-0 m-auto  ${props.show ? 'animate-zoom-in block' : 'hidden'}`}>
                <div className='w-full h-full flex flex-col justify-center items-center p-5 overflow-y-auto'>
                    <h1 className="font-bold text-gray-700 text-[1.2rem]">
                        Add Token
                    </h1>

                    <Form initForm={form} submit={handleSubmitForm} loading={loadingForm} submitTitle={'Apply'} file={false}/>

                </div>
            </div>
        </>
    )
}

export default ModalAddToken;