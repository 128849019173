import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectUser } from '../../../redux/slices/auth';
import {checkPermissions} from '../../../utils/checkPermissions';

const AuthorizedRoute = ({children , permission}) => {

    const user = useSelector(selectUser);

    // check permissions
    if (!user || !checkPermissions(user.type , user.permissions , permission)) {
        return <Navigate to="/" replace />;
    } 

    return children;

};

export default AuthorizedRoute;