export const checkPermissions = (type , permissions , permission)=>{

    let allow = false;

    if(type === 'Parent'){
        allow = true;
    }else if(permission!==null && permission !== undefined){

        let permissionSubject = permission.split('-')[0];
        let permissionAction = permission.split('-')[1];

        let permissionSubjectArr = permissionSubject.split(',');

        permissionSubjectArr.forEach(permissionSub=>{

            let permissonItemIndex = permissions.findIndex(item=>item.permission.subject === permissionSub);

            if(permissonItemIndex !== -1){
                if(permissionAction === undefined){
                    allow = true;
                }else{
    
                    if(permissions[permissonItemIndex].actions.includes(permissionAction)){
                        allow = true;
                    }
                }
            }

        })

    }

    return allow
 
}