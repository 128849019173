import React  from 'react';

const MyCheckbox = (props) => {

    const changeCheck = ()=>{
        props.change(props.value)
    }

    return (
        <div className={`${props?.config?.containerClasses} flex justify-start items-center mb-5`} >

            <input className={`hidden`} name={props.config.name} value={props.value} ></input>

            <div className={`w-[15px] h-[15px] border border-gray-400 cursor-pointer rounded-full ${props.value == 'true' ?  'bg-primary-100' : ''}`}  onClick={() => changeCheck()} >
                {
                    props.value == 'true' ?
                    (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} className="w-3 h-3 stroke-gray-900">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>
                    )
                    :
                    (
                        null
                    )
                }
            </div>
            <span className='ml-2 text-gray-700 font-normal text-[0.8rem]'>{props.config.label}</span>
                    
        </div>
    
    )
}

export default MyCheckbox;